import Item from "examples/Item2/Item";
// import fallbackImage from "assets/images/marie.jpg";
import { useEffect, useState } from "react";
import axios from "axios";

function EmployeeNameHeader() {
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const fallbackImage = "assets/images/marie.jpg";

  useEffect(() => {
    fetchEmployeesByEmail();
  }, []);

  async function fetchEmployeesByEmail() {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        const response = await axios.get(
          `${apiUrl}/api/PersonalInformation/GetEmployeeby-Email/${loginEmail}`
        );
        const responseData = response.data;

        if (Array.isArray(responseData)) {
          setEmployeeDetails(responseData);
        } else {
          setEmployeeDetails([responseData]);
        }
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <div className="card">
      <div
        className="card-header"
        style={{ position: "relative", display: "flex", alignItems: "left" }}
      >
        {employeeDetails.map((employee) => (
          <>
            <Item
              image={
                <img
                  src={`${apiUrl}/${employee.image}`}
                  alt="person"
                  style={{ height: "55px", width: "55px", borderRadius: "100px" }}
                />
              }
              title={`${employee.firstName} ${employee.lastName}`}
              // date="Full Stack Web Developer"
            />
          </>
        ))}
      </div>
    </div>
  );
}

export default EmployeeNameHeader;
