import { Card, Row, Col } from "antd";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";

function Assets() {
  const navigate = useNavigate();
  const handleHardwareClick = () => {
    navigate("/hardware-assets");
  };

  const handleSoftwareClick = () => {
    navigate("/software-assets");
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <Card style={{ margin: "20px" }} title="Assets" className="card-professional">
          <Row justify="center" gutter={[16, 16]} style={{ marginTop: "20px" }}>
            <Col>
              <Card
                onClick={handleHardwareClick}
                style={{
                  width: "180px", // Adjust width
                  height: "180px", // Adjust height
                  borderRadius: "50%",
                  background: "linear-gradient(to right, #ff7e5f, #feb47b)", // Gradient color
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "16px", // Increased font size
                  transition: "transform 0.2s",
                  textAlign: "center",
                }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
              >
                Hardware Assets
              </Card>
            </Col>
            <Col>
              <Card
                onClick={handleSoftwareClick}
                style={{
                  width: "180px", // Adjust width
                  height: "180px", // Adjust height
                  borderRadius: "50%",
                  background: "linear-gradient(to right, #6a11cb, #2575fc)", // Gradient color
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "16px", // Increased font size
                  transition: "transform 0.2s",
                  textAlign: "center",
                }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
              >
                Software Assets
              </Card>
            </Col>
          </Row>
        </Card>
      </div>
    </DashboardLayout>
  );
}

export default Assets;
