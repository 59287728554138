import React, { useState, useEffect } from "react";
import "./ViewCandidate.scss";
import { FileOpenOutlined } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Modal } from "antd";
import { Space } from "antd";
import FeedBackService from "../APIServices/ViewCandidateService/FeedBackService";
import InterviewService from "../APIServices/ViewCandidateService/InterviewService";
import JobOfferService from "../APIServices/ViewCandidateService/JobOfferService";
import EmployeeContractService from "../APIServices/ViewCandidateService/EmployeeContractService";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

function ViewCandidateService() {
  const { postingJobId } = useParams();
  const [jobDetails, setJobDetails] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [isOverall, setIsOverall] = useState(true);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [selectedPdf, setSelectedPdf] = useState("");
  const [selectedCoverLetter, setSelectedCoverLetter] = useState("");
  const [emailSent, setEmailSent] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingEmails, setLoadingEmails] = useState({});
  const [loadingEmployeeId, setLoadingEmployeeId] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const {
    feedbacks,
    setFeedbacks,
    openfeedback,
    setOpenfeedback,
    feedbackDetail,
    showfeedbackModal,
    feedbackData,
    setFeedbackData,
    handleSubmitFeedback,
    fetchfeedBack,
    emailStatusList,
  } = FeedBackService();

  const {
    employeeId,
    open,
    setOpen,
    email,
    firstDateTime,
    secondDateTime,
    thirdDateTime,
    setFirstDateTime,
    setSecondDateTime,
    setThirdDateTime,
    showModal,
    handleOk,
    handleCancel,
    interviewScheduled,
    loadingInterviewEmails,
  } = InterviewService();

  const {
    jobOfferOpen,
    jobOfferScheduled,
    handleContractChange,
    showjobOfferModal,
    handleJobOfferCancel,
    handleJobOfferClick,
    loadingJobOffer,
  } = JobOfferService();

  const {
    openContract,
    employeeContract,
    contractPdf,
    handleContractCancel,
    showcontractModal,
    handleContractPreview,
    employeeEmail,
    empId,
  } = EmployeeContractService();

  useEffect(() => {
    fetchJobDetails();
    fetchEmployeesByPostingJobId(postingJobId);
    handleButtonClick(postingJobId);
    fetchfeedBack(emailStatusList.map((status) => status.email));
    fetchEmailStatus();
  }, [interviewScheduled, feedbacks, jobOfferScheduled]);

  const fetchEmailStatus = async () => {
    try {
      const emailsToCheck = [];
      const emailStatus = {};

      for (const email of emailsToCheck) {
        const response = await axios.get(
          `${apiUrl}/api/EmployeeJobApplication/GetEmailStatus?email=${email}`
        );
        if (response.status === 200) {
          const { emailSent, status } = response.data;
          emailStatus[email] = { emailSent, status };
        }
      }

      setEmailSent(emailStatus);
    } catch (error) {
      console.error("Error fetching email status:", error);
    }
  };

  function displayWarningMessage(emailData) {
    confirm({
      title: "Are you sure you want to send this email?",
      content: `You are about to send an email to ${emailData.firstName} ${emailData.lastName}.`,
      okText: "Yes, Send",
      okType: "primary",
      cancelText: "No, Cancel",
      onOk: async () => {
        // Call the sendEmailToEmployee function here with the emailData
        await sendEmailToEmployee(emailData);
      },
      onCancel() {
        console.log("Cancel button clicked");
      },
    });
  }

  // Update the sendEmailToEmployee function
  const sendEmailToEmployee = async (emailData) => {
    try {
      await axios.post(`${apiUrl}/api/EmployeeJobApplication/SendEmail`, emailData);

      toast.success("Successfully Sent");
      fetchEmployeesByPostingJobId(postingJobId);
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Failed to send email.");
    }
  };

  // Example usage
  const handleSendEmail = (email, firstName, lastName, forJobApply, password) => {
    const emailData = { email, firstName, lastName, forJobApply, password };
    displayWarningMessage(emailData); // Call the warning message with email data
  };

  const renderButton = (info) => {
    const isLoading = loadingEmails[info.email] || false;
    const isInterLoading = loadingInterviewEmails[info.email] || false;
    const jobOfferLoading = loadingJobOffer[info.email] || false;

    switch (info.status) {
      case 0:
        return (
          <button
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-success"
            onClick={() =>
              handleShortlistClick(
                info.email,
                info.firstName,
                info.lastName,
                info.forJobApply,
                info.password
              )
            }
            disabled={isLoading}
          >
            {isLoading ? "Sending..." : "Shortlist"}
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>
        );
      case 1:
        return (
          <button
            disabled={info.status === 1}
            onClick={() =>
              showModal(
                info.employeeId,
                info.email,
                info.firstDateTime,
                info.secondDateTime,
                info.thirdDateTime
              )
            }
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-primary"
          >
            Interview
          </button>
        );
      case 2:
        return (
          <button
            onClick={() =>
              showModal(
                info.employeeId,
                info.email,
                info.firstDateTime,
                info.secondDateTime,
                info.thirdDateTime
              )
            }
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-primary"
            disabled={isInterLoading}
          >
            {isInterLoading ? "Please Wait..." : "Interview"}
            {isInterLoading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>
        );
      case 3:
        return (
          <button
            disabled={info.status === 3}
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-info"
            onClick={() => {
              showfeedbackModal(info.employeeId, info.email);
            }}
          >
            Feedback
          </button>
        );
      case 4:
        return (
          <button
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-info"
            onClick={() => {
              showfeedbackModal(info.employeeId, info.email);
            }}
          >
            Feedback
          </button>
        );
      case 5:
        return (
          <button
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-warning"
            onClick={() => showjobOfferModal(info.employeeId, info.email)}
            disabled={jobOfferLoading}
          >
            {jobOfferLoading ? "Please Wait..." : "Job Offer"}
            {jobOfferLoading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>
        );
      case 6:
        return (
          <button
            disabled={info.status === 5}
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-success"
            onClick={() => showcontractModal(info.employeeId, info.email)}
          >
            View Document
          </button>
        );
      case 7:
        return (
          <button
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-success"
            onClick={() => showcontractModal(info.employeeId, info.email)}
          >
            View Document
          </button>
        );
      case 8:
        return (
          <button
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-success"
            onClick={() => showcontractModal(info.employeeId, info.email)}
          >
            View Document
          </button>
        );
      default:
        return null;
    }
  };

  async function fetchJobDetails() {
    try {
      const response = await axios.get(
        `${apiUrl}/api/HiringProcess/GetpostingById/${postingJobId}`
      );
      setJobDetails(response.data);
    } catch (error) {
      console.error("Error fetching job details:", error);
    }
  }
  const [pending, setPending] = useState(0);
  const [interview, setInterview] = useState(0);
  const [qualified, setQualified] = useState(0);

  async function fetchEmployeesByPostingJobId(postingJobId) {
    try {
      const response = await axios.get(
        `${apiUrl}/api/EmployeeJobApplication/GetEmployeesByPostingJobId/${postingJobId}`
      );
      const responseData = response.data;
      const employeeJobApplications = responseData.employeeJobApplications;

      const pendingEmployees = employeeJobApplications.filter((info) => info.status === 3);
      const interviewEmployees = employeeJobApplications.filter((info) => info.status === 4);
      const qualifiedEmployees = employeeJobApplications.filter((info) => info.status === 8);
      setPending(pendingEmployees.length);
      setInterview(interviewEmployees.length);
      setQualified(qualifiedEmployees.length);

      const updatedRows = employeeJobApplications.map((info) => ({
        key: info.employeeId.toString(),
        employeeId: info.employeeId,
        firstName: info.firstName,
        lastName: info.lastName,
        email: info.email,
        mobileno: info.mobileno,
        telePhone: info.telePhone,
        streetAddress: info.streetAddress,
        city: info.city,
        state: info.state,
        postalCode: info.postalCode,
        forJobApply: info.forJobApply,
        coverLetter: info.coverLetter,
        resume: info.resume,
        status: info.status,
        password: info.password,
        dateAvailable: <span>{formatDateTime(info.dateAvailable)}</span>,
        appliedOn: <span>{formatDateTime(info.appliedOn)}</span>,
      }));
      setEmployeeCount(updatedRows.length);
      setEmployeeDetails(updatedRows);
      setIsOverall(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  function renderStatus(status) {
    if (status === 0) {
      return <span style={{ color: "green" }}>Applied</span>;
    } else if (status === 1) {
      return <span style={{ color: "green" }}>Pending</span>;
    } else if (status === 2) {
      return <span style={{ color: "green" }}>Interview Date Time Schedule</span>;
    } else if (status === 3) {
      return <span style={{ color: "green" }}>Short List</span>;
    } else if (status === 4) {
      return <span style={{ color: "green" }}>Interview</span>;
    } else if (status === 5) {
      return <span style={{ color: "green" }}>FeedBack</span>;
    } else if (status === 6) {
      return <span style={{ color: "green" }}>Document Send</span>;
    } else if (status === 7) {
      return <span style={{ color: "green" }}>Document Received</span>;
    } else if (status === 8) {
      return <span style={{ color: "green" }}>Qualified</span>;
    } else {
      return <span>{status}</span>;
    }
  }

  const handleButtonClick = (postingJobId) => {
    fetchEmployeesByPostingJobId(postingJobId);
  };

  const handleShortListClick = (postingJobId, status) => {
    fetchPendingEmployeesByPostingJobId(postingJobId, status);
  };

  function formatDateTime(dateTimeString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return new Date(dateTimeString).toLocaleString("en-US", options);
  }

  const handleShortlistClick = async (email, firstName, lastName, forJobApply, password) => {
    setLoadingEmails((prevState) => ({ ...prevState, [email]: true }));

    try {
      await handleSendEmail(email, firstName, lastName, forJobApply, password);
    } catch (error) {
      console.error("Error sending email:", error);
    } finally {
      setLoadingEmails((prevState) => ({ ...prevState, [email]: false }));
    }
  };
  const handleDelete = (employeeId, employeeData) => {
    confirm({
      title: "Are you sure you want to reject this employee?",
      icon: <ExclamationCircleOutlined />,
      content: "After rejection, this action cannot be undone.",
      okText: "Yes, Reject",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          // Set the loading state to the current employee's ID
          setLoadingEmployeeId(employeeId);

          // Convert emailData to query parameters
          const queryParams = new URLSearchParams({
            firstName: employeeData.firstName,
            lastName: employeeData.lastName,
            email: employeeData.email,
            forJobApply: employeeData.forJobApply,
            Password: employeeData.Password,
          }).toString();

          // Make the DELETE request with query parameters
          const response = await axios.delete(
            `${apiUrl}/api/EmployeeJobApplication/Employee-Reject/${employeeId}?${queryParams}`,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          // Display the message from the backend in the toast
          toast.success(response.data);

          // Filter the employeeDetails to remove the rejected employee
          setEmployeeDetails((prevDetails) => {
            const updatedDetails = prevDetails.filter((info) => info.employeeId !== employeeId);

            // If no employees left, set employeeDetails to null to show "No Candidate available"
            if (updatedDetails.length === 0) {
              return null;
            }

            return updatedDetails;
          });

          setEmployeeCount((prevCount) => prevCount - 1);
        } catch (error) {
          // Show the error message from the backend in the toast
          if (error.response && error.response.data) {
            toast.error(error.response.data);
          } else {
            toast.error("Failed to reject employee.");
          }
        } finally {
          // Reset the loading state after the request completes
          setLoadingEmployeeId(null);
        }
      },
      onCancel() {
        console.log("Cancel button clicked");
      },
    });
  };

  const handlePdfPreview = async (fileUrl) => {
    try {
      // Get the file extension
      const extension = fileUrl.split(".").pop().toLowerCase();

      if (extension === "pdf") {
        // If PDF, set the selected PDF state and open modal for preview
        setSelectedPdf(fileUrl);
        const modal = new bootstrap.Modal(document.getElementById("staticBackdrop"));
        modal.show();
      } else {
        // If not PDF, download the file
        window.open(fileUrl, "_blank");
      }
    } catch (error) {
      console.error("Error handling file:", error);
    }
  };
  const handlecoverLetterPdfPreview = (fileUrl) => {
    try {
      // Get the file extension
      const extension = fileUrl.split(".").pop().toLowerCase();

      if (extension === "pdf") {
        // If PDF, set the selected PDF state and open modal for preview
        setSelectedCoverLetter(fileUrl);
        const modal = new bootstrap.Modal(document.getElementById("coverLetter"));
        modal.show();
      } else {
        // If not PDF, download the file
        window.open(fileUrl, "_blank");
      }
    } catch (error) {
      console.error("Error handling file:", error);
    }
  };

  async function fetchPendingEmployeesByPostingJobId(postingJobId, status) {
    try {
      const response = await axios.get(
        `${apiUrl}/api/EmployeeJobApplication/GetEmployeesByPostingJobId/${postingJobId}`
      );
      const responseData = response.data;
      const employeeJobApplications = responseData.employeeJobApplications;

      const filteredEmployees = employeeJobApplications.filter((info) => info.status === status);

      if (status === 3) {
        setPending(filteredEmployees.length);
      } else if (status === 4) {
        setInterview(filteredEmployees.length);
      } else if (status === 8) {
        setQualified(filteredEmployees.length);
      }

      const updatedRows = filteredEmployees.map((info) => ({
        key: info.employeeId.toString(),
        employeeId: info.employeeId,
        firstName: info.firstName,
        lastName: info.lastName,
        email: info.email,
        phone: info.phone,
        address: info.address,
        city: info.city,
        state: info.state,
        zipcode: info.zipcode,
        forJobApply: info.forJobApply,
        coverLetter: info.coverLetter,
        resume: info.resume,
        status: info.status,
        password: info.password,
        dateAvailable: <span>{formatDateTime(info.dateAvailable)}</span>,
        appliedOn: <span>{formatDateTime(info.appliedOn)}</span>,
      }));
      setEmployeeDetails(updatedRows);
      setIsOverall(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleOnboarding = (empId, employeeEmail) => {
    navigate(`/hiringOnboarding/${empId}`, { state: { employeeEmail } });
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Download/Preview",
      key: "download",
      render: (record) => (
        <Space size="middle">
          <FileOpenOutlined
            onClick={() => handleContractPreview(`${apiUrl}/${record.file}`)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
  ];
  return {
    renderButton,
    qualified,
    interview,
    pending,
    renderStatus,
    handleShortListClick,
    handleDelete,
    handlePdfPreview,
    handlecoverLetterPdfPreview,
    handleOnboarding,
    columns,
    openContract,
    employeeContract,
    contractPdf,
    handleContractCancel,
    employeeEmail,
    empId,
    handleJobOfferCancel,
    handleJobOfferClick,
    handleContractChange,
    jobOfferOpen,
    handleOk,
    handleCancel,
    employeeId,
    open,
    setOpen,
    email,
    firstDateTime,
    secondDateTime,
    thirdDateTime,
    setFirstDateTime,
    setSecondDateTime,
    setThirdDateTime,
    feedbackData,
    setFeedbackData,
    handleSubmitFeedback,
    setFeedbacks,
    openfeedback,
    setOpenfeedback,
    feedbackDetail,
    jobDetails,
    employeeDetails,
    isOverall,
    employeeCount,
    selectedPdf,
    selectedCoverLetter,
    emailSent,
    loading,
    loadingEmployeeId,
    formatDateTime,
    postingJobId,
  };
}

export default ViewCandidateService;
