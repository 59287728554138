import React from "react";
import PropTypes from "prop-types";
import check from "../../assets/images/checklist.jpg";

const OnboardingTasks = ({ formData, setFormData }) => {
  const handleCheckboxChange = (key, message) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: prevState[key] === message ? "" : message,
    }));
  };

  return (
    <>
      <label className="mb-3" style={{ textAlign: "left", fontSize: "large", color: "#3dc00f" }}>
        <img src={check} height={"35px"} style={{ marginLeft: "-8px" }} alt="Tasks for HR" />
        Tasks for Manager
      </label>
      {[
        {
          key: "WelcomePacket",
          text: "Send out a new hire welcome packet that includes the forms and documents new hires must complete.",
        },
        {
          key: "RoleAndCompensationInfo",
          text: "Document the new hire`s role and compensation information in your HRIS system or records.",
        },
        { key: "PayrollSetup", text: "Add the new employee to the payroll." },
        {
          key: "CompanyPolicies",
          text: "Share essential company policies (e.g., hours of operation, dress code, parking, etc.)",
        },
        {
          key: "PaperworkIntroMeetings",
          text: "Schedule intro meetings to go over paperwork, including benefits, direct deposit, and company policies.",
        },
        {
          key: "PeriodicReviewsScheduling",
          text: "If your organization conducts periodic reviews (such as after a 90-day probationary period), schedule these and other important milestones.",
        },
        {
          key: "BackgroundCheck",
          text: "Ask new hires to complete a background check (if applicable).",
        },
        { key: "DrugScreen", text: "Send the new hire to complete a drug screen (if applicable)." },
      ].map(({ key, text }, index) => (
        <div className="form-check OnboardCheckbox" key={index}>
          <input
            className="form-check-input"
            type="checkbox"
            checked={formData[key] === text}
            id={`Check${index + 9}`}
            onChange={() => handleCheckboxChange(key, text)}
          />
          <label className="form-check-label" htmlFor={`Check${index + 9}`}>
            {text}
          </label>
        </div>
      ))}
      <label className="mb-3" style={{ textAlign: "left", fontSize: "large", color: "#3dc00f" }}>
        <img src={check} height={"35px"} style={{ marginLeft: "-8px" }} alt="check" />
        IT Onboarding Checklist
      </label>

      {[
        { key: "EmailCreation", text: "Create an email address for the new hire." },
        {
          key: "AccountPermissionsSetup",
          text: "Set up account permissions for any platforms or software the role requires.",
        },
        {
          key: "EquipmentSetup",
          text: "Prepare a computer and any phone equipment or tech accessories a new hire may need.",
        },
      ].map(({ key, text }, index) => (
        <div className="form-check OnboardCheckbox" key={index}>
          <input
            className="form-check-input"
            type="checkbox"
            checked={formData[key] === text}
            id={`Check${index + 18}`}
            onChange={() => handleCheckboxChange(key, text)}
          />
          <label className="form-check-label" htmlFor={`Check${index + 18}`}>
            {text}
          </label>
        </div>
      ))}
    </>
  );
};

OnboardingTasks.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};

export default OnboardingTasks;
