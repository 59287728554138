import "./ViewCareer.scss";
import usStates from "united-states";
import SoftButton from "components/SoftButton";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { CInputGroup } from "@coreui/react";
import JobOpeningService from "layouts/APIServices/JopOpeningService";
import ValidationError from "./ValidationError";

function InputFields() {
  const {
    formData,
    options,
    handleSubmit,
    isSubmitted,
    handleUploadresumeChange,
    handleUploadDocChange,
    handleChange,
    formDataError,
    handleCancelClick,
    isLoading,
  } = JobOpeningService();

  return (
    <form onSubmit={handleSubmit} encType="multipart/formData">
      <ToastContainer />
      <div className="row">
        <div className="col-md-5 mb-3">
          <div className="mb-3">
            <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
              First Name *
            </label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="Enter First Name here"
              className={`form-control ${formDataError.firstNameError ? "is-invalid" : ""}`}
            />

            <ValidationError errorMessage={formDataError.firstNameError} />
          </div>
        </div>
        <div className="col-md-5 mb-3">
          <div className="mb-3">
            <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
              Last Name *
            </label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Enter Last Name here"
              className={`form-control ${formDataError.lastNameError ? "is-invalid" : ""}`}
            />
            <ValidationError errorMessage={formDataError.lastNameError} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 mb-3">
          <div className="mb-3">
            <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
              Email *
            </label>
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter Email here"
              className={`form-control ${formDataError.emailError ? "is-invalid" : ""}`}
            />
            <ValidationError errorMessage={formDataError.emailError} />
          </div>
        </div>
        <div className="col-md-5 mb-3">
          <div className="mb-3">
            <label
              htmlFor="exampleInputEmail1"
              className="form-label"
              style={{ fontSize: "medium" }}
            >
              Gender *
            </label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className={`form-select form-select-sm ${
                formDataError.genderError ? "is-invalid" : ""
              }`}
              aria-label="Default select example"
            >
              <option value=""> Please Select </option>
              <option value="Male"> Male </option>
              <option value="Female"> Female </option>
              <option value="UnSpecified"> UnSpecified </option>
              <option value="Do not wish to disclosed"> Do not wish to disclosed </option>
            </select>
            <ValidationError errorMessage={formDataError.genderError} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 mb-3">
          <div className="mb-3">
            <label
              htmlFor="exampleInputEmail1"
              className="form-label"
              style={{ fontSize: "medium" }}
            >
              Marital Status *
            </label>
            <select
              name="maritalStatus"
              value={formData.maritalStatus}
              onChange={handleChange}
              className={`form-select form-select-sm ${
                formDataError.maritalStatusError ? "is-invalid" : ""
              }`}
              aria-label="Default select example"
            >
              <option value=""> Please Select </option>
              <option value="Single"> Single </option>
              <option value="Married"> Married </option>
              <option value="Divorced"> Divorced </option>
              <option value="Widow"> Widow </option>
            </select>
            <ValidationError errorMessage={formDataError.maritalStatusError} />
          </div>
        </div>
        <div className="col-md-5 mb-3">
          <div className="mb-3">
            <label
              htmlFor="exampleInputEmail1"
              className="form-label"
              style={{ fontSize: "large" }}
            >
              Phone *
            </label>
            <CInputGroup className="mb-3">
              <PhoneInput
                onChange={(value) => handleChange(value, "mobileno")}
                className={`col-md-12 ${formDataError.mobilenoError ? "is-invalid" : ""}`}
                defaultCountry="us"
                inputStyle={{ width: "420px" }}
                onBlur={handleChange}
                name="mobileno"
                value={formData.mobileno}
                placeholder="Enter Phone here"
              />
              <ValidationError errorMessage={formDataError.mobilenoError} />
            </CInputGroup>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 mb-3">
          <div className="mb-3">
            <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
              Street Address *
            </label>
            <input
              type="text"
              name="streetAddress"
              value={formData.streetAddress}
              onChange={handleChange}
              placeholder="Enter street address here"
              className={`form-control ${formDataError.streetAddressError ? "is-invalid" : ""}`}
            />
            <ValidationError errorMessage={formDataError.streetAddressError} />
          </div>
        </div>
        <div className="col-md-5 mb-3">
          <div className="mb-3">
            <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
              City *
            </label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              placeholder="Enter City here"
              className={`form-control ${formDataError.cityError ? "is-invalid" : ""}`}
            />
            <ValidationError errorMessage={formDataError.cityError} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 mb-3">
          <div className="mb-3">
            <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
              State *
            </label>
            <select
              name="state"
              value={formData.state}
              onChange={handleChange}
              aria-label=".form-select-sm example"
              className={`form-select ${formDataError.stateError ? "is-invalid" : ""}`}
            >
              <option value="" disabled>
                Please select States
              </option>
              {usStates.map((state) => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.name}
                </option>
              ))}
            </select>
            <ValidationError errorMessage={formDataError.stateError} />
          </div>
        </div>
        <div className="col-md-5 mb-3">
          <div className="mb-3">
            <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
              Postal Code *
            </label>
            <input
              type="number"
              name="postalCode"
              value={formData.postalCode}
              onChange={handleChange}
              placeholder="Enter postal code here"
              className={`form-control ${formDataError.postalCodeError ? "is-invalid" : ""}`}
            />
            <ValidationError errorMessage={formDataError.postalCodeError} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 mb-3">
          <div className="mb-3">
            <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
              Country *
            </label>
            <select
              defaultValue="US"
              name="country"
              value={formData.country}
              onChange={handleChange}
              aria-label=".form-select-sm example"
              className={`form-control ${formDataError.countryError ? "is-invalid" : ""}`}
            >
              <option value="">Please select</option>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <ValidationError errorMessage={formDataError.countryError} />
          </div>
        </div>
        <div className="col-md-5 mb-3">
          <div className="mb-3">
            <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
              Cover Letter *
            </label>
            <input
              type="file"
              accept=".pdf,.PDF,.docx,.DOCX,.doc,.DOC"
              onChange={(e) => {
                handleUploadDocChange(e);
              }}
              className={`form-control ${formDataError.coverLetterError ? "is-invalid" : ""}`}
            />
            <ValidationError errorMessage={formDataError.coverLetterError} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 mb-3">
          <div className="mb-3">
            <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
              Resume *
            </label>
            <input
              type="file"
              // value={formData.resume}
              // onChange={handleFileChange}
              accept=".pdf,.PDF,.docx,.DOCX,.doc,.DOC"
              onChange={(e) => {
                handleUploadresumeChange(e);
              }}
              className={`form-control ${formDataError.resumeError ? "is-invalid" : ""}`}
            />
            <ValidationError errorMessage={formDataError.resumeError} />
          </div>
        </div>
        <div className="col-md-5 mb-3">
          <div className="mb-3">
            <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
              Date Available *
            </label>
            <input
              type="date"
              name="dateAvailable"
              value={formData.dateAvailable}
              onChange={handleChange}
              className={`form-control ${formDataError.dateAvailableError ? "is-invalid" : ""}`}
            />
            <ValidationError errorMessage={formDataError.dateAvailableError} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 mb-3">
          <div className="mb-3">
            <label
              htmlFor="exampleInputEmail1"
              className="form-label"
              style={{ fontSize: "medium" }}
            >
              Work Authorization US
              <i
                className="fa fa-info-circle"
                title="Are you authorized to work lawfully in the united states for Demand base?"
                style={{ marginLeft: "5px", cursor: "pointer" }}
                aria-hidden="true"
              ></i>
            </label>
            <select
              aria-label="Default select example"
              name="workAuthorizationUS"
              value={formData.workAuthorizationUS}
              onChange={handleChange}
              className={`form-select ${
                formDataError.workAuthorizationUSError ? "is-invalid" : ""
              }`}
            >
              <option value=""> Please Select </option>
              <option value="Yes"> Yes </option>
              <option value="NO"> No </option>
            </select>
            <ValidationError errorMessage={formDataError.workAuthorizationUSError} />
          </div>
        </div>
        <div className="col-md-5 mb-3">
          <div className="mb-3">
            <label
              htmlFor="exampleInputEmail1"
              className="form-label"
              style={{ fontSize: "medium" }}
            >
              Sponsorship Needed
              <i
                className="fa fa-info-circle"
                title="Will you now or in the future require Demandbase to commence ('sponsor') an immigration case in order to employ you (for example, H-1B, OPT, F1, J1, TN or other employment-based immigration case)? This is sometimes called 'sponsorship' for an employment-based visa status."
                style={{ marginLeft: "5px", cursor: "pointer" }}
                aria-hidden="true"
              ></i>
            </label>
            <select
              aria-label="Default select example"
              name="sponsorshipNeeded"
              value={formData.sponsorshipNeeded}
              onChange={handleChange}
              className={`form-select ${formDataError.sponsorshipNeededError ? "is-invalid" : ""}`}
            >
              <option value=""> Please Select </option>
              <option value="Yes"> Yes </option>
              <option value="NO"> No </option>
            </select>
            <ValidationError errorMessage={formDataError.sponsorshipNeededError} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10">
          <SoftButton
            variant="outlined"
            style={{ float: "inline-end" }}
            color="info"
            size="small"
            type="submit"
            disabled={isSubmitted || isLoading} // Disable button if already submitted or loading
          >
            {isSubmitted ? "Applied" : isLoading ? "Submitting..." : "Submit"}
            {isLoading && !isSubmitted && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </SoftButton>
          <SoftButton
            style={{ float: "inline-end", marginRight: "10px" }}
            variant="outlined"
            color="error"
            size="small"
            onClick={handleCancelClick}
          >
            Cancel
          </SoftButton>
        </div>
      </div>
    </form>
  );
}

export default InputFields;
