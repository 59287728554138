import { useEffect, useState } from "react";
import { message } from "antd";
import {
  fetchEmployees,
  fetchLeavePolicies,
  addLeavePolicy,
  fetchUserNameByEmail,
} from "./Service";

function APIFunctionService() {
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [userName, setUserName] = useState("HR");
  const [formData, setFormData] = useState({
    policyName: "",
    grantedDays: "",
    approvals: "",
    enrollments: 0,
  });
  const [formDataError, setFormDataError] = useState({
    policyNameError: "",
    grantedDaysError: "",
    approvalsError: "",
  });

  useEffect(() => {
    // Fetch initial data for employees and policies
    initializeData();
  }, []);

  useEffect(() => {
    // Fetch user details based on email stored in local storage
    const email = localStorage.getItem("hremail");
    if (email) {
      handleFetchUserName(email);
    }
  }, []);

  const initializeData = async () => {
    try {
      const employeesData = await fetchEmployees();
      setEmployees(employeesData);
      await fetchLeavePolicies(); // Keeping it for additional functionality
    } catch (error) {
      console.error("Error initializing data:", error);
    }
  };

  const handleFetchUserName = async (email) => {
    try {
      const fetchedUserName = await fetchUserNameByEmail(email);
      setUserName(fetchedUserName);
      setFormData((prevData) => ({
        ...prevData,
        approvals: `${fetchedUserName} HR Manager`,
      }));
    } catch (error) {
      console.error("Error fetching user name:", error);
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    if (!name || value === undefined) return;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setFormDataError((prevErrors) => ({
      ...prevErrors,
      [`${name}Error`]: value.trim() ? "" : `${name.replace(/([A-Z])/g, " $1")} is required.`,
    }));
  };

  const clearFields = () => {
    setFormData({
      policyName: "",
      grantedDays: "",
      approvals: "",
      enrollments: 0,
    });
    setFormDataError({
      policyNameError: "",
      grantedDaysError: "",
      approvalsError: "",
    });
  };

  const validateFields = () => {
    const errors = {};
    let isValid = true;

    Object.entries(formData).forEach(([key, value]) => {
      if (value === undefined || value === "") {
        errors[`${key}Error`] = `${key.replace(/([A-Z])/g, " $1")} is required.`;
        isValid = false;
      }
    });

    setFormDataError((prev) => ({ ...prev, ...errors }));
    return isValid;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return false;

    try {
      setLoading(true);
      await addLeavePolicy(formData);
      message.success("Policy added successfully");
      clearFields();
      await fetchLeavePolicies(); // Refresh policies after successful submission
      return true;
    } catch (error) {
      const errorMessage =
        error.response?.status === 400 ? error.response.data : "Error adding Policy";
      message.error(errorMessage);
      console.error("Submission error:", errorMessage);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    formData,
    setFormData,
    formDataError,
    handleChange,
    clearFields,
    loading,
    employees,
    userName,
  };
}

export default APIFunctionService;
