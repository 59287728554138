import React, { useState, useEffect } from "react";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import "./Training.scss";
import { Table, Spin, Card } from "antd";
import axios from "axios";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function EmployeeTrainings() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      setLoading(true);
      // Retrieve email from local storage
      const email = localStorage.getItem("loginEmail");

      if (!email) {
        console.error("Email not found in local storage");
        return;
      }

      // Fetch data from API using the retrieved email
      const response = await axios.get(
        `${apiUrl}/api/InitiateAccountability/Get-Enrolled-Employee-By-Email?email=${email}`
      );
      const responseData = response.data;

      // Generate rows based on the fetched data
      const updatedRows = responseData.map((info, index) => {
        function formatDateTime(dateTimeString) {
          const options = {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          };

          return new Date(dateTimeString).toLocaleString("en-US", options);
        }

        return {
          key: info.enrollmentDetails?.trainingId?.toString() || index.toString(),
          Name: `${info.trainingDetails?.trainingName || "N/A"}`,
          Provider: `${info.enrollmentDetails?.provider || "N/A"}`,
          Mentor: info.enrollmentDetails?.mentor || "N/A",
          Enrollments: "N/A", // Replace with actual enrollment data
          Allocated_budget: info.enrollmentDetails?.allocated_budget || "N/A",
          StartDate: <span>{formatDateTime(info.enrollmentDetails?.startDate || "N/A")}</span>,
          EndDate: <span>{formatDateTime(info.enrollmentDetails?.endDate || "N/A")}</span>,
          Action: (
            <span style={{ display: "flex", alignItems: "center" }}>
              <Link
                to={`/employeedashboard/emptrainingDetail/${info.enrollmentDetails?.trainingId}`}
              >
                <EyeOutlined className="eyeOutlined" />
              </Link>
            </span>
          ),
        };
      });

      // Update dataSource state
      setDataSource(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  const columns = [
    { title: "Name", dataIndex: "Name", key: "Name" },
    { title: "Provider", dataIndex: "Provider", key: "Provider" },
    { title: "Mentor", dataIndex: "Mentor", key: "Mentor" },
    { title: "Allocated_budget", dataIndex: "Allocated_budget", key: "Allocated_budget" },
    { title: "Start Date", dataIndex: "StartDate", key: "StartDate" },
    { title: "End Date", dataIndex: "EndDate", key: "EndDate" },
    { title: "Action", dataIndex: "Action", key: "Action" },
  ];

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card title="Enrolled In Training" className="card-professional">
            <div className="row mb-3">
              <Table
                className="table-responsive"
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={{ pageSize: 10 }}
                loading={loading}
              />
            </div>
          </Card>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EmployeeTrainings;
