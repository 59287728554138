import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Button, Empty } from "antd";
import { useLocation } from "react-router-dom";

const DocumentPreview = () => {
  const location = useLocation();
  const { pdfUrl, title } = location.state || {}; // Access passed data

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div style={{ padding: "20px", textAlign: "center" }}>
            <h6>{title}</h6>
            {pdfUrl ? (
              <>
                <iframe
                  src={pdfUrl}
                  title="Document Preview"
                  width="100%"
                  height="600px"
                  style={{ border: "none" }}
                />
              </>
            ) : (
              <Empty description="No document available for preview." />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DocumentPreview;
