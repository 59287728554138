import React from "react";
import { Card, Select, Button, Table } from "antd";
import EmployeeOnLeaveService from "./Service";
const { Option } = Select;

export default function EmployeesOnLeave() {
  const {
    columns,
    filteredRows,
    handleDownloadReport,
    handleDepartmentChange,
    departments,
    selectedDepartment,
  } = EmployeeOnLeaveService();

  return (
    <Card
      title="Employee on Leave"
      className="card-professional"
      extra={
        <>
          <Button type="default" onClick={handleDownloadReport} style={{ marginRight: "16px" }}>
            <i className="fa fa-download" aria-hidden="true"></i>&nbsp;Download Employee Reports
          </Button>
          <Select
            value={selectedDepartment}
            onChange={handleDepartmentChange}
            style={{ width: 200 }}
          >
            <Option value="All Departments">All Departments</Option>
            {departments.map((department) => (
              <Option key={department} value={department}>
                {department}
              </Option>
            ))}
          </Select>
        </>
      }
    >
      <Table
        bordered
        className="table-responsive"
        dataSource={filteredRows}
        columns={columns}
        pagination={{ pageSize: 10 }}
        rowKey="id"
      />
    </Card>
  );
}
