import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Card, Collapse, Empty } from "antd";

function OnboardingBackground() {

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card title="Onboarding Background" className="card-professional">
            <Empty description="Under Construction" />
          </Card>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default OnboardingBackground;
