import React, { useState, useEffect } from "react";
import { Table } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import SoftButton from "components/SoftButton";
import DatePicker from "react-datepicker";
import "./Benefits.scss";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import reimbursment from "assets/images/reimbursement.jpg";
import fidelity401k from "assets/images/fidelity.jpeg";
import benefitDependentService from "../APIServices/PersonalInformationService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { updateBenefitsInfo, updateLifeInsuranceInfo } from "../Services/Actions/actions";
import axios from "axios";

import { GiftFilled } from "@ant-design/icons";

function Benefits({ onNext, onBack, employeeDetail }) {
  const apiUrl = process.env.REACT_APP_API_URL
  const { personal, job, emergency, lifeInsuranceName } = useSelector((state) => state);

  const {
    benefitDependent,
    benefitDependentError,
    setBenefitDependentError,
    setBenefitDependent,
    handleBenefitDependentChange,
    validateBenefitDependentValidation,
  } = benefitDependentService();
  const dispatch = useDispatch();

  const dataToSend = {
    ...personal,
    JobRequestDto: job,
    EmergencyRequestDto: emergency,
    BenefitsDependentsDto: benefitDependent,
    LifeInsuranceRequestDto: lifeInsuranceName,
  };

  // const handleNext = async (e) => {
  //   e.preventDefault();
  //   const isValid = validateBenefitDependentValidation(benefitDependent, setBenefitDependentError);
  //   if (isValid) {
  //     dispatch(updateBenefitsInfo(benefitDependent));
  //     try {
  //       await axios.post(
  //         "https://backend-data.datagonomix.com/api/PersonalInformation/Addpersonal-info",
  //         dataToSend,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );
  //       toast.success("Data has Been Added Successfully", {
  //         autoClose: 1000,
  //         style: { background: "lightgreen", color: "white" },
  //       });
  //     } catch (error) {
  //       console.error("Error adding personal information:", error);
  //       toast.error(error.message || "Failed to add personal information", {
  //         autoClose: 5000,
  //         style: { background: "lightcoral", color: "white" },
  //       });
  //     } finally {
  //       // setIsLoading(false);
  //     }
  //   }
  // };

  const handleNext = () => {
    const isValid = validateBenefitDependentValidation(benefitDependent, setBenefitDependentError);
    if (isValid) {
      dispatch(updateBenefitsInfo(benefitDependent));
      onNext();
      // handleCancelDependentClick();
    }
  };
  const [showDependents, setShowDependents] = useState(true);
  const handleAddDependentClick = () => {
    setShowDependents(false);
  };

  const handleCancelDependentClick = () => {
    setShowDependents(true);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const success = await handleNext();
      if (success) {
        toast.success("Data has Been Added Successfully", {
          autoClose: 5000,
          style: { background: "lightgreen", color: "white" },
        });
      }
    } catch (error) {
      toast.error(error.message || "Added failed", {
        autoClose: 5000,
        style: { background: "lightcoral", color: "white" },
      });
    }
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "RelationShip",
      dataIndex: "RelationShip",
      key: "RelationShip",
    },
    {
      title: "Gender",
      dataIndex: "Gender",
      key: "Gender",
    },
    {
      title: "SSN",
      dataIndex: "SSN",
      key: "SSN",
    },
    {
      title: "Birth Date",
      dataIndex: "BirthDate",
      key: "BirthDate",
    },
    {
      title: "Manager",
      dataIndex: "Manager",
      key: "Manager",
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${apiUrl}/api/PersonalInformation/Get-All`);
        const responseData = response.data;

        // Extract data from the response
        const benefits = responseData.benefits;
        const jobs = responseData.job;

        // Generate rows based on the fetched data
        const updatedRows = benefits.map((info, index) => ({
          key: info.personalId.toString(), // Set a unique key for each row
          Name: `${info.name}`,
          RelationShip: `${info.relationShip}`, // Fill with appropriate data
          Gender: `${info.gender}`,
          SSN: info.ssn,
          BirthDate: info.dob,
          Manager: jobs[index] ? jobs[index].manager : "-", // Assuming job information exists
        }));

        // Update dataSource state
        setDataSource(updatedRows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    // Populate formData with employee data when editing
    if (employeeDetail && employeeDetail.benefits) {
      const { benefits } = employeeDetail;
      setBenefitDependent({
        ...benefitDependent,
        personalId: benefits.personalId,
        Name: benefits.name,
        relationShip: benefits.relationShip,
        Gender: benefits.gender,
        SSN: benefits.ssn,
        DOB: benefits.dob,
      });
    }
  }, [employeeDetail]);

  const columnsR = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "Policy",
      dataIndex: "Policy",
      key: "Policy",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
    },
  ];

  const handleBack = () => {
    onBack();
  };
  const handleBNext = () => {
    onNext();
  };

  return (
    <div className="body flex-grow-1 px-3">
      {showDependents && (
        <div className="card">
          <div className="card-header" style={{ position: "relative" }}>
            <h5>Benefits</h5>
          </div>
          <div className="card-body">
            <div className="row mb-3">
              <div className="col-md-12">
                <label className="mb-3" style={{ textAlign: "left" }}>
                  <i className="fa fa-recycle" aria-hidden="true"></i>&nbsp;Dependents
                </label>
                <SoftButton
                  variant="outlined"
                  style={{ float: "inline-end" }}
                  color="info"
                  size="small"
                  onClick={handleAddDependentClick}
                >
                  <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Add Dependents
                </SoftButton>
              </div>
              <Table
                className="table-responsive"
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={{ pageSize: 10 }}
              />
            </div>
            {/* <div className="row mb-3">
              <label className="mb-3" style={{ textAlign: "left" }}>
                <GiftFilled />
                &nbsp;Benefits Overview
              </label>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={`accordion-button${activeIndex === 0 ? "" : " collapsed"}`}
                      data-section="benefits"
                      type="button"
                      onClick={() => toggleAccordion(0)}
                      aria-expanded={activeIndex === 0 ? "true" : "false"}
                      aria-controls="collapseOne"
                    >
                      Life Insurance
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className={`accordion-collapse collapse${activeIndex === 0 ? " show" : ""}`}
                    data-section="benefits"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row mb-3">
                        <div className="col-md-4">
                          <div className="card mb-3">
                            <div className="card-header" style={{ position: "relative" }}>
                              <h5>PODential</h5>
                            </div>
                            <div className="card-body">
                              <div className="row mb-3">
                                <p style={{ fontSize: "medium" }}>
                                  $- 3000 Deductible<br></br>
                                  $- 8000 OOP Max<br></br>
                                  20/80 Co-Insurance after Deductible<br></br>
                                  Prescription Benefits<br></br>
                                  <br></br>
                                  You Pay (Weekly)<br></br>$ 34.62
                                </p>
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                  <SoftButton
                                    // onClick={handleEnroll("PODential")}
                                    variant="outlined"
                                    color="info"
                                    size="small"
                                    type="Submit"
                                  >
                                    Enrolled
                                  </SoftButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="card mb-3">
                            <div className="card-header" style={{ position: "relative" }}>
                              <h5>MetLife</h5>
                            </div>
                            <div className="card-body">
                              <div className="row mb-3">
                                <p style={{ fontSize: "medium" }}>
                                  $- 6000 Deductible<br></br>
                                  $- 12000 OOP Max<br></br>
                                  20/80 Co-Insurance after Deductible<br></br>
                                  Prescription Benefits<br></br>
                                  <br></br>
                                  You Pay (Weekly)<br></br>$ 23.08
                                </p>
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                  <SoftButton variant="outlined" color="info" size="small">
                                    Enrolled
                                  </SoftButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="card mb-3">
                            <div className="card-header" style={{ position: "relative" }}>
                              <h5>State Farm</h5>
                            </div>
                            <div className="card-body">
                              <div className="row mb-3">
                                <p style={{ fontSize: "medium" }}>
                                  $- 1500 Deductible<br></br>
                                  $- 3000 OOP Max<br></br>
                                  20/80 Co-Insurance after Deductible<br></br>
                                  Prescription Benefits<br></br>
                                  <br></br>
                                  You Pay (Weekly)<br></br>$ 92.31
                                </p>
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                  <SoftButton variant="outlined" color="info" size="small">
                                    Enrolled
                                  </SoftButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className={`accordion-button${activeIndex === 1 ? "" : " collapsed"}`}
                      data-section="benefits"
                      type="button"
                      onClick={() => toggleAccordion(1)}
                      aria-expanded={activeIndex === 1 ? "true" : "false"}
                      aria-controls="collapseTwo"
                    >
                      401 k Fidelity
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className={`accordion-collapse collapse${activeIndex === 1 ? " show" : ""}`}
                    data-section="benefits"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <div className="row mb-3">
                            <img src={fidelity401k} alt="" />
                            <div
                              className="col-md-12"
                              style={{ textAlign: "center", marginTop: "20PX" }}
                            >
                              <SoftButton variant="outlined" color="info" size="small">
                                Enrolled
                              </SoftButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className={`accordion-button${activeIndex === 2 ? "" : " collapsed"}`}
                      data-section="benefits"
                      type="button"
                      onClick={() => toggleAccordion(2)}
                      aria-expanded={activeIndex === 2 ? "true" : "false"}
                      aria-controls="collapseThree"
                    >
                      Dental and Vision Health
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className={`accordion-collapse collapse${activeIndex === 2 ? " show" : ""}`}
                    data-section="benefits"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row mb-3">
                        <div className="col-md-4">
                          <div className="card mb-3">
                            <div className="card-header" style={{ position: "relative" }}>
                              <h5>Blue cross blue shield</h5>
                            </div>
                            <div className="card-body">
                              <div className="row mb-3">
                                <p style={{ fontSize: "medium" }}>
                                  $- 3000 Deductible<br></br>
                                  $- 8000 OOP Max<br></br>
                                  20/80 Co-Insurance after Deductible<br></br>
                                  Prescription Benefits<br></br>
                                  <br></br>
                                  You Pay (Weekly)<br></br>$ 34.62
                                </p>
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                  <SoftButton variant="outlined" color="info" size="small">
                                    Enrolled
                                  </SoftButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="card mb-3">
                            <div className="card-header" style={{ position: "relative" }}>
                              <h5>ASPEN</h5>
                            </div>
                            <div className="card-body">
                              <div className="row mb-3">
                                <p style={{ fontSize: "medium" }}>
                                  $- 6000 Deductible<br></br>
                                  $- 12000 OOP Max<br></br>
                                  20/80 Co-Insurance after Deductible<br></br>
                                  Prescription Benefits<br></br>
                                  <br></br>
                                  You Pay (Weekly)<br></br>$ 23.08
                                </p>
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                  <SoftButton variant="outlined" color="info" size="small">
                                    Enrolled
                                  </SoftButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="card mb-3">
                            <div className="card-header" style={{ position: "relative" }}>
                              <h5>Vision Insurance VSP</h5>
                            </div>
                            <div className="card-body">
                              <div className="row mb-3">
                                <p style={{ fontSize: "medium" }}>
                                  $- 1500 Deductible<br></br>
                                  $- 3000 OOP Max<br></br>
                                  20/80 Co-Insurance after Deductible<br></br>
                                  Prescription Benefits<br></br>
                                  <br></br>
                                  You Pay (Weekly)<br></br>$ 92.31
                                </p>
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                  <SoftButton variant="outlined" color="info" size="small">
                                    Enrolled
                                  </SoftButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <label className="mb-3" style={{ textAlign: "left" }}>
                <img src={reimbursment} height={"30px"} />
                &nbsp;Reimbursement
              </label>
              <Table
                className="table-responsive"
                bordered
                // dataSource={datasource}
                columns={columnsR}
                pagination={{ pageSize: 10 }}
              />
            </div> */}
            <div className="col-md-12">
              <SoftButton
                variant="outlined"
                style={{ float: "inline-end" }}
                color="info"
                size="small"
                onClick={handleBNext}
              >
                Next
              </SoftButton>
              <SoftButton
                variant="outlined"
                style={{ float: "inline-end", marginRight: "10px" }}
                color="black"
                size="small"
                onClick={handleBack}
              >
                Back
              </SoftButton>
            </div>
          </div>
        </div>
      )}
      {!showDependents && (
        <div className="card">
          <div className="card-header" style={{ position: "relative" }}>
            <h5>Add Dependents</h5>
          </div>
          <div className="card-body">
            <form onSubmit={onSubmit} encType="multipart/form-data">
              <ToastContainer />
              <div className="row">
                <input
                  type="hidden"
                  value={benefitDependent.personalId}
                  className="form-control"
                  onChange={handleBenefitDependentChange}
                />
                <div className="col-md-5 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInput"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Name *
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        benefitDependentError.NameError ? "is-invalid" : ""
                      }`}
                      data-section="benefits"
                      name="Name"
                      value={benefitDependent.Name}
                      onChange={handleBenefitDependentChange}
                      placeholder="Enter name here"
                    />
                    {benefitDependentError.NameError && (
                      <div className="invalid-feedback invalid-message">
                        {benefitDependentError.NameError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      RelationShip *
                    </label>
                    <select
                      className={`form-select ${
                        benefitDependentError.relationShipError ? "is-invalid" : ""
                      }`}
                      data-section="benefits"
                      name="relationShip"
                      value={benefitDependent.relationShip}
                      onChange={handleBenefitDependentChange}
                      aria-label="Default select example"
                    >
                      <option value=""> Please Select </option>
                      <option value="Father"> Father </option>
                      <option value="Mother "> Mother </option>
                      <option value="Siblings "> Siblings </option>
                      <option value="Spouse "> Spouse </option>
                    </select>
                    {benefitDependentError.relationShipError && (
                      <div className="invalid-feedback invalid-message">
                        {benefitDependentError.relationShipError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-5 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Gender *
                    </label>
                    <select
                      className={`form-select ${
                        benefitDependentError.GenderError ? "is-invalid" : ""
                      }`}
                      data-section="benefits"
                      name="Gender"
                      value={benefitDependent.Gender}
                      onChange={handleBenefitDependentChange}
                      aria-label="Default select example"
                    >
                      <option value=""> Please Select </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="UnSpecified">UnSpecified</option>
                      <option value="Do not wish to disclosed">Do not wish to disclosed</option>
                    </select>
                    {benefitDependentError.GenderError && (
                      <div className="invalid-feedback invalid-message">
                        {benefitDependentError.GenderError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInput"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      SSN *
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        benefitDependentError.SSNError ? "is-invalid" : ""
                      }`}
                      data-section="benefits"
                      name="SSN"
                      value={benefitDependent.SSN}
                      onChange={handleBenefitDependentChange}
                      placeholder="Enter SSN here"
                    />
                    {benefitDependentError.SSNError && (
                      <div className="invalid-feedback invalid-message">
                        {benefitDependentError.SSNError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Birth Date *
                    </label>
                    <input
                      type="date"
                      className={`form-control ${
                        benefitDependentError.DOBError ? "is-invalid" : ""
                      }`}
                      data-section="benefits"
                      name="DOB"
                      value={benefitDependent.DOB}
                      onChange={handleBenefitDependentChange}
                    />
                    {benefitDependentError.DOBError && (
                      <div className="invalid-feedback invalid-message">
                        {benefitDependentError.DOBError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <SoftButton
                    variant="outlined"
                    style={{ float: "inline-end" }}
                    color="info"
                    size="small"
                    type="submit"
                    onClick={handleNext}
                  >
                    Save
                  </SoftButton>
                  <SoftButton
                    style={{ float: "inline-end", marginRight: "10px" }}
                    variant="outlined"
                    color="dark"
                    size="small"
                    onClick={handleCancelDependentClick}
                  >
                    Cancel
                  </SoftButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

Benefits.propTypes = {
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  employeeDetail: PropTypes.func.isRequired,
};

export default Benefits;
