import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "antd"; // Assuming you are using Ant Design Table

function Service() {
  const { trainingId } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [trainingDetail, setTrainingDetail] = useState(null);
  const [enrolledCount, setEnrolledCount] = useState(0);
  const [dataSourceenrolled, setDataSourceenrolled] = useState([]);

  // Fetching training details and enrolled employees data
  useEffect(() => {
    async function fetchTrainingDetail() {
      try {
        const trainingResponse = await axios.get(
          `${apiUrl}/api/TrainingDevelopement/GetAllByTrainingId/${trainingId}`
        );
        setTrainingDetail(trainingResponse.data);
      } catch (error) {
        console.error("Error fetching training data:", error);
      }
    }

    async function fetchDatalenrolled() {
      try {
        const response = await axios.get(
          `${apiUrl}/api/InitiateAccountability/Get-Enrolled-Employee`
        );
        const responseData = response.data;

        if (Array.isArray(responseData)) {
          const filteredData = responseData.filter(
            (info) => info.enrollmentDetails.trainingId === parseInt(trainingId)
          );

          const updatedRows = filteredData.map((info) => ({
            key: info.enrollmentDetails.enrolledTrainingId?.toString() || "",
            EmployeeID: info.enrollmentDetails.personalId || "",
            name: `${info.personalInfo.firstName || ""} ${info.personalInfo.lastName || ""}`,
            email: info.personalInfo.email || "",
            Provider: info.enrollmentDetails.provider || "",
            Mentor: info.enrollmentDetails.mentor || "",
          }));

          setDataSourceenrolled(updatedRows);
          setEnrolledCount(filteredData.length); // Update the enrolled count
        } else {
          console.error("Unexpected response data format:", responseData);
        }
      } catch (error) {
        console.error("Error fetching enrolled data:", error);
      }
    }

    // Fetch data on component mount or when trainingId changes
    fetchTrainingDetail();
    fetchDatalenrolled();
  }, [trainingId]); // Ensure that fetches happen when trainingId changes

  // Columns for the enrolled employees table
  const columnsTrainingDetail = [
    {
      title: "Employee_ID",
      dataIndex: "EmployeeID",
      key: "EmployeeID",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Provider",
      dataIndex: "Provider",
      key: "Provider",
    },
    {
      title: "Mentor",
      dataIndex: "Mentor",
      key: "Mentor",
    },
  ];

  // Go back function
  const goBack = () => {
    navigate("/training");
  };

  function formatDateTime(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  }

  return {
    trainingDetail,
    enrolledCount,
    dataSourceenrolled,
    formatDateTime,
    columnsTrainingDetail,
    goBack,
  };
}

export default Service;
