import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

export default function ProtectedHRRoute({ element }) {
  const navigate = useNavigate();
  useEffect(() => {
    const login = localStorage.getItem("hremail");
    if (!login) {
      navigate("/signIn");
    }
  }, [navigate]);

  return element;
}

ProtectedHRRoute.propTypes = {
  element: PropTypes.element.isRequired,
};
