import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal, Radio } from "antd";
import { Table, Space } from "antd";
import { useParams } from "react-router-dom";

export default function FeedBack() {
  const { postingJobId } = useParams();
  const [openfeedback, setOpenfeedback] = useState(false);
  const [feedbackEmployeeId, setFeedBackEmployeeId] = useState(false);
  const [feedbackEmployeeEmail, setFeedBackEmployeeEmail] = useState(false);
  const [feedbackDetail, setFeedbackDetail] = useState([]);
  const [emailStatusList, setEmailStatusList] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  const [feedbacks, setFeedbacks] = useState({});

  useEffect(() => {
    fetchEmailStatuses();
    fetchEmployeesByPostingJobId(postingJobId);
  }, []);

  const fetchEmailStatuses = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/EmployeeInterview/GetAllEmails`);
      const emailsToCheck = response.data; // Assuming the API returns an array of email strings
      const emailStatus = [];

      for (const email of emailsToCheck) {
        const response = await axios.get(`${apiUrl}/api/EmployeeJobApplication/GetEmailStatus`, { params: { email } });
        if (response.status === 200) {
          const { emailSent, status } = response.data;
          emailStatus.push({ email, emailSent, status });
        }
      }

      setEmailStatusList(emailStatus);
      fetchfeedBack(emailsToCheck);
    } catch (error) {
      console.error("Error fetching email statuses:", error);
    }
  };

  const fetchEmployeesByPostingJobId = async (postingJobId) => {
    try {
      const response = await axios.get(`${apiUrl}/api/EmployeeJobApplication/GetEmployeesByPostingJobId/${postingJobId}`);
      const responseData = response.data.employeeJobApplications.map((info) => ({
        key: info.employeeId.toString(),
        employeeId: info.employeeId,
        status: info.status,
      }));
      setFeedbacks(responseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const showfeedbackModal = async (employeeId, email) => {
    setFeedBackEmployeeId(employeeId);
    setFeedBackEmployeeEmail(email);
    setOpenfeedback(true);
  };

  const [feedbackData, setFeedbackData] = useState({
    email: feedbackEmployeeEmail,
    Rating: 1,
    CommunicationSkill: 1,
    TechnicalAptitude: 1,
    AttitudeAndMotivation: 1,
    CriticalThinking: 1,
    Professionalism: 1,
    OrganizationalFit: 1,
    SuitableForPost: 1,
  });

  const handleSubmitFeedback = async () => {
    try {
      const totalRating =
        feedbackData.Rating +
        feedbackData.CommunicationSkill +
        feedbackData.TechnicalAptitude +
        feedbackData.AttitudeAndMotivation +
        feedbackData.CriticalThinking +
        feedbackData.Professionalism +
        feedbackData.OrganizationalFit +
        feedbackData.SuitableForPost;
      const marksAverage = totalRating / 8;
      const feedback = {
        ...feedbackData,
        email: feedbackEmployeeEmail,
        employeeId: feedbackEmployeeId,
        MarksAverage: marksAverage,
      };

      const response = await fetch(`${apiUrl}/api/EmployeeInterview/Interview-feedBack`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(feedback),
      });
      if (response.ok) {
        setOpenfeedback(false);
        toast.success("Feedback submitted successfully");
        fetchEmailStatuses();
        fetchfeedBack();
        setFeedbacks((prevInterviewScheduled) => ({
          ...prevInterviewScheduled,
          [feedbackEmployeeId]: true,
        }));
      } else {
        console.error("Error submitting feedback");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  async function fetchfeedBack(emails) {
    try {
      const promises = emails.map(async (email) => {
        const response = await axios.get(`${apiUrl}/api/EmployeeInterview/GetFeedBack/${email}`);
        const responseData = response.data;
        return responseData.feedBacks.map((info) => ({
          key: info.feedbackId.toString(),
          employeeId: info.employeeId,
          email: info.email,
          marksAverage: info.marksAverage,
        }));
      });

      const feedbackDataList = await Promise.all(promises);
      const updatedRows = feedbackDataList.flat(); // Flatten the array of arrays
      setFeedbackDetail(updatedRows);
    } catch (error) {
      console.error("Error fetching feedback data:", error);
    }
  }
  return {
    feedbacks,
    setFeedbacks,
    openfeedback,
    setOpenfeedback,
    feedbackDetail,
    showfeedbackModal,
    feedbackData,
    setFeedbackData,
    handleSubmitFeedback,
    fetchfeedBack,
    emailStatusList
  };
}


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { Button, Modal, Radio } from "antd";
// import { Table, Space } from "antd";

// export default function FeedBack() {
//   const [openfeedback, setOpenfeedback] = useState(false);
//   const [feedbackEmployeeId, setFeedBackEmployeeId] = useState(false);
//   const [feedbackEmployeeEmail, setFeedBackEmployeeEmail] = useState(false);
//   const [feedbackDetail, setFeedbackDetail] = useState([]);
//   const apiUrl = process.env.REACT_APP_API_URL

//   const [feedbacks, setFeedbacks] = useState(() => {
//     const storedFeedback = localStorage.getItem("feedbacks");
//     return storedFeedback ? JSON.parse(storedFeedback) : {};
//   });

//   useEffect(() => {
//     fetchfeedBack(email);
//   }, []);

//   useEffect(() => {
//     localStorage.setItem("feedbacks", JSON.stringify(feedbacks));
//   }, [feedbacks]);

//   const showfeedbackModal = async (employeeId, email) => {
//     setFeedBackEmployeeId(employeeId);
//     setFeedBackEmployeeEmail(email);
//     setOpenfeedback(true);
//     // await fetchFeedBack(employeeEmail);
//   };

//   const [feedbackData, setFeedbackData] = useState({
//     email: feedbackEmployeeEmail,
//     Rating: 1,
//     CommunicationSkill: 1,
//     TechnicalAptitude: 1,
//     AttitudeAndMotivation: 1,
//     CriticalThinking: 1,
//     Professionalism: 1,
//     OrganizationalFit: 1,
//     SuitableForPost: 1,
//   });

//   const handleSubmitFeedback = async () => {
//     try {
//       const totalRating =
//         feedbackData.Rating +
//         feedbackData.CommunicationSkill +
//         feedbackData.TechnicalAptitude +
//         feedbackData.AttitudeAndMotivation +
//         feedbackData.CriticalThinking +
//         feedbackData.Professionalism +
//         feedbackData.OrganizationalFit +
//         feedbackData.SuitableForPost;
//       const marksAverage = totalRating / 8;
//       const feedback = {
//         ...feedbackData,
//         email: feedbackEmployeeEmail,
//         employeeId: feedbackEmployeeId,
//         MarksAverage: marksAverage,
//       };

//       let emails = JSON.parse(localStorage.getItem("feedbackEmployeeEmails")) || [];
//       emails.push(feedbackEmployeeEmail);
//       localStorage.setItem("feedbackEmployeeEmails", JSON.stringify(emails));

//       const response = await fetch(`${apiUrl}/api/EmployeeInterview/Interview-feedBack`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(feedback),
//       });
//       if (response.ok) {
//         setOpenfeedback(false);
//         toast.success("Feedback submitted successfully");
//         fetchfeedBack(emails);

//         setFeedbacks((prevInterviewScheduled) => ({
//           ...prevInterviewScheduled,
//           [feedbackEmployeeId]: true,
//         }));
//       } else {
//         console.error("Error submitting feedback");
//       }
//     } catch (error) {
//       console.error("Error submitting feedback:", error);
//     }
//   };
//   debugger;
//   const email = JSON.parse(localStorage.getItem("feedbackEmployeeEmails")) || [];
//   debugger;
//   async function fetchfeedBack(email) {
//     try {
//       const promises = email.map(async (email) => {
//         const response = await axios.get(`${apiUrl}/api/EmployeeInterview/GetFeedBack/${email}`);
//         const responseData = response.data;
//         return responseData.feedBacks.map((info) => ({
//           key: info.feedbackId.toString(),
//           employeeId: info.employeeId,
//           email: info.email,
//           marksAverage: info.marksAverage,
//         }));
//       });

//       const feedbackDataList = await Promise.all(promises);
//       const updatedRows = feedbackDataList.flat(); // Flatten the array of arrays
//       setFeedbackDetail(updatedRows);
//     } catch (error) {
//       console.error("Error fetching feedback data:", error);
//     }
//   }
//   return {
//     feedbacks,
//     setFeedbacks,
//     openfeedback,
//     setOpenfeedback,
//     feedbackDetail,
//     showfeedbackModal,
//     feedbackData,
//     setFeedbackData,
//     handleSubmitFeedback,
//     fetchfeedBack,
//   };
// }
