// pdfGenerator.js
import jsPDF from "jspdf";
import "jspdf-autotable";

export const generatePDF = (filteredData, selectedSkills) => {
  const doc = new jsPDF();

  doc.setFontSize(18);
  doc.text("Employee Skills Report", 14, 22);

  const lineY = 28;
  const lineWidth = 0.5;
  const lineColor = [192, 192, 192];
  doc.setLineWidth(lineWidth);
  doc.setDrawColor(...lineColor);
  doc.line(14, lineY, 200, lineY);

  const marginTop = 3;
  const marginTopEOM = 2;
  doc.setFontSize(12);

  // Employee section
  doc.text("Employee: All - All employees selected", 14, 32 + marginTop);

  // Current date
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();
  doc.text(`Report Date: ${formattedDate}`, 14, 42 + marginTopEOM);

  // Exceptions section
  doc.text("Exceptions: No Deletions", 14, 52);

  // Department section, replaced with Skills
  const skillText = selectedSkills.length === 0 ? "All Skills" : selectedSkills.join(", ");
  doc.text(`Skill: ${skillText}`, 14, 59);

  const headers = [["Serial No", "Post Name", "Employee Name", "Department", "Skills", "Status"]];
  const data = filteredData.map((employee, index) => {
    const serialNo = index + 1;
    const postName = employee.hiring.postingJobTitle;
    const employeeName = `${employee.firstName} ${employee.lastName}`;
    const department = employee.hiring.department;
    const skills = employee.hiring.skills;
    const status = employee.status === 8 ? "Qualified" : employee.status;

    return [serialNo, postName, employeeName, department, skills, status];
  });

  doc.autoTable({
    startY: 62,
    head: headers,
    body: data,
    headerStyles: {
      fillColor: [192, 192, 192],
      fontSize: 8,
    },
    styles: {
      fontSize: 8,
    },
  });

  doc.save(`skills_report_${selectedSkills.join("_") || "all_skills"}.pdf`);
};
