import { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function PerformanceService({ onNext, selectedEmail }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [performanceFormData, setPerformFormdata] = useState({
    personalId: null,
    employeeEmail: "",
    jobKnowledge: "",
    administration: "",
    decision_Making: "",
    communication: "",
    initiative: "",
    work_Relationships: "",
    flexibility: "",
    overall_Performance: "",
  });

  useEffect(() => {
  
    if (selectedEmail && selectedEmail.email && selectedEmail.personalId !== null) {
      console.warn("Selected Email:", selectedEmail.email);
      console.warn("Selected PersonalId:", selectedEmail.personalId);
  
      setPerformFormdata(prevState => {
        const updatedState = {
          ...prevState,
          employeeEmail: selectedEmail.email,
          personalId: selectedEmail.personalId,
        };
        return updatedState;
      });
    }
  }, [selectedEmail]);
  const [performanceFormDataError, setPerformFormdataError] = useState({
    employeeEmailError: "",
    jobKnowledgeError: "",
    administrationError: "",
    decision_MakingError: "",
    communicationError: "",
    initiativeError: "",
    work_RelationshipsError: "",
    flexibilityError: "",
    overall_PerformanceError: "",
  });

  const handlePerformanceChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    if (name === "hiringDate" || name === "probationStartDate") {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      if (selectedDate >= currentDate) {
        setPerformFormdataError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "Please select a date before today.",
        }));
        return;
      }
    }

    setPerformFormdataError({ ...performanceFormDataError, [`${name}Error`]: "" });
    setPerformFormdata({ ...performanceFormData, [name]: value });
  };

  function validatePerformanceValidation(performanceFormData, setPerformFormdataError) {
    let hasError = false;

    Object.keys(performanceFormData).forEach((fieldName) => {
      if (
        (typeof performanceFormData[fieldName] === "string" &&
          (!performanceFormData[fieldName] || !performanceFormData[fieldName].trim())) || // For string fields
        (typeof performanceFormData[fieldName] === "number" &&
          (isNaN(performanceFormData[fieldName]) || performanceFormData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setPerformFormdataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      }
    });

    if (hasError) {
      return false;
    }

    const allFieldsFilled = Object.values(performanceFormData).every((value) => {
      return (
        (typeof value === "string" && value.trim() !== "") ||
        (typeof value === "number" && !isNaN(value))
      );
    });

    if (!allFieldsFilled) {
      setPerformFormdataError((prevState) => ({
        ...prevState,
        commonError: "Please fill in all fields before saving.",
      }));
      return false;
    }

    return true;
  }

  return {
    performanceFormData,
    setPerformFormdata,
    performanceFormDataError,
    setPerformFormdataError,
    onNext,
    validatePerformanceValidation,
    handlePerformanceChange,
  };
}

export default PerformanceService;
