import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Provider } from "react-redux";
import store from "./store";
import { CorrectiveActionProvider } from "./layouts/Complaints/CorrectiveActionContext";

import { SoftUIControllerProvider } from "context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <CorrectiveActionProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </CorrectiveActionProvider>
    </SoftUIControllerProvider>
  </BrowserRouter>
);
