import { Table } from "antd";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import "./Document.scss";
import SoftButton from "components/SoftButton";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import documentsService from "../APIServices/PersonalInformationService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { updateDocumentInfo } from "../Services/Actions/actions";
import axios from "axios";
import choosefile from "../../assets/images/uploadfile.png";
import Footer from "layouts/authentication/components/Footer";

function Documents({ onNext, onBack, employeeDetail, isEditMode }) {
  const [openModel, setOpenModel] = useState(true);
  const [error, setError] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL

  const handleuploadOpenModel = () => {
    setOpenModel(false);
  };
  const handleCloseModel = () => {
    setOpenModel(true);
  };
  const { personal, job, emergency, benefits } = useSelector((state) => state);
  // const {
  //   uploadD,
  //   uploadDError,
  //   setUploadDError,
  //   handleDocumentChange,
  //   validateDocumentValidation,
  //   setUploadD,
  // } = documentsService();
  const dispatch = useDispatch();
  const [docName, setDocName] = useState("");
  const [docfile, setUploadD] = useState(null);

  const dataToSend = {
    ...personal,
    JobRequestDto: job,
    EmergencyRequestDto: emergency,
    BenefitsDependentsDto: benefits,
    // UploadDocRequest: uploadD,
  };

  const [buttonLabel, setButtonLabel] = useState("Save");

  useEffect(() => {
    if (isEditMode) {
      setButtonLabel("Update");
    } else {
      setButtonLabel("Save");
    }
  }, [isEditMode]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleNext = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("docfile", docfile); // Append the uploaded file
    dispatch(updateDocumentInfo(docfile));

    Object.entries(personal).forEach(([key, value]) => {
      formData.append(key, value);
    });
    // Append job properties
    Object.entries(job).forEach(([key, value]) => {
      formData.append(`JobRequestDto.${key}`, value);
    });

    // Append emergency properties
    Object.entries(emergency).forEach(([key, value]) => {
      formData.append(`EmergencyRequestDto.${key}`, value);
    });

    // Append benefits properties
    Object.entries(benefits).forEach(([key, value]) => {
      formData.append(`BenefitsDependentsDto.${key}`, value);
    });

    Object.entries(docfile).forEach(([key, value]) => {
      formData.append(`UploadDocumentRequestDto.${key}`, value);
    });

    try {
      if (isEditMode) {
        await axios.post(
          // Use PUT request for update in edit mode
          `${apiUrl}/api/PersonalInformation/UpdatePersonalInfo/${employeeDetail.personalId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Data has Been Added Successfully", {
          autoClose: 1000,
          style: { background: "lightgreen", color: "white" },
        });
        fetchData();
        setOpenModel(true);
      } else {
        await axios.post(
          `${apiUrl}/api/PersonalInformation/Addpersonal-info`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Data has Been Added Successfully", {
          autoClose: 1000,
          style: { background: "lightgreen", color: "white" },
        });
        fetchData();
        setOpenModel(true);
      }
    } catch (error) {
      console.error("Error adding personal information:", error);
      toast.error(error.message || "Failed to add personal information", {
        autoClose: 5000,
        style: { background: "lightcoral", color: "white" },
      });
    }
  };

  async function fetchData() {
    try {
      const response = await axios.get(`${apiUrl}/api/PersonalInformation/Get-All`);
      const responseData = response.data;

      // Extract data from the response
      const document = responseData.document;

      // Generate rows based on the fetched data
      const updatedRows = document.map((info, index) => {
        // Function to format the date and time
        function formatDateTime(dateTimeString) {
          const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          const date = new Date(dateTimeString);
          const month = months[date.getMonth()];
          const day = date.getDate();
          const year = date.getFullYear();
          let hours = date.getHours();
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12;
          const minutes = date.getMinutes();
          return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
            minutes < 10 ? "0" : ""
          }${minutes} ${ampm}`;
        }

        return {
          key: info.personalId.toString(),
          FileName: (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handlePdfPreview(`${apiUrl}/${info.docfile}`)}
            >
              {info.docfile}
            </span>
          ),
          Date: <span>{formatDateTime(info.uploadedAt)}</span>,
          Action: (
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <EyeOutlined
                className="eyeOutlined"
                onClick={() => handlePdfPreview(`${apiUrl}/${info.docfile}`)}
              />
            </span>
          ),
        };
      });

      // Update dataSource state
      setDataSource(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const [dataSource, setDataSource] = useState([]);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [pdfPreview, setPdfPreview] = useState(true);

  const handlePdfPreview = (pdfUrl) => {
    setSelectedPdf(pdfUrl);
    setPdfPreview(false);
  };
  const handlePerviewBack = () => {
    setPdfPreview(true);
  };

  const handleUploadDocChange = (e) => {
    const file = e.target.files[0];
    const allowedExtensions = [".pdf", ".PDF", ".docx", ".DOCX", ".doc", ".DOC"];

    if (file) {
      const fileExtension = file.name.split(".").pop();
      if (!allowedExtensions.includes("." + fileExtension)) {
        setError("Unsupported file type. Only PDF and DOCX files are allowed.");
        setUploadD({ docfile: null }); // Update the state with null
        return;
      }
      setError("");
      setUploadD({ docfile: file }); // Update the state with the selected file
    }
  };

  const handleInputChange = (e) => {
    setDocName(e.target.value);
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "FileName",
      key: "FileName",
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
    },
  ];

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const success = await handleNext();
      if (success) {
        toast.success("Data has Been Added Successfully", {
          autoClose: 5000,
          style: { background: "lightgreen", color: "white" },
        });
      }
    } catch (error) {
      toast.error(error.message || "Added failed", {
        autoClose: 5000,
        style: { background: "lightcoral", color: "white" },
      });
    }
  };
  const handleDNext = () => {
    onNext();
  };
  const handleBack = () => {
    onBack();
  };

  return (
    <>
      {pdfPreview && (
        <div className="card">
          <div className="card-header" style={{ position: "relative" }}>
            <h5>Documents</h5>
          </div>
          <div className="card-body">
            {openModel && (
              <>
                <div className="row mb-3">
                  <ToastContainer />
                  <div className="col-md-12 mb-3">
                    <SoftButton
                      variant="outlined"
                      color="info"
                      size="small"
                      onClick={handleuploadOpenModel}
                    >
                      Upload Document
                    </SoftButton>
                  </div>
                  <Table
                    className="table-responsive"
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ pageSize: 10 }}
                  />
                </div>
                <div className="col-md-12">
                  <SoftButton
                    variant="outlined"
                    style={{ float: "inline-end" }}
                    color="info"
                    size="small"
                    onClick={handleDNext}
                  >
                    Next
                  </SoftButton>
                  <SoftButton
                    variant="outlined"
                    style={{ float: "inline-end", marginRight: "10px" }}
                    color="black"
                    size="small"
                    onClick={handleBack}
                  >
                    Back
                  </SoftButton>
                </div>
              </>
            )}
            {!openModel && (
              <div className="card-body">
                <form onSubmit={onSubmit} encType="multipart/form-data">
                  <div className="row">
                    {/* <div className="col-md-5">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          onChange={(e) => setDocName(e.target.value)}
                          placeholder="Enter file name here"
                        />
                      </div>
                    </div> */}
                    <div className="col-md-5">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="file"
                          accept=".pdf,.PDF,.docx,.DOCX,.doc,.DOC"
                          onChange={(e) => {
                            handleUploadDocChange(e);
                          }}
                        />
                        {error && <p style={{ color: "red" }}>{error}</p>}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <SoftButton
                        variant="outlined"
                        style={{ float: "inline-end" }}
                        color="info"
                        size="small"
                        type="submit"
                        onClick={handleNext}
                      >
                        {buttonLabel}
                      </SoftButton>
                      <SoftButton
                        style={{ float: "inline-end", marginRight: "10px" }}
                        variant="outlined"
                        color="dark"
                        size="small"
                        onClick={handleCloseModel}
                      >
                        Cancel
                      </SoftButton>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      )}
      {!pdfPreview && (
        <div className="card">
          <div className="card-header" style={{ position: "relative" }}>
            <h5>Document</h5>

            <div className="col-md-12">
              <SoftButton
                variant="outlined"
                style={{ float: "inline-end", marginTop: "-30px" }}
                color="dark"
                size="small"
                onClick={handlePerviewBack}
              >
                Back
              </SoftButton>
            </div>
          </div>
          <div className="card-body">
            <>
              <embed src={selectedPdf} type="application/pdf" width="100%" height="500px" />
            </>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}

Documents.propTypes = {
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  employeeDetail: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
};

export default Documents;
