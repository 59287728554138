import { Button, Form, message } from "antd";
import ComplainceService from "../APIServices/ComplainceService";
import React, { useEffect, useState } from "react";

function Service() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [addloading, setAddLoading] = useState(false);
  const [form] = Form.useForm();
  const API_URL = process.env.REACT_APP_API_URL;

  // Fetch data from API
  const fetchComplainceData = async () => {
    setLoading(true);
    try {
      const response = await ComplainceService.fetchComplainces();
      setData(response); // Adjusted to handle the response properly
    } catch (error) {
      message.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  // Handle form submission
  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("policyName", values.policyName);
    formData.append("policyAction", values.policyAction.file);

    try {
      setAddLoading(true);
      await ComplainceService.addComplaince(formData);
      message.success("Compliance added successfully");
      form.resetFields();
      setShowForm(false);
      fetchComplainceData();
    } catch (error) {
      message.error("Failed to add compliance");
    } finally {
      setAddLoading(false);
    }
  };

  // Columns for Ant Design Table
  const columns = [
    {
      title: "Policy Name",
      dataIndex: "policyName",
      key: "policyName",
    },
    {
      title: "Policy Action",
      dataIndex: "policyAction",
      key: "policyAction",
      render: (_, record) => (
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button type="link" onClick={() => handlePdfPreview(`${API_URL}/${record.policyAction}`)}>
            View File
          </Button>
        </span>
      ),
    },
  ];

  // Fetch data on component mount
  useEffect(() => {
    fetchComplainceData();
  }, []);

  const handlePdfPreview = async (fileUrl) => {
    try {
      // Get the file extension
      const extension = fileUrl.split(".").pop().toLowerCase();

      if (extension === "pdf") {
        window.open(fileUrl, "_blank");
      } else {
        // If not PDF, download the file
        window.open(fileUrl, "_blank");
      }
    } catch (error) {
      console.error("Error handling file:", error);
    }
  };

  return { columns, handleSubmit, data, loading, showForm, addloading, form, Form, setShowForm };
}

export default Service;
