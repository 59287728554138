import React from "react";
import PropTypes from "prop-types";

const ValidationError = ({ errorMessage }) => {
  return errorMessage ? (
    <div className="invalid-feedback invalid-message">{errorMessage}</div>
  ) : null;
};

ValidationError.propTypes = {
  errorMessage: PropTypes.string,
};

export default ValidationError;
