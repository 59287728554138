import React, { useState, useEffect } from "react";
import { Button, Form, message } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import TrainingService from "./TrainingAPI";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function Service() {
  const [trainings, setTrainings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [currentTrainingId, setCurrentTrainingId] = useState(null);
  const navigate = useNavigate();

  // Fetch data on component mount
  useEffect(() => {
    fetchTrainingData();
  }, []);

  const fetchTrainingData = async () => {
    setLoading(true);
    try {
      const data = await TrainingService.fetchTrainings();
      setTrainings(data);
    } catch (error) {
      message.error("Failed to fetch training data");
    } finally {
      setLoading(false);
    }
  };

  // Show form for Add/Edit
  const showForm = (record) => {
    if (record) {
      // Set the form values if editing
      form.setFieldsValue({
        ...record,
        startDate: moment(record.startDate),
        endDate: moment(record.endDate),
      });
      setEditMode(true);
      setCurrentTrainingId(record.trainingId);
    } else {
      form.resetFields();
      setEditMode(false);
      setCurrentTrainingId(null);
    }
    setIsFormVisible(true);
  };

  // Handle form submission for Add/Edit
  const handleFormSubmit = async () => {
    try {
      setAddLoading(true);
      const values = await form.validateFields();
      const formattedData = {
        ...values,
        startDate: values.startDate.format("YYYY-MM-DD"),
        endDate: values.endDate.format("YYYY-MM-DD"),
      };

      if (editMode) {
        // Update existing training
        await TrainingService.editTraining(currentTrainingId, formattedData);
        message.success("Training updated successfully");
      } else {
        // Add new training
        await TrainingService.addTraining(formattedData);
        message.success("Training added successfully");
      }

      fetchTrainingData();
      setIsFormVisible(false);
    } catch (error) {
      message.error("Failed to submit form");
    } finally {
      setAddLoading(false);
    }
  };

  const validateEndDate = (rule, value) => {
    const startDate = form.getFieldValue("startDate");

    // If no end date is selected, we can't validate
    if (!value) {
      return Promise.resolve();
    }

    // Check if start date is defined and if end date is before or same as start date
    if (startDate && value.isBefore(startDate, "day")) {
      return Promise.reject(new Error("End date must be after the start date"));
    }

    return Promise.resolve();
  };

  // Define columns for Ant Design Table
  const columns = [
    {
      title: "Training Name",
      dataIndex: "trainingName",
      key: "trainingName",
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
    },
    {
      title: "Mentor",
      dataIndex: "mentor",
      key: "mentor",
    },
    {
      title: "Start_Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => moment(text).format("MMM D, YYYY"),
    },
    {
      title: "End_Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => moment(text).format("MMM D, YYYY"),
    },
    {
      title: "Enrolled Employee",
      key: "action",
      render: (text, record) => (
        <span>
          <Button
            icon={<EyeOutlined />}
            onClick={() => navigate(`/trainingDetail/${record.trainingId}`)}
          >
            View Details
          </Button>
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Button icon={<EditOutlined />} onClick={() => showForm(record)}>
            Edit
          </Button>
        </span>
      ),
    },
  ];

  return {
    trainings,
    columns,
    validateEndDate,
    handleFormSubmit,
    loading,
    isFormVisible,
    addLoading,
    showForm,
    form,
    editMode,
    setIsFormVisible,
  };
}

export default Service;
