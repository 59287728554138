import "./ViewCareer.scss";
import logo from "assets/images/datagonomix.jpg";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import JobOpeningService from "layouts/APIServices/JopOpeningService";
import JobSideDetail from "./JobSideDetail";
import InputFields from "./InputFields";

function FinancialAnalyst() {
  const { handleShow, jobDetails, show } = JobOpeningService();

  const renderHTML = (htmlContent) => {
    return { __html: htmlContent };
  };
  // Assign job details based on job nam
  return (
    <div className="wrapper d-flex flex-column min-vh-100 bg-light">
      <div className="body flex-grow-1 px-5">
        <div style={{ marginTop: "50px", display: "flex" }}>
          <img src={logo} alt="" height={"40px"} /> &nbsp;&nbsp;&nbsp;
          <h4 className="logo">Datagonomix</h4>
        </div>
        <div style={{ display: "flex", marginTop: "30px" }}>
          <div className="row">
            <div className="col-xl-8 mb-2">
              <div className="card" style={{ flex: "1", marginRight: "20px" }}>
                <div className="card-body">
                  <ul>
                    <Link className="back-link" to="/viewcareer">
                      <div className="backjob-opening">
                        <svg viewBox="0 0 8 7" width="8" height="7" className="back-icon">
                          <g fillRule="evenodd" stroke="none" strokeWidth="1">
                            <g>
                              <g transform="translate(-56 -101) translate(56 101)">
                                <path d="M3.4693 0 3.9985 1.2247 1.7463 3.5002 4 5.774 3.4697 7 0 3.5002z"></path>
                                <path d="M2 3H8V4H2z"></path>
                              </g>
                            </g>
                          </g>
                        </svg>
                        <span>Job Openings</span>
                      </div>
                    </Link>
                    {jobDetails && (
                      <div>
                        <>
                          <li style={{ fontSize: "medium" }}>
                            <h2 className="card-title heading-ViewCaree">
                              {jobDetails.postingInfo.postingJobTitle}
                            </h2>
                          </li>
                          <hr className="hr-viewcareer"></hr>
                        </>
                      </div>
                    )}
                    {show && (
                      <>
                        <div className="aboutus">
                          <div className="aboutus-i">
                            <div className="about-ii">
                              {jobDetails && (
                                <>
                                  <div className="aboutus-iii">
                                    <div
                                      dangerouslySetInnerHTML={renderHTML(
                                        jobDetails.postingInfo.jobDescription
                                      )}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <hr className="hr-viewcareer"></hr>
                        </div>
                        <div className="aboutus">
                          <div className="aboutus-i">
                            <div className="about-ii">
                              {jobDetails && (
                                <>
                                  <div className="aboutus-iii">
                                    <h4>Skills Required:</h4>
                                    <div>{jobDetails.postingInfo.skills}</div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <hr className="hr-viewcareer"></hr>
                        </div>
                      </>
                    )}
                    {!show && (
                      <>
                        <p style={{ fontSize: "medium" }}>
                          <span style={{ fontWeight: "bold" }}>Apply for this Position</span>
                        </p>
                        <InputFields />
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              {jobDetails && (
                <>
                  <div
                    className="card block"
                    style={{ flex: "1", height: "565px" }}
                  >
                    <div className="fab-Card fab-Card--sizeFull fab-Card--withoutBottomGradient">
                      <div style={{ padding: "32px 24px" }}>
                        <button
                          className="jss-f143 css-8ef6qm"
                          tabIndex="0"
                          type="button"
                          onClick={handleShow}
                        >
                          <span className="jss-f149">Apply for This Job</span>
                        </button>
                      </div>
                    </div>
                    <JobSideDetail />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinancialAnalyst;
