import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function hiringProcessService() {
  const { empId } = useParams();
  const navigate= useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL
  const location = useLocation();
  const employeeEmail = location.state?.employeeEmail;

  const [formData, setFormData] = useState({
    employeeId: empId,
    email: employeeEmail,
    WelcomeEmail: "",
    MentorAssignment: "",
    RecurringOneOnOneMeetings: "",
    FirstDayMeetings: "",
    WelcomeMeeting: "",
    WelcomeLunch: "",
    BusinessIntroduction: "",
    OfficeTour: "",
    RegularCheckIns: "",
    ShadowingOpportunities: "",
    AdHocSupport: "",
    BenefitsEnrollment: "",
    EmergencyContactInfo: "",
    EmployeeHandbook: "Employee handbook featuring all company policies.",
    DirectDepositInfo: "Direct deposit and payroll information.",
    EmploymentEligibilityForm: "1-9 form (employment eligibility verification).",
    NDAOrNonCompete:
      "Non-disclosure agreement, non-compete, or confidentiality agreement (if applicable).",
    StateTaxWithholdingForm: "",
    FederalTaxWithholdingForm:
      "W-4 form (employee`s withholding certificate for federal taxes) or W-9 for contractors.",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const getEmailStatus = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/EmployeeJobApplication/GetEmailStatus?email=${employeeEmail}`
        );
        if (response.status === 200) {
          const data = response.data;
          setIsSubmitted(data.status === 8);
        } else {
          throw new Error("Failed to fetch email status");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to fetch email status");
      }
    };
  
    getEmailStatus();
  }, [apiUrl, employeeEmail]);
  


  const handleSubmit = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/api/EmployeeInterview/Hiring-Onboarding-Process`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            employeeId: empId, // Include employeeId
            email: employeeEmail, // Include email
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add onboarding data");
      }

      // Reset form data on successful submission
      toast.success("Onboarding data added successfully");
      localStorage.setItem(`onboarding_${empId}`, 'submitted');
      setIsSubmitted(true);
      navigate(`/humanResource/${empId}`);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to add onboarding data please try again to select data");
    }
  };

  const handleNavigation=()=>{
    navigate(`/humanResource/${empId}`);
  }

  return {
    formData,
    setFormData,
    handleSubmit,
    isSubmitted,
    handleNavigation
  };
}

export default hiringProcessService;
