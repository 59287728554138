import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const api = `${apiUrl}/api/LeaveAddPolicy/Get-Leave-request`;

const gradientLineChartData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  datasets: [
    {
      label: "Employees on Leave",
      fill: true,
      lineTension: 0.5,
      color: "info",
      backgroundColor: 'rgba(75,192,192,0.2)',
      borderColor: 'rgba(75,192,192,1)',
      borderWidth: 2,
      data: Array(12).fill(0),
    },
  ],
};

// Function to fetch leave data and update gradientLineChartData
const fetchAndSetLeaveData = async () => {
  try {
    const response = await axios.get(api);
    const requestData = response.data;

    // Aggregate total employees on leave for each month
    const monthEmployeeCounts = Array(12).fill(0); // Initialize array to store employee counts per month

    const filteredRequests = requestData.filter(request => request.status === 1);

    filteredRequests.forEach(request => {
      const fromDate = new Date(request.from);
      const toDate = new Date(request.to);

      const fromMonth = fromDate.getMonth();
      const toMonth = toDate.getMonth();

      // Add 1 to each month in the range [fromMonth, toMonth] (for each employee)
      for (let month = fromMonth; month <= toMonth; month++) {
        monthEmployeeCounts[month]++;
      }
    });
    gradientLineChartData.datasets[0].data = monthEmployeeCounts;

  } catch (error) {
    console.error("Error fetching leave requests:", error);
  }
};
fetchAndSetLeaveData();

export default gradientLineChartData;
