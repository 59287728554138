import React from "react";
import { Table, Button, Input, Upload, Card, Row, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Service from "./Service";

const Complaince = () => {
  const { columns, handleSubmit, data, loading, showForm, addloading, form, Form, setShowForm } =
    Service();

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card title="Complaince" className="card-professional">
            {!showForm ? (
              <>
                <Button
                  type="primary"
                  onClick={() => setShowForm(true)}
                  style={{ marginBottom: 16 }}
                >
                  Add New Policy
                </Button>
                <Table
                  className="table-responsive"
                  dataSource={data}
                  columns={columns}
                  rowKey="complainceId"
                  loading={loading}
                />
              </>
            ) : (
              <Form form={form} onFinish={handleSubmit} layout="vertical">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Policy Name"
                      name="policyName"
                      rules={[{ required: true, message: "Please enter policy name" }]}
                    >
                      <Input placeholder="Enter Policy Name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Upload Policy File"
                      name="policyAction"
                      valuePropName="file"
                      rules={[{ required: true, message: "Please upload a file" }]}
                    >
                      <Upload beforeUpload={() => false} accept=".pdf,.docx,.doc" maxCount={1}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button disabled={addloading} type="primary" htmlType="submit">
                    {addloading ? "Submitting" : "Submit"}
                  </Button>
                  <Button style={{ marginLeft: 8 }} onClick={() => setShowForm(false)}>
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Card>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Complaince;
