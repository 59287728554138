import React from "react";
import { Table, Card } from "antd";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import SoftBox from "components/SoftBox";
import Footer from "layouts/authentication/components/Footer";
import Service from "./Service";

function Performance() {
  const {
    departments,
    loading,
    handleDepartmentChange,
    handleDownloadReport,
    columns,
    selectedDepartment,
    employees,
  } = Service();

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card title="Performance" className="card-professional">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <SoftBox color="text" style={{ marginRight: "20px" }}>
                <div style={{ display: "inline-block" }}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    size="small"
                    onClick={handleDownloadReport}
                  >
                    <i className="fa fa-download" aria-hidden="true"></i>&nbsp;Download Employee
                    Reports
                  </SoftButton>
                </div>
              </SoftBox>
              <div className="mb-3" style={{ display: "inline-block" }}>
                <select
                  className="form-select form-select-sm"
                  value={selectedDepartment}
                  onChange={handleDepartmentChange}
                >
                  <option value="All Departments">All Departments</option>
                  {departments.map((department) => (
                    <option key={department} value={department}>
                      {department}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <Table
                className="table-responsive"
                columns={columns}
                dataSource={employees}
                loading={loading}
                rowKey={(record) => record.id}
              />
            </div>
          </Card>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Performance;
