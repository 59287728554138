import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { fetchEmployees, fetchJobPostings, fetchDepartments } from "./RecruitmentAPI";

function RecruitmentService() {
  const [employees, setEmployees] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [jobPostings, setJobPostings] = useState([]);
  const [postingJobTitles, setPostingJobTitles] = useState([]);
  const [selectedJobTitle, setSelectedJobTitle] = useState("All postingJobTitle");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("All Departments");
  const [selectedMonth, setSelectedMonth] = useState(""); // State for selected month
  // Generate month names dynamically
  const monthNames = Array.from({ length: 12 }, (_, i) =>
    new Date(0, i).toLocaleString("default", { month: "long" })
  );

  useEffect(() => {
    const loadData = async () => {
      try {
        const employeesData = await fetchEmployees();
        setAllEmployees(employeesData);
        setEmployees(employeesData);

        const jobPostingsData = await fetchJobPostings();
        setJobPostings(jobPostingsData);
        const jobTitles = jobPostingsData.map((post) => post.postingJobTitle);
        setPostingJobTitles(["All postingJobTitle", ...new Set(jobTitles)]);

        const departmentsData = await fetchDepartments();
        setDepartments(["All Departments", ...departmentsData]);
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    if (selectedJobTitle === "All postingJobTitle") {
      setEmployees(allEmployees);
    } else {
      const filteredEmployees = allEmployees.filter(
        (employee) => employee.forJobApply === selectedJobTitle
      );
      setEmployees(filteredEmployees);
    }
  }, [selectedJobTitle, allEmployees]);

  const statusMap = {
    0: "Applied",
    1: "Pending",
    2: "ShortList",
    3: "ShortList",
    4: "Interview",
    5: "Interview In-Process",
    6: "Contract Send",
    7: "Contract Received",
    8: "Qualified",
  };

  const handleJobTitleChange = (e) => {
    setSelectedJobTitle(e.target.value);
  };

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const employeeColumns = [
    {
      title: "Employee ID",
      dataIndex: "employeeId",
      key: "employeeId",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "For Position",
      dataIndex: "forJobApply",
      key: "forJobApply",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => statusMap[status],
    },
  ];

  const jobPostingColumns = [
    {
      title: "Job Title",
      dataIndex: "postingJobTitle",
      key: "postingJobTitle",
    },
    {
      title: "Recruiter",
      dataIndex: "recruiter",
      key: "recruiter",
    },
    {
      title: "Hiring Lead",
      dataIndex: "hiringLead",
      key: "hiringLead",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (createdOn) => {
        const formattedDate = new Date(createdOn).toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
        return formattedDate;
      },
    },
    {
      title: "Read More",
      key: "readMore",
      render: (_, record) => (
        <button className="btn btn-link" onClick={() => handleReadMore(record)}>
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      ),
    },
  ];

  const handleReadMore = (record) => {
    Modal.info({
      title: "Job Posting Details",
      content: (
        <div>
          <p>
            <strong>Posted Job Id:</strong> {record.postingJobId}
          </p>
          <p>
            <strong>Employment Type:</strong> {record.employementType}
          </p>
          <p>
            <strong>Compensation:</strong> {record.compensation}
          </p>
          <p>
            <strong>Created On:</strong> {new Date(record.createdOn).toLocaleString("en-US")}
          </p>
          <p>
            <strong>Job Status:</strong> {record.jobStatus}
          </p>
        </div>
      ),
      width: 600,
    });
  };

  return {
    postingJobTitles,
    departments,
    handleJobTitleChange,
    handleDepartmentChange,
    handleMonthChange,
    employeeColumns,
    jobPostingColumns,
    selectedMonth,
    selectedDepartment,
    jobPostings,
    employees,
    monthNames,
  };
}

export default RecruitmentService;
