import "./ExpenseManagment.scss";
import SoftButton from "components/SoftButton";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import ExpenseManagementService from "../EmployeeApiService/ExpenseManageService";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DownloadExecelSheet from "../../assets/images/calculationExcellShet.png";

function ExpenseManagement() {
  const {
    formData,
    formDataError,
    handleChange,
    isLoading,
    handleAddExpense,
    handleUploadDocChange,
    handleUploadCalculationDoc,
    managerName,
  } = ExpenseManagementService();
  const navigate = useNavigate();
  const handleCancelExpense = () => {
    navigate("/employeedashboard/expenseManagement");
  };

  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <ToastContainer />
          <div className="body flex-grow-1 px-3">
            <div className="card">
              <div className="card-header" style={{ position: "relative" }}>
                <h5>Add Expense</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-5">
                    <div className="mb-3">
                      <label
                        htmlFor="beneficiary"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Employee Name *
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Employee name here"
                        disabled
                        name="employeeName"
                        value={formData.employeeName}
                        onChange={handleChange}
                        className={`form-control ${
                          formDataError.employeeNameError ? "is-invalid" : ""
                        }`}
                      />
                      {formDataError.employeeNameError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.employeeNameError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Manager *
                      </label>
                      <select
                        aria-label="Default select example"
                        value={formData.manager}
                        name="manager"
                        onChange={handleChange}
                        className={`form-select ${formDataError.managerError ? "is-invalid" : ""}`}
                      >
                        <option value="">Select Manager</option>
                        <option value={managerName}>{managerName}</option>
                      </select>
                      {formDataError.managerError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.managerError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="mb-3">
                      <label htmlFor="Date" className="form-label" style={{ fontSize: "medium" }}>
                        Request Date *
                      </label>
                      <input
                        type="date"
                        name="Date"
                        value={formData.Date}
                        onChange={handleChange}
                        className={`form-control ${formDataError.DateError ? "is-invalid" : ""}`}
                      />
                      {formDataError.DateError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.DateError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        htmlFor="description"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Description *
                      </label>
                      <input
                        type="text"
                        placeholder="Enter description here"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className={`form-control ${
                          formDataError.descriptionError ? "is-invalid" : ""
                        }`}
                      />
                      {formDataError.descriptionError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.descriptionError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="mb-3">
                      <label
                        htmlFor="department"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Department *
                      </label>
                      <input
                        type="text"
                        placeholder="Enter department here"
                        disabled
                        name="department"
                        value={formData.department}
                        onChange={handleChange}
                        className={`form-control ${
                          formDataError.departmentError ? "is-invalid" : ""
                        }`}
                      />
                      {formDataError.departmentError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.departmentError}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        htmlFor="charges"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Charges *
                      </label>
                      <input
                        type="text"
                        placeholder="Enter charges here"
                        name="charges"
                        value={formData.charges}
                        onChange={handleChange}
                        className={`form-control ${formDataError.chargesError ? "is-invalid" : ""}`}
                      />
                      {formDataError.chargesError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.chargesError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="mb-3">
                      <label
                        htmlFor="receipt"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Receipt *
                      </label>
                      <input
                        type="file"
                        accept=".pdf,.PDF,.docx,.DOCX,.doc,.DOC"
                        onChange={handleUploadDocChange}
                        className={`form-control ${formDataError.receiptError ? "is-invalid" : ""}`}
                      />
                      {formDataError.receiptError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.receiptError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        htmlFor="calculationSheet"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Expense Sheet *
                      </label>
                      <input
                        type="file"
                        accept=".pdf, .PDF, .docx, .DOCX, .doc, .DOC, .xls, .XLS, .xlsx, .XLSX"
                        onChange={handleUploadCalculationDoc}
                        className={`form-control ${
                          formDataError.calculationSheetError ? "is-invalid" : ""
                        }`}
                      />
                      {formDataError.calculationSheetError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.calculationSheetError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <SoftButton
                    variant="outlined"
                    style={{ float: "inline-end" }}
                    color="info"
                    size="small"
                    onClick={handleAddExpense}
                  >
                    {isLoading ? "Submitting..." : "Submit"}
                    {isLoading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </SoftButton>
                  <SoftButton
                    variant="outlined"
                    style={{ float: "inline-end", marginRight: "10px" }}
                    color="error"
                    size="small"
                    onClick={handleCancelExpense}
                  >
                    Cancel
                  </SoftButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}

export default ExpenseManagement;
