// src/pdfGenerator.js
import jsPDF from "jspdf";
import "jspdf-autotable";

export const generateSingleReport = (reportData, month, year) => {
  const doc = new jsPDF();
  doc.setFontSize(18);
  doc.text("Employee Finance Report", 14, 22);

  const lineY = 28;
  const lineWidth = 0.5;
  const lineColor = [192, 192, 192];
  doc.setLineWidth(lineWidth);
  doc.setDrawColor(...lineColor);
  doc.line(14, lineY, 200, lineY);

  const marginTop = 3;
  const marginTopEOM = 2;
  doc.setFontSize(12);
  doc.text(`Employee ID: ${reportData.employeeId}`, 14, 32 + marginTop);
  doc.text(`Name: ${reportData.name}`, 14, 38 + marginTop);
  doc.text(`Email: ${reportData.email}`, 14, 44 + marginTop);

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December",
  ];
  const monthName = monthNames[month - 1];
  doc.text(`Report Date: ${monthName} ${year}`, 14, 51 + marginTopEOM);
  doc.text(`Total Sessions: ${reportData.totalSessions}`, 14, 59);
  doc.text(`Payment Status: ${reportData.isPaid ? "Paid" : "Un-Paid"}`, 14, 65);
  doc.text(`Role: ${reportData.role}`, 14, 72);

  if (reportData.sessions && reportData.sessions.length > 0) {
    const headers = [["Session Mode", "Session Date & Time", "Session Amount"]];
    const data = reportData.sessions.map(session => [
      session.sessionMode,
      new Date(session.dateTime).toLocaleString(),
      session.amount,
    ]);

    const totalSessionAmount = reportData.sessions.reduce((sum, session) => sum + session.amount, 0);
    data.push(["Total Amount", "", totalSessionAmount]);

    doc.autoTable({
      startY: 78,
      head: headers,
      body: data,
      headerStyles: {
        fillColor: [192, 192, 192],
        fontSize: 8,
      },
      styles: {
        fontSize: 8,
        overflow: "linebreak",
      },
    });
  } else {
    const headers = [["Name", "Email", "Session"]];
    const data = [[reportData.name, reportData.email, "No session data available"]];
    doc.autoTable({
      startY: 68,
      head: headers,
      body: data,
      headerStyles: {
        fillColor: [192, 192, 192],
        fontSize: 8,
      },
      styles: {
        fontSize: 8,
        overflow: "linebreak",
      },
    });
  }

  doc.save(`employee_finance_report_${reportData.name}_${reportData.month}_${reportData.year}.pdf`);
};

export const generateMultipleReport = (reportData, month, year) => {
  const doc = new jsPDF();
  doc.setFontSize(18);
  doc.text("Employee Finance Report", 14, 22);

  const lineY = 28;
  const lineWidth = 0.5;
  const lineColor = [192, 192, 192];
  doc.setLineWidth(lineWidth);
  doc.setDrawColor(...lineColor);
  doc.line(14, lineY, 200, lineY);

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December",
  ];
  const monthName = monthNames[month - 1];
  doc.setFontSize(12);
  doc.text(`Report Date: ${monthName}, ${year}`, 14, 35);

  const headers = [
    [
      "Employee ID",
      "Name",
      "Email",
      "Role",
      "Total Sessions",
      "Total Amount",
      "Payment Status",
    ],
  ];
  const data = reportData.map(employee => [
    employee.employeeId,
    employee.name,
    employee.email,
    employee.role,
    employee.totalSessions,
    employee.amount,
    employee.isPaid ? "Paid" : "Un-Paid",
  ]);

  doc.autoTable({
    startY: 40,
    head: headers,
    body: data,
    headerStyles: {
      fillColor: [192, 192, 192],
      fontSize: 8,
    },
    styles: {
      fontSize: 8,
      overflow: "linebreak",
    },
  });

  doc.save(`employee_finance_report_${monthName}_${year}.pdf`);
};
