import { Button, Card } from "antd";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import { useState, useEffect } from "react";
import "./Training.scss";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

function EmployeeTrainingDetail() {
  const { trainingId } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [trainingDetail, setTrainingDetail] = useState(null);
  useEffect(() => {
    async function fetchTrainingDetail() {
      try {
        const response = await axios.get(
          `${apiUrl}/api/TrainingDevelopement/GetAllByTrainingId/${trainingId}`
        );
        setTrainingDetail(response.data);
      } catch (error) {
        console.error("Error fetching employee detail:", error);
      }
    }

    fetchTrainingDetail();
  }, [trainingId]);

  function formatDateTime(dateTimeString) {
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return new Date(dateTimeString).toLocaleString("en-US", options);
  }

  const back = () => {
    navigate("/employeedashboard/training");
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="row">
            <Card
              className="card-professional"
              title="Training Details"
              extra={
                <Button type="default" onClick={back}>
                  Back
                </Button>
              }
            >
              {trainingDetail && (
                <>
                  <div className="trainingDetail-card mb-2">
                    <h6 className="h6-trainingdetail">Name </h6>
                    <span className="h6-trainingdetail">
                      {trainingDetail.trainingInfo.trainingName}
                    </span>
                  </div>
                  <div className="trainingDetail-card mb-2">
                    <h6 className="h6-trainingdetail">Provider</h6>
                    <span className="h6-trainingdetail">
                      {trainingDetail.trainingInfo.provider}
                    </span>
                  </div>
                  <div className="trainingDetail-card mb-2">
                    <h6 className="h6-trainingdetail">Mentor</h6>
                    <span className="h6-trainingdetail"> {trainingDetail.trainingInfo.mentor}</span>
                  </div>
                  <div className="trainingDetail-card mb-2">
                    <h6 className="h6-trainingdetail">Budget</h6>
                    <span className="h6-trainingdetail">
                      {" "}
                      {trainingDetail.trainingInfo.allocated_budget}
                    </span>
                  </div>
                  <div className="trainingDetail-card mb-2">
                    <h6 className="h6-trainingdetail">Start Date</h6>
                    <span className="h6-trainingdetail">
                      {formatDateTime(trainingDetail.trainingInfo.startDate)}
                    </span>
                  </div>
                  <div className="trainingDetail-card mb-2">
                    <h6 className="h6-trainingdetail">End Date</h6>
                    <span className="h6-trainingdetail">
                      {formatDateTime(trainingDetail.trainingInfo.endDate)}
                    </span>
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EmployeeTrainingDetail;
