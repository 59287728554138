import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateDynamicInfo } from "../../../layouts/Services/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

function DynamicOrganizationService({ onNext, selectedEmail }) {
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { initiateData, performanceData } = useSelector((state) => state);

  const [dynamicFormData, setDynamicFormdata] = useState({
    personalId: null,
    employeeEmail: "",
    valuing_Diversity: "",
    overall_Accountability_Rating: "",
    overall_Performance_Rating: "",
    overall_DynamicOrganization_Rating: "",
    total_performance_Rating: "",
    associate_Strengths: "",
    associate_Development: "",
    action_Plan: "",
    associate_Comments: "",
    associate_Name: "",
    Date: "",
    supervisor_Name: "",
  });

  useEffect(() => {
  
    if (selectedEmail && selectedEmail.email && selectedEmail.personalId !== null) {
      console.warn("Selected Email:", selectedEmail.email);
      console.warn("Selected PersonalId:", selectedEmail.personalId);
  
      setDynamicFormdata(prevState => {
        const updatedState = {
          ...prevState,
          employeeEmail: selectedEmail.email,
          personalId: selectedEmail.personalId,
        };
        return updatedState;
      });
    }
  }, [selectedEmail]);

  const [dynamicFormDataError, setDynamicFormdataError] = useState({
    valuing_DiversityError: "",
    overall_Accountability_RatingError: "",
    overall_Performance_RatingError: "",
    overall_DynamicOrganization_RatingError: "",
    total_performance_RatingError: "",
    associate_StrengthsError: "",
    associate_DevelopmentError: "",
    action_PlanError: "",
    associate_CommentsError: "",
    associate_NameError: "",
    DateError: "",
    supervisor_NameError: "",
  });

  const handleDynamicChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }
  
    const { name, value } = e.target;
  
    // Clear the error message if the value is not empty
    if (value.trim() !== "") {
      setDynamicFormdataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } else {
      // Set the error message if the value is empty
      setDynamicFormdataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: `${name} is required.`,
      }));
    }
  
    setDynamicFormdata((prevState) => {
      const newFormData = { ...prevState, [name]: value };
  
      const {
        overall_Accountability_Rating,
        overall_Performance_Rating,
        overall_DynamicOrganization_Rating,
      } = newFormData;
  
      const ratings = [
        parseFloat(overall_Accountability_Rating),
        parseFloat(overall_Performance_Rating),
        parseFloat(overall_DynamicOrganization_Rating),
      ];
  
      const validRatings = ratings.filter((rating) => !isNaN(rating));
  
      const sum = validRatings.reduce((acc, curr) => acc + curr, 0);
      const count = validRatings.length;
  
      newFormData.total_performance_Rating = count > 0 ? (sum / count).toFixed(2) : '';
  
      return newFormData;
    });
  };
  
  

  function validateDynamicValidation(dynamicFormData, setDynamicFormdataError) {
    let hasError = false;

    Object.keys(dynamicFormData).forEach((fieldName) => {
      if (
        (typeof dynamicFormData[fieldName] === "string" &&
          (!dynamicFormData[fieldName] || !dynamicFormData[fieldName].trim())) || // For string fields
        (typeof dynamicFormData[fieldName] === "number" &&
          (isNaN(dynamicFormData[fieldName]) || dynamicFormData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setDynamicFormdataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      }
    });

    if (hasError) {
      return false;
    }

    const allFieldsFilled = Object.values(dynamicFormData).every((value) => {
      return (
        (typeof value === "string" && value.trim() !== "") ||
        (typeof value === "number" && !isNaN(value))
      );
    });

    if (!allFieldsFilled) {
      setDynamicFormdataError((prevState) => ({
        ...prevState,
        commonError: "Please fill in all fields before saving.",
      }));
      return false;
    }

    return true;
  }

  return {
    dynamicFormData,
    setDynamicFormdata,
    dynamicFormDataError,
    setDynamicFormdataError,
    validateDynamicValidation,
    handleDynamicChange,
    isLoading,
    onNext,
    setIsLoading,
  };
}

export default DynamicOrganizationService;
