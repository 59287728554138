import React, { useState, useEffect } from "react";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import PropTypes from "prop-types";
import axios from "axios";

function Author({ name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

Author.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default function DataTable() {
  const [rows, setRows] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchLeaveEmployee = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/LeaveAddPolicy/Get-Leave-request`);
        const requestData = response.data;

        // Filter the requestData to include only approved requests (status === 1)
        const approvedRequests = requestData.filter(item => item.status === 1);

        const formattedRows = approvedRequests.map((item, index) => ({
          Employee: (
            <Author
              // image={images[index % images.length]}
              name={`${item.employeeFirstName} ${item.employeeLastName}`}
              email={item.email}
            />
          ),
          Department: (
            <SoftTypography variant="caption" color="text" fontWeight="medium">
              {item.department}
            </SoftTypography>
          ),
          Days_Off: (
            <SoftTypography variant="caption" color="text" fontWeight="medium">
              {item.amountDays}
            </SoftTypography>
          ),
          From: (
            <SoftTypography variant="caption" color="text" fontWeight="medium">
              {new Date(item.from).toLocaleDateString()}
            </SoftTypography>
          ),
          To: (
            <SoftTypography variant="caption" color="text" fontWeight="medium">
              {new Date(item.to).toLocaleDateString()}
            </SoftTypography>
          ),
          Leave_Policy: (
            <SoftTypography variant="caption" color="text" fontWeight="medium">
              {item.timeOffCategory}
            </SoftTypography>
          ),
          status: (
            <SoftTypography variant="caption" color="text" fontWeight="medium">
              {item.status === 0 ? "Pending" : item.status === 1 ? "Approved" : "Unknown Status"}
            </SoftTypography>
          ),
        }));

        setRows(formattedRows);
      } catch (error) {
        console.error("Error fetching leave requests:", error);
      }
    };

    fetchLeaveEmployee();
  }, [apiUrl]);

  const columns = [
    { title: "Employee", name: "Employee", align: "left" },
    { title: "Department", name: "Department", align: "left" },
    { title: "Days_Off", name: "Days_Off", align: "center", width: "10px" },
    { title: "From", name: "From", align: "center" },
    { title: "To", name: "To", align: "center" },
    { title: "Leave Policy", name: "Leave_Policy", align: "center" },
    { title: "Status", name: "status", align: "center" },
  ];

  return {
    columns,
    rows,
  };
}
