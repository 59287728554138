import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

function OnboardingService() {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { empId, email } = useParams();
  const [isOnboarded, setIsOnboarded] = useState(false);

  const [formData, setFormData] = useState({
    employeeId: "",
    email: "",
    welcomePacket: "",
    roleAndCompensationInfo: "",
    payrollSetup: "",
    companyPolicies: "",
    paperworkIntroMeetings: "",
    periodicReviewsScheduling: "",
    backgroundCheck: "",
    drugScreen: "",
    emailCreation: "",
    accountPermissionsSetup: "",
    equipmentSetup: "",
    status: "True",
  });

  useEffect(() => {
    if (email) {
      const fetchOnboardingStatus = async () => {
        try {
          const response = await fetch(
            `${apiUrl}/api/ManagerOnboarding/Get-ManagerOnboarding-By-Email?email=${encodeURIComponent(
              email
            )}`
          );
          const data = await response.json();

          if (response.ok) {
            // Check if any of the entries have Status === "True"
            const onboarded = data.some((item) => item.status === "True");
            setIsOnboarded(onboarded);
          } else {
            console.error("Failed to fetch onboarding status");
          }
        } catch (error) {
          console.error("Error fetching onboarding status:", error);
        }
      };

      fetchOnboardingStatus();
    }
  }, [email, apiUrl]);

  useEffect(() => {
    if (empId && email) {
      setFormData((prevData) => ({
        ...prevData,
        employeeId: parseInt(empId, 10),
        email: decodeURIComponent(email), // Decode email if needed
      }));
    }
  }, [empId, email]);

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/ManagerOnboarding/Add-Manager-Onboarding`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // toast.success("Onboarding data added successfully");
        return true; // Indicate success
      } else {
        toast.error("Please check all boxes");
        return false; // Indicate failure
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to add onboarding data, please try again");
      return false; // Indicate failure
    }
  };

  const handleNavigation = () => {
    navigate(`/humanResource/${formData.employeeId}`);
  };

  return {
    formData,
    setFormData,
    handleSubmit,
    handleNavigation,
    isOnboarded,
  };
}

export default OnboardingService;

// import { useEffect, useState } from "react";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { useNavigate, useParams } from "react-router-dom";

// function OnboardingService() {
//   const navigate = useNavigate();
//   const apiUrl = process.env.REACT_APP_API_URL;
//   const { empId, email } = useParams();
//   const [isOnboarded, setIsOnboarded] = useState(false);

//   const [formData, setFormData] = useState({
//     employeeId: "",
//     email: "",
//     WelcomePacket:
//       "Send out a new hire welcome packet that includes the forms and documents new hires must complete.",
//     RoleAndCompensationInfo: "",
//     PayrollSetup: "",
//     CompanyPolicies: "",
//     PaperworkIntroMeetings: "",
//     PeriodicReviewsScheduling: "",
//     BackgroundCheck: "",
//     DrugScreen: "",
//     EmailCreation: "",
//     AccountPermissionsSetup: "",
//     EquipmentSetup: "",
//     Status: "True",
//   });
//   useEffect(() => {
//     if (email) {
//       const fetchOnboardingStatus = async () => {
//         try {
//           const response = await fetch(`${apiUrl}/api/ManagerOnboarding/Get-ManagerOnboarding-By-Email?email=${encodeURIComponent(email)}`);
//           const data = await response.json();

//           if (response.ok) {
//             // Assuming data is an array and you want to check the status of the first item
//             if (data.length > 0 && data[0].Status === "True") {
//               setIsOnboarded(true);
//             }
//           } else {
//             console.error("Failed to fetch onboarding status");
//           }
//         } catch (error) {
//           console.error("Error fetching onboarding status:", error);
//         }
//       };

//       fetchOnboardingStatus();
//     }
//   }, [email, apiUrl]);

//   useEffect(() => {
//     if (empId && email) {
//       setFormData((prevData) => ({
//         ...prevData,
//         employeeId: parseInt(empId, 10),
//         email: decodeURIComponent(email), // Decode email if needed
//       }));
//     }

//     console.log("emp id", empId);
//     console.log("email", email);
//   }, [empId, email]);

//   const handleSubmit = async () => {
//     console.log("HandleSubmit Called");
//     console.log("Form Data:", formData);

//     try {
//       const response = await fetch(`${apiUrl}/api/ManagerOnboarding/Add-Manager-Onboarding`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formData),
//       });

//       console.log("Response Status:", response.status);
//       console.log("Response Text:", await response.text());

//       if (response.ok) {
//         toast.success("Onboarding data added successfully");
//       } else {
//         toast.error("Failed to add onboarding data, please try again");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("Failed to add onboarding data, please try again");
//     }
//   };

//   const handleNavigation = () => {
//     navigate(`/humanResource/${formData.employeeId}`);
//   };

//   return {
//     formData,
//     setFormData,
//     handleSubmit,
//     handleNavigation,
//     isOnboarded,
//   };
// }

// export default OnboardingService;
