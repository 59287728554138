import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { FileOpenOutlined } from "@mui/icons-material";
import { Card, Space, Table, Tooltip } from "antd";

function OfferLetter() {
  const [data, setData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/EmployeeInterview/GetReceiveContract`);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const result = await response.json();
      if (result && result.contract) {
        setData(
          result.contract.map((contract) => {
            const employee = result.employees.find((e) => e.employeeId === contract.employeeId);
            return {
              key: contract.receiveContractId.toString(),
              receiveContractId: `${contract.receiveContractId}`,
              employeeId: employee ? `${employee.employeeId}` : "Unknown",
              name: employee ? `${employee.firstName} ${employee.lastName}` : "Unknown",
              email: `${contract.email}`,
              documentType: "Offer Letter",
              preview: (
                <Space size="middle">
                  {contract.offerLetter ? (
                    <FileOpenOutlined
                      onClick={() => handlePdfPreview(`${apiUrl}/${contract.offerLetter}`)}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    "No Offer Letter available"
                  )}
                </Space>
              ),
            };
          })
        );
      } else {
        console.error("Contract data not found in response");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePdfPreview = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const columns = [
    {
      title: "Contract Id",
      dataIndex: "receiveContractId",
      key: "receiveContractId",
    },
    {
      title: "Employee Id",
      dataIndex: "employeeId",
      key: "employeeId",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => (
        <Tooltip title={email}>{email.length > 13 ? `${email.slice(0, 13)}...` : email}</Tooltip>
      ),
    },
    {
      title: "Doc Type",
      dataIndex: "documentType",
      key: "documentType",
    },
    {
      title: "Preview",
      dataIndex: "preview",
      key: "preview",
    },
  ];

  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            <Card title="Offer Letters" className="card-professional">
              <div className="row">
                <Table
                  className="table-responsive"
                  bordered
                  dataSource={data}
                  columns={columns}
                  pagination={{ pageSize: 10 }}
                />
              </div>
            </Card>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}

export default OfferLetter;
