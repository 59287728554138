// src/utils/api.js
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchDepartments = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/AttendanceReport/Get-All-Departments`);
    return response.data ? response.data.map((dept) => dept.department) : [];
  } catch (error) {
    console.error("Error fetching departments:", error);
    return []; // Return an empty array on error
  }
};

export const fetchEmployees = async () => {
  try {
    const response = await fetch(`${apiUrl}/api/Job/GetEmployees-AllManagers`);

    // Check if the response is OK
    if (!response.ok) {
      if (response.status === 404) {
        // Handle 404 specifically
        console.error("No employees found.");
        return []; // Return an empty array if no employees are found
      }
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const contentType = response.headers.get("Content-Type");
    if (contentType && contentType.includes("application/json")) {
      return await response.json();
    } else {
      console.error("Response is not JSON.");
      return []; // Return an empty array if the response is not JSON
    }
  } catch (error) {
    console.error("Error fetching employees:", error);
    throw error;
  }
};


export const updateEmployeeStatus = async (personalId) => {
  try {
    await axios.post(`${apiUrl}/api/PersonalInformation/UpdateStatus`, { personalId });
  } catch (error) {
    console.error("Failed to update status:", error);
    throw error;
  }
};
