import React, { useEffect, useState } from "react";
import axios from "axios";
import Item from "examples/Item2/Item";
import marie from "assets/images/HR.png";

function EmployeeNameHeader() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [userName, setUserName] = useState("Employee Name"); // Default to "Employee Name"
  const [role, setRole] = useState("HR Administrator");

  useEffect(() => {
    const email = localStorage.getItem("hremail");
    const loginType = localStorage.getItem("loginType");
    if (email && loginType) {
      fetchUserNameByEmail(email, loginType);
    }
  }, []);

  async function fetchUserNameByEmail(email, loginType) {
    try {
      const endpoint = loginType === "HR" ? "Auth/GetUserByEmail" : "ChildManager/GetChildManagerByEmail";
      const response = await axios.get(`${apiUrl}/api/${endpoint}/${encodeURIComponent(email)}`);
      if (response.status === 200) {
        const { userName } = response.data;
        setUserName(userName);
        setRole(loginType === "HR" ? "HR Administrator" : "Associate Manager");
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <div className="card">
      <div
        className="card-header"
        style={{ position: "relative", display: "flex", alignItems: "left" }}
      >
        <Item
          image={
            <img
              src={marie}
              alt="person"
              style={{ height: "55px", width: "55px", borderRadius: "100px" }}
            />
          }
          title={[userName]}
          date={role}
        />
      </div>
    </div>
  );
}

export default EmployeeNameHeader;
