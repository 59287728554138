import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SoftButton from "components/SoftButton";
import axios from "axios";
import { Table, Space, Card } from "antd";
import { FileOpenOutlined } from "@mui/icons-material";

export default function EmployeeSalarySlip() {
  const navigate = useNavigate();
  const [slipData, setSalarySlipData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const employeeEmail = localStorage.getItem("loginEmail");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/SalarySlip/Get-SalarySlip-By-Email/${employeeEmail}`
        );

        if (!response.data || !Array.isArray(response.data)) {
          throw new Error("Invalid response format or empty data.");
        }

        const slipsWithUrl = response.data.map((slip) => ({
          ...slip,
          fileUrl: `${apiUrl}/${slip.salarySlip}`,
        }));
        setSalarySlipData(slipsWithUrl);
      } catch (error) {
        console.error("Error fetching salary slip data:", error);
        setSalarySlipData([]); // Clear data or handle error state accordingly
      }
    };

    if (employeeEmail) {
      fetchData();
    }
  }, [apiUrl, employeeEmail]);

  const salarySlipColumns = [
    { title: "Department", dataIndex: "department", key: "department" },
    {
      title: "From_Date",
      dataIndex: "fromDate",
      key: "fromDate",
      render: (date) => (date === "0001-01-01" ? "" : date),
    },
    {
      title: "End_Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (date) => (date === "0001-01-01" ? "" : date),
    },
    {
      title: "Salary_Slip",
      dataIndex: "fileUrl",
      key: "fileUrl",
      render: (fileUrl) => (
        <Space size="middle">
          <FileOpenOutlined
            onClick={() => handlePdfPreview(fileUrl)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
  ];

  const handlePdfPreview = (fileUrl) => {
    window.open(fileUrl, "_blank"); // Opens the file in a new tab
  };

  return (
    <Card
      title="Salary Slip"
      style={{ width: "100%", height: "100%" }}
      className="card-professional"
    >
      <div className="row">
        <div className="col-md-12 d-flex justify-content-end">
          <Table
            dataSource={slipData}
            columns={salarySlipColumns}
            rowKey="salarySlipId" // Assuming salarySlipId is unique
            pagination={{ pageSize: 2 }}
            style={{ marginTop: "20px" }}
          />
        </div>
      </div>
    </Card>
  );
}
