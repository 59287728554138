import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function EmployeeContactService() {
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const [formData, setFormData] = useState({
    personalId: "",
    name: "",
    relation: "",
    phoneNo: "",
    homePhone: "",
    email: "",
    address_one: "",
    address_two: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });

  useEffect(() => {
    const fetchPersonalId = async () => {
      const email = localStorage.getItem("loginEmail");
      if (email) {
        try {
          const response = await axios.get(
            `${apiUrl}/api/EmployeeEmergencyContact/Get-Emergency-By-Email`,
            {
              params: { email },
            }
          );
          const personalId = response.data;
          if (personalId) {
            setFormData((prevFormData) => ({ ...prevFormData, personalId }));
          } else {
            toast.error("No personal ID found for this email.");
          }
        } catch (error) {
          console.error("Error fetching personal ID:", error);
          toast.error("Error fetching personal ID.");
        }
      }
    };

    fetchPersonalId();
  }, [apiUrl]);

  const handleChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    // Clear the error message if the value is not empty
    if (value.trim() !== "") {
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } else {
      // Set the error message if the value is empty
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: `${name} is required.`,
      }));
    }

    setFormData({ ...formData, [name]: value });
  };

  function validateValidation(formData, setFormDataError) {
    let hasError = false;

    Object.keys(formData).forEach((fieldName) => {
      if (
        (typeof formData[fieldName] === "string" &&
          (!formData[fieldName] || !formData[fieldName].trim())) || // For string fields
        (typeof formData[fieldName] === "number" &&
          (isNaN(formData[fieldName]) || formData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      }
    });

    if (hasError) {
      return false;
    }

    const allFieldsFilled = Object.values(formData).every((value) => {
      return (
        (typeof value === "string" && value.trim() !== "") ||
        (typeof value === "number" && !isNaN(value))
      );
    });

    if (!allFieldsFilled) {
      setFormDataError((prevState) => ({
        ...prevState,
        commonError: "Please fill in all fields before saving.",
      }));
      return false;
    }

    return true;
  }

  const handleSubmit = async () => {
    const isValid = validateValidation(formData, setFormDataError);
    if (isValid) {
      try {
        setIsLoading(true);
        const updatedFormData = { ...formData };
        const response = await axios.post(
          `${apiUrl}/api/EmployeeEmergencyContact/Add-Emergency-Contact`,
          updatedFormData
        );
        clearFields();
        toast.success("Your Contact added successfully");
      } catch (error) {
        if (error.response) {
          // If the error response is due to more than 2 contacts
          if (error.response.data === "You cannot add more than 2 emergency contacts.") {
            toast.error("You cannot add more than 2 emergency contacts.");
            clearFields();
          } else {
            console.error("Server Error:", error.response.data);
            toast.error(`Server Error: ${error.response.data}`);
          }
        } else if (error.request) {
          // No response received
          console.error("No response received:", error.request);
          toast.error("No response received from the server.");
        } else {
          // Request setup error
          console.error("Error setting up request:", error.message);
          toast.error(`Error: ${error.message}`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const [formDataError, setFormDataError] = useState({
    nameError: "",
    relationError: "",
    phoneNoError: "",
    homePhoneError: "",
    emailError: "",
    address_oneError: "",
    address_twoError: "",
    cityError: "",
    stateError: "",
    postalCodeError: "",
    countryError: "",
  });

  const clearFields = () => {
    setFormData({
      name: "",
      relation: "",
      phoneNo: "",
      homePhone: "",
      email: "",
      address_one: "",
      address_two: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    });
  };

  return {
    formData,
    setFormData,
    formDataError,
    setFormDataError,
    handleChange,
    clearFields,
    validateValidation,
    isLoading,
    handleSubmit,
  };
}

export default EmployeeContactService;
