import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function ExpenseManagementService() {
  const [isLoading, setIsLoading] = useState(false);
  const [managerName, setManagerName] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    personalId: null,
    employeeName: "",
    email:"",
    manager: "",
    Date: "",
    Approveddate:null,
    description: "",
    department: "",
    charges: "",
    receipt: "",
    calculationSheet: "",
    status:"Pending"
  });

  useEffect(() => {
    fetchEmployeesByEmail();
  }, []);

  async function fetchEmployeesByEmail() {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        const response = await axios.get(`${apiUrl}/api/Job/GetEmployee-JobDataby-Email/${loginEmail}`);
        const responseData = response.data;
  
        if (Array.isArray(responseData)) {
          if (responseData.length > 0) {
            const manager = responseData[0].manager;
            const personalId = responseData[0].personalInformation.personalId;
            const firstName = responseData[0].personalInformation.firstName;
            const lastName = responseData[0].personalInformation.lastName;
            const fullName = `${firstName} ${lastName} (${personalId})`;
            setManagerName(manager);
            setFormData((prevState) => ({
              ...prevState,
              personalId: personalId,
              email: loginEmail,
              employeeName: fullName,
            }));
          }
        } else {
          if (responseData.manager && responseData.personalInformation.personalId) {
            const manager = responseData.manager;
            const personalId = responseData.personalInformation.personalId;
            const firstName = responseData.personalInformation.firstName;
            const lastName = responseData.personalInformation.lastName;
            const fullName = `${firstName} ${lastName} (${personalId})`;
            setManagerName(manager);
            setFormData((prevState) => ({
              ...prevState,
              personalId: personalId,
              email: loginEmail,
              employeeName: fullName,
            }));
          }
        }
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const email = localStorage.getItem("loginEmail");
        const response = await axios.get(`${apiUrl}/api/ExpenseManagement/GetEmployee-department/${email}`);
        
        // Ensure response.data.getemployee is not empty and access the department field
        if (response.data.getemployee.length > 0) {
          const departmentName = response.data.getemployee[0].hiringDepartment;
          setFormData(prevFormData => ({
            ...prevFormData,
            department: departmentName
          }));
        } else {
          console.error("No department data found for the logged-in user.");
        }
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
  
    fetchDepartments();
  }, [apiUrl]);
  

  const handleChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    // Update formData state
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear the error message if the value is not empty
    if (value.trim() !== "") {
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } else {
      // Set the error message if the value is empty
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: `${name} is required.`,
      }));
    }
  };

  const handleUploadDocChange = (e) => {
    const file = e.target.files[0];
    const allowedExtensions = [".pdf", ".PDF", ".docx", ".DOCX", ".doc", ".DOC", ".xls", ".XLS", ".xlsx", ".XLSX"];

    if (file) {
      const fileExtension = file.name.split(".").pop();
      if (!allowedExtensions.includes("." + fileExtension)) {
        setFormData({ ...formData, receipt: null });
        setFormDataError((prevState) => ({
          ...prevState,
          receiptError: "",
        }));
        return;
      }
      setFormData({ ...formData, receipt: file });
      setFormDataError((prevState) => ({
        ...prevState,
        receiptError: "",
      }));
    }
  };

  const handleUploadCalculationDoc = (e) => {
    const file = e.target.files[0];
    const allowedExtensions = [".pdf", ".PDF", ".docx", ".DOCX", ".doc", ".DOC", ".xls", ".XLS", ".xlsx", ".XLSX"];

    if (file) {
      const fileExtension = file.name.split(".").pop();
      if (!allowedExtensions.includes("." + fileExtension)) {
        setFormData({ ...formData, calculationSheet: null });
        setFormDataError((prevState) => ({
          ...prevState,
          calculationSheetError: "",
        }));
        return;
      }
      setFormData({ ...formData, calculationSheet: file });
      setFormDataError((prevState) => ({
        ...prevState,
        calculationSheetError: "",
      }));
    }
  };
  const handleAddExpense = async () => {
    const currentDate = new Date();
    formData.Approveddate = currentDate.toISOString();
    const isValid = validateValidation(formData, setFormDataError);
    if (isValid) {
      try {
        setIsLoading(true);
        // Create a new FormData object
        const formDataToSend = new FormData();
        formDataToSend.append("personalId", formData.personalId);
        formDataToSend.append("manager", formData.manager);
        formDataToSend.append("employeeName", formData.employeeName);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("Date", formData.Date);
        formDataToSend.append("Approveddate", formData.Approveddate); // Ensure Approveddate is sent as null if needed
        formDataToSend.append("description", formData.description);
        formDataToSend.append("department", formData.department);
        formDataToSend.append("charges", formData.charges);
        formDataToSend.append("receipt", formData.receipt); // Append the file
        formDataToSend.append("calculationSheet", formData.calculationSheet); // Append the file
  
        // Send the form data with multipart/form-data header
        const response = await axios.post(
          `${apiUrl}/api/ExpenseManagement/Add-ExpenseManagment`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        clearFields();
        toast.success("Expense added successfully");
        navigate("/employeedashboard/expenseManagement");
      } catch (error) {
        console.error("Error Expense:", error);
        toast.error("Failed adding Expense");
      } finally {
        setIsLoading(false);
      }
    }
  };
  
  const [formDataError, setFormDataError] = useState({
    personalIdError: null,
    employeeNameError: "",
    ManagerError: "",
    DateError: "",
    descriptionError: "",
    departmentError: "",
    chargesError: "",
    receiptError: "",
    calculationSheetError: "",
  });

  const clearFields = () => {
    setFormData({
      personalId: null,
      employeeName: "",
      Manager: "",
      Date: "",
      description: "",
      department: "",
      charges: "",
      receipt: "",
      calculationSheet: "",
    });
  };

  function validateValidation(formData, setFormDataError) {
    let hasError = false;

    // Check each field for emptiness or invalidity
    Object.keys(formData).forEach((fieldName) => {
      if (
        (typeof formData[fieldName] === "string" &&
          (!formData[fieldName] || !formData[fieldName].trim())) || // For string fields
        (typeof formData[fieldName] === "number" &&
          (isNaN(formData[fieldName]) || formData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      } else {
        // Clear the error message if the field is not empty
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: "", // Clear the error message
        }));
      }
    });

    // Check if any error occurred
    if (hasError) {
      return false;
    }

    // Check if all fields are filled out
    const isAllFieldsFilled = Object.values(formData).every(
      (value) => value !== null && value !== undefined && value !== ""
    );

    if (!isAllFieldsFilled) {
      // If any field is empty, display an error message
      setFormDataError((prevState) => ({
        ...prevState,
        genericError: "Please fill out all fields.",
      }));
      return false;
    }

    return true;
  }

  return {
    formData,
    setFormData,
    formDataError,
    setFormDataError,
    handleChange,
    validateValidation,
    isLoading,
    handleAddExpense,
    handleUploadDocChange,
    handleUploadCalculationDoc,
    managerName
  };
}

export default ExpenseManagementService;
