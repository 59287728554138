import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import "./EmployeeDetail.scss";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "layouts/authentication/components/Footer";
import { Divider, Empty } from "antd";

function EmployeeDetail() {
  const { personalId } = useParams();
  const [employeeDetail, setEmployeeDetail] = useState(null);
  const [employeeEmergencyDetail, setEmployeeEmergency] = useState(null);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleUserEdit = () => {
    navigate(`/humanResource/${personalId}`, { state: { employeeDetail } });
  };

  useEffect(() => {
    async function fetchEmployeeDetail() {
      try {
        const response = await axios.get(
          `${apiUrl}/api/PersonalInformation/GetAllByPersonalId/${personalId}`
        );
        setEmployeeDetail(response.data);
      } catch (error) {
        console.error("Error fetching employee detail:", error);
      }
    }

    fetchEmployeeDetail();
  }, [personalId]);

  useEffect(() => {
    async function fetchEmployeeEmergencyDetail() {
      try {
        const response = await axios.get(
          `${apiUrl}/api/EmployeeEmergencyContact/Get-Emergency-Detail/${personalId}`
        );
        setEmployeeEmergency(response.data);
      } catch (error) {
        console.error("Error fetching employee emergency details:", error);
      }
    }

    fetchEmployeeEmergencyDetail();
  }, [personalId]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="row">
        <div className="col-md-12">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h6 className="h6-employeedetail" style={{ marginLeft: "15px", color: "cadetblue" }}>
              User Detail View
            </h6>
          </div>
          <div className="card cardSet">
            <div className="card-body">
              {employeeDetail && (
                <>
                  <div className="userDetail-card mb-2">
                    <h6 className="h6-employeedetail">Employee no #</h6>
                    <span className="h6-employeedetail">
                      {employeeDetail.personalInfo.employeeNumber}
                    </span>
                  </div>
                  <hr></hr>
                  <div className="userDetail-card mb-2">
                    <h6 className="h6-employeedetail">First Name</h6>
                    <span className="h6-employeedetail">
                      {employeeDetail.personalInfo.firstName}
                    </span>
                  </div>
                  <hr></hr>
                  <div className="userDetail-card mb-2">
                    <h6 className="h6-employeedetail">Last Name</h6>
                    <span className="h6-employeedetail">
                      {employeeDetail.personalInfo.lastName}
                    </span>
                  </div>
                  <hr></hr>
                  <div className="userDetail-card mb-2">
                    <h6 className="h6-employeedetail">Department</h6>
                    <span className="h6-employeedetail">{employeeDetail.job.team}</span>
                  </div>
                  <hr></hr>
                  <div className="userDetail-card mb-2">
                    <h6 className="h6-employeedetail">Job Title</h6>
                    <span className="h6-employeedetail">{employeeDetail.job.forJobApply}</span>
                  </div>
                  <hr></hr>
                  <div className="userDetail-card mb-2">
                    <h6 className="h6-employeedetail">Location</h6>
                    <span className="h6-employeedetail">{employeeDetail.personalInfo.city}</span>
                  </div>
                  <hr></hr>
                  <div className="userDetail-card mb-2">
                    <h6 className="h6-employeedetail">Hire Date</h6>
                    <span className="h6-employeedetail">
                      {employeeDetail.job.probationStartDate}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div className="row">
        <div className="body flex-grow-1 px-3">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h6 className="h6-employeedetail" style={{ marginLeft: "15px", color: "cadetblue" }}>
              Employee Emergency Contacts
            </h6>
          </div>
          <div className="card cardSet">
            <div className="card-body">
              {employeeEmergencyDetail &&
              employeeEmergencyDetail.emergecnyContactInfo.length > 0 ? (
                employeeEmergencyDetail.emergecnyContactInfo.map((contact, index) => (
                  <div key={index}>
                    <h6 style={{ textAlign: "center" }}>Emergency Contact {index + 1}</h6>
                    <div className="userDetail-card mb-2">
                      <h6 className="h6-employeedetail">Employee ID #</h6>
                      <span className="h6-employeedetail">{contact.personalId}</span>
                    </div>
                    <div className="userDetail-card mb-2">
                      <h6 className="h6-employeedetail">Name</h6>
                      <span className="h6-employeedetail">{contact.name}</span>
                    </div>
                    <div className="userDetail-card mb-2">
                      <h6 className="h6-employeedetail">Relation</h6>
                      <span className="h6-employeedetail">{contact.relation}</span>
                    </div>
                    <div className="userDetail-card mb-2">
                      <h6 className="h6-employeedetail">Phone No</h6>
                      <span className="h6-employeedetail">{contact.phoneNo}</span>
                    </div>
                    <div className="userDetail-card mb-2">
                      <h6 className="h6-employeedetail">Home Phone</h6>
                      <span className="h6-employeedetail">{contact.homePhone}</span>
                    </div>
                    <div className="userDetail-card mb-2">
                      <h6 className="h6-employeedetail">Email</h6>
                      <span className="h6-employeedetail">{contact.email}</span>
                    </div>
                    <div className="userDetail-card mb-2">
                      <h6 className="h6-employeedetail">Address Line 1</h6>
                      <span className="h6-employeedetail">{contact.address_one}</span>
                    </div>
                    <div className="userDetail-card mb-2">
                      <h6 className="h6-employeedetail">Address Line 2</h6>
                      <span className="h6-employeedetail">{contact.address_two}</span>
                    </div>
                    <div className="userDetail-card mb-2">
                      <h6 className="h6-employeedetail">City</h6>
                      <span className="h6-employeedetail">{contact.city}</span>
                    </div>
                    <div className="userDetail-card mb-2">
                      <h6 className="h6-employeedetail">State</h6>
                      <span className="h6-employeedetail">{contact.state}</span>
                    </div>
                    <div className="userDetail-card mb-2">
                      <h6 className="h6-employeedetail">Postal Code</h6>
                      <span className="h6-employeedetail">{contact.postalCode}</span>
                    </div>
                    <Divider />
                    <Divider />
                  </div>
                ))
              ) : (
                <Empty description="No Emergency information available" />
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EmployeeDetail;
