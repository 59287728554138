import { useState, useEffect } from "react";
import axios from "axios"; // Don't forget to import axios
import "./ViewCareer.scss";
import logo from "assets/images/datagonomixLOGO.jpeg";
import { Link } from "react-router-dom";
import { Spin } from "antd";

function ViewCareer() {
  const [dataSource, setDataSource] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/api/HiringProcess/Getposting-Detail`);
      const responseData = response.data;
      const postingDetail = responseData.postingDetail;

      const updatedRows = postingDetail.map((info) => ({
        key: info.postingJobId.toString(),
        postingJobId: info.postingJobId,
        PositionName: info.postingJobTitle,
        Department: info.department,
        JobLocationType: info.jobLocationType,
        JobType: info.jobType,
        Status: info.jobStatus,
      }));

      setDataSource(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data fetching is complete
    }
  }

  return (
    <div className="wrapper d-flex flex-column min-vh-100 bg-light">
      <div className="body flex-grow-1 px-5">
        <div style={{ marginTop: "50px", display: "flex" }}>
          <img src={logo} alt="" height={"60px"} /> &nbsp;&nbsp;&nbsp;
          <h4 className="logo"></h4>
        </div>
        <div className="card" style={{ marginTop: "40px" }}>
          <div className="card-body">
            <ul>
              <li>
                <h2 className="card-title heading-ViewCaree">Current Openings</h2>
                <h5 className="card-text cardtext mb-4">
                  Thanks for checking out our job openings. See something that interests you? Apply
                  here.
                </h5>
              </li>
              {loading ? ( // Conditionally render loader if loading is true
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spin size="large" />
                </div>
              ) : (
                dataSource.map((info) => (
                  <div key={info.key}>
                    <li>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="li-viewcareer">{info.Department}</div>
                          <div className="li-div">
                            <Link className="li-anchor" to={`/jobOpenings/${info.postingJobId}`}>
                              {info.PositionName}
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="city">
                            <div className="cityStart">
                              <div className="cityMain">
                                <p className="cityMain-p">{info.JobLocationType}</p>
                                <p className="city-d">
                                  {info.JobType} ({info.Status})
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <hr className="hr-viewcareer" />
                  </div>
                ))
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewCareer;
