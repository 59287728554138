import React from "react";
import { Button, Table, Spin, Card } from "antd";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import "./OrganizationHierarchy.scss";
import Footer from "layouts/authentication/components/Footer";
import Service from "./Service";

function OrganizationHierarchy() {
  const {
    renderJobTitles,
    columns,
    filteredData,
    departmentClickHandler,
    handleBack,
    employeesData,
    buttonText,
    loading,
    departments,
    showTable,
    showEmployeeTable,
  } = Service();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        className="card-professional"
        title="Organization Hierarchy By Job Titles"
        extra={
          <Button type="default" onClick={handleBack}>
            {buttonText}
          </Button>
        }
      >
        {!showTable && !showEmployeeTable && (
          <div className="row">
            {departments.map((dept, index) => (
              <div className="col-xl-4 col-lg-4" key={index}>
                <div
                  className="card cardpd"
                  style={{ height: "158px", cursor: "pointer" }}
                  onClick={() => departmentClickHandler(dept.name)}
                >
                  <div className="cardpd-statistic-3 p-4">
                    <div className="mb-3">
                      <h6 className="card-title mb-0 selfService-text">{dept.name}</h6>
                    </div>
                    <div className="mb-2">
                      <h4 className="card-title mb-0 selfService-text">
                        <img src={dept.image} alt="" height="55px" />
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {showTable && (
          <div className="job-titles-table">
            <Table
              columns={columns}
              className="table-responsive"
              dataSource={filteredData}
              rowKey="id"
            />
          </div>
        )}

        {showEmployeeTable && (
          <div className="employee-table">
            {loading ? (
              <div className="loading">
                <Spin />
              </div>
            ) : (
              renderJobTitles(employeesData)
            )}
          </div>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default OrganizationHierarchy;
