import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Table, Collapse, Form, Input, Button, Card, Empty } from "antd";
import Footer from "layouts/authentication/components/Footer";
import SalarySlipService from "./SalarySlipService";

const { Panel } = Collapse;
const { Item } = Form;

export default function SalarySlip() {
  const {
    handleUploadDocChange,
    handleChange,
    handleSubmit,
    handleCancel,
    onPanelClick,
    salarySlipColumns,
    handleShowFields,
    showFields,
    items,
    salarySlips,
    formData,
    formErrors,
    loading,
  } = SalarySlipService();
  const processedItems = Array.isArray(items) ? items : [];
  const getNextDay = (date) => {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card title="Salary Slip" className="card-professional">
        <div className="row">
          {/* Check if there are no items */}
          {processedItems.length === 0 ? (
            <Empty description="No Data Available" />
          ) : (
            <Collapse accordion>
              {items.map((info, index) => (
                <Panel
                  key={index}
                  header={`${info.firstName} ${info.lastName} (Employee ID: ${info.employeeId})`}
                  onClick={() => onPanelClick(info.personalId)}
                >
                  {showFields && (
                    <>
                      <Form layout="vertical">
                        <div className="row">
                          <div className="col-md-5">
                            <Form.Item hidden label="Personal Id">
                              <Input
                                type="text"
                                name="personalId"
                                disabled
                                value={formData.personalId}
                                onChange={(e) =>
                                  setFormData({ ...formData, personalId: e.target.value })
                                }
                              />
                            </Form.Item>
                          </div>

                          <div className="col-md-4">
                            <Item hidden label="First Name">
                              <Input
                                type="text"
                                name="firstName"
                                disabled
                                value={formData.firstName}
                                onChange={(e) =>
                                  setFormData({ ...formData, firstName: e.target.value })
                                }
                              />
                            </Item>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5">
                            <Item hidden label="Last Name">
                              <Input
                                type="text"
                                name="lastName"
                                disabled
                                value={formData.lastName}
                                onChange={(e) =>
                                  setFormData({ ...formData, lastName: e.target.value })
                                }
                              />
                            </Item>
                          </div>
                          <div className="col-md-4">
                            <Item hidden label="Email">
                              <Input
                                type="text"
                                name="email"
                                disabled
                                value={formData.email}
                                onChange={(e) =>
                                  setFormData({ ...formData, email: e.target.value })
                                }
                              />
                            </Item>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5">
                            <Item
                              label="From Date"
                              validateStatus={formErrors.fromDate ? "error" : ""}
                              help={formErrors.fromDate ? "Please enter a valid date" : ""}
                            >
                              <Input
                                type="date"
                                name="fromDate"
                                value={formData.fromDate}
                                onChange={(e) => handleChange("fromDate", e.target.value)}
                              />
                            </Item>
                          </div>
                          <div className="col-md-4">
                            <Item
                              label="To Date"
                              validateStatus={
                                formErrors.endDate || formErrors.endDateInvalid ? "error" : ""
                              }
                              help={
                                formErrors.endDate
                                  ? "Please enter a valid date"
                                  : formErrors.endDateInvalid
                                  ? "End date cannot be earlier than From date"
                                  : ""
                              }
                            >
                              <Input
                                type="date"
                                name="endDate"
                                min={formData.fromDate || ""}
                                value={formData.endDate}
                                onChange={(e) => handleChange("endDate", e.target.value)}
                              />
                            </Item>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-9">
                            <Item
                              label="Salary Slip"
                              validateStatus={formErrors.salarySlip ? "error" : ""}
                              help={formErrors.salarySlip ? "Please upload a salary slip file" : ""}
                            >
                              <Input
                                type="file"
                                accept=".pdf,.PDF,.docx,.DOCX,.doc,.DOC"
                                onChange={handleUploadDocChange}
                              />
                            </Item>
                          </div>
                        </div>
                        <Item>
                          <div className="row">
                            <div className="col-md-9">
                              <Button
                                style={{ float: "right" }}
                                onClick={handleSubmit}
                                loading={loading}
                                disabled={loading}
                                type="primary"
                              >
                                {loading ? "Submitting..." : "Submit"}
                              </Button>
                              <Button
                                style={{ float: "right", marginRight: "10px" }}
                                onClick={handleCancel}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </Item>
                      </Form>
                    </>
                  )}
                  {!showFields && (
                    <>
                      <Button type="primary" onClick={handleShowFields}>
                        Upload Salary Slip
                      </Button>
                      <br></br>
                      <Table
                        className="table-responsive"
                        dataSource={salarySlips}
                        columns={salarySlipColumns}
                        rowKey="id"
                        style={{ marginTop: "20px" }}
                      />
                    </>
                  )}
                </Panel>
              ))}
            </Collapse>
          )}
        </div>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}
