import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const TrainingAPI = {
  // Fetch all training records
  fetchTrainings: async () => {
    try {
      const response = await axios.get(`${API_URL}/api/TrainingDevelopement/Get-Training-Detail`);
      return response.data.trainingDetail || [];
    } catch (error) {
      console.error('Error fetching training data:', error);
      throw error;
    }
  },

  // Add a new training record
  addTraining: async (data) => {
    try {
      await axios.post(`${API_URL}/api/TrainingDevelopement/Add-Training`, data);
    } catch (error) {
      console.error('Error adding training:', error);
      throw error;
    }
  },

  // Edit a training record
  editTraining: async (id, data) => {
    try {
      await axios.put(`${API_URL}/api/TrainingDevelopement/Edit-Training/${id}`, data);
    } catch (error) {
      console.error('Error updating training:', error);
      throw error;
    }
  },
};

export default TrainingAPI;
