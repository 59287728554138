import React, { useState, useEffect } from "react";
import "antd/dist/antd";
import "../Onboarding/Onboarding.scss";
import SoftButton from "components/SoftButton";
import EmployeeOnboardingService from "./onboardingService";
import { ToastContainer } from "react-toastify";
import OnboardingTasks from "./CheckLists";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";

const EmployeeOnboarding = () => {
  const { formData, setFormData, handleSubmit, isOnboarded } = EmployeeOnboardingService();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Show modal if already onboarded
    if (isOnboarded) {
      setIsModalVisible(true);
    }
  }, [isOnboarded]);

  const handleSubmitClick = async () => {
    const success = await handleSubmit();
    if (success) {
      setIsModalVisible(true);
    }
  };

  const handleModalClose = () => {
    navigate("/employeedashboard");
  };

  return (
    <>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <ToastContainer />
        <div className="body flex-grow-1 px-3">
          <Modal
            visible={isModalVisible}
            onCancel={handleModalClose}
            // closable={false}
            footer={null}
            centered
          >
            <p style={{ textAlign: "center", fontSize: "18px" }}>
              You have successfully Onboarded this employee.
            </p>
          </Modal>

          {!isOnboarded && (
            <>
              <div className="card">
                <div className="card-header" style={{ position: "relative" }}>
                  <b>New Hire Onboarding Checklist</b>
                </div>
                <div className="card-body">
                  <OnboardingTasks formData={formData} setFormData={setFormData} />
                  <div className="col-md-12">
                    <SoftButton
                      variant="outlined"
                      style={{ float: "inline-end" }}
                      color="info"
                      size="small"
                      onClick={handleSubmitClick}
                    >
                      Submit
                    </SoftButton>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EmployeeOnboarding;
