import React, { useState } from "react";
import { Chart } from "react-google-charts";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Attendence.scss";
import { Table } from "antd";


function Attendance() {
  const [checkIn, setCheckIn] = useState(false);
  const [history, setHistory] = useState(false);
  const [time, setTime] = useState("");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [date, setDate] = useState("");
  const [isDate, setIsDate] = useState(false);

  const handleDateTimeChange = (date) => {
    setTime(date);
  };

  const handleDatePickerOpen = () => {
    setIsDatePickerOpen(true);
  };

  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false);
  };

  const handleDateChange = (date) => {
    setDate(date);
  };

  const handleDateOpen = () => {
    setIsDate(true);
  };

  const handleDateClose = () => {
    setIsDate(false);
  };

  const handleCheckIn = () => {
    setCheckIn(true);
    setHistory(false);
  };
  const hide = () => {
    setCheckIn(false);
  };
  const handleHistory = () => {
    setHistory(true);
    setCheckIn(false);
  };
  const historyhide = () => {
    setHistory(false);
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "Check In Time",
      dataIndex: "CheckInTime",
      key: "CheckInTime",
    },
    {
      title: "Check Out Time",
      dataIndex: "CheckOutTime",
      key: "CheckOutTime",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
    },
  ];
  const data = [
    ["Task", "Hours per Day"],
    ["Present", 50],
    ["Absent", 40],
    ["Late Arrival", 17],
  ];

  // Define custom colors for the chart
  const colors = ["green", "#FF5733", "#faae20"];

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card mb-3">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Attendance</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 " style={{ height: "300px" }}>
                  <Chart
                    chartType="PieChart"
                    data={data}
                    options={{
                      pieHole: 0.4,
                      colors: colors, // Set custom colors
                    }}
                    width={"100%"}
                    height={"100%"}
                  />
                </div>

                <div className="btncheckin">
                  <SoftButton variant="outlined" color="info" size="small" onClick={handleCheckIn}>
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Check In
                  </SoftButton>
                </div>
                <div className="history">
                  <SoftButton variant="outlined" color="info" size="small" onClick={handleHistory}>
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;History
                  </SoftButton>
                </div>
              </div>
            </div>
          </div>
          {checkIn && (
            <div className="card mb-3">
              <div className="card-header" style={{ position: "relative" }}>
                <h5>Check In</h5>
                <button className="btn btn-outline-danger btncancel" onClick={hide}>
                  X
                </button>
              </div>
              <div className="card-body">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Time *
                    </label>
                    <br></br>
                    <DatePicker
                      selected={time}
                      onChange={handleDateTimeChange}
                      onCalendarOpen={handleDatePickerOpen}
                      onCalendarClose={handleDatePickerClose}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="h:mm aa"
                      placeholderText="Select time"
                      className="form-control custom-datepicker-attendence"
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Date *
                    </label>
                    <br></br>
                    <DatePicker
                      selected={date}
                      onChange={handleDateChange}
                      onCalendarOpen={handleDateOpen}
                      onCalendarClose={handleDateClose}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="Select date"
                      className="form-control custom-datepicker-attendence"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <SoftButton
                    style={{ float: "inline-end" }}
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={handleCheckIn}
                  >
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Check In
                  </SoftButton>
                </div>
              </div>
            </div>
          )}
          {history && (
            <div className="card mb-3">
              <div className="card-header" style={{ position: "relative" }}>
                <h5>History</h5>
                <button className="btn btn-outline-danger btncancel" onClick={historyhide}>
                  X
                </button>
              </div>
              <div className="card-body">
                <Table
                  className="table-responsive"
                  bordered
                  // dataSource={datasource}
                  columns={columns}
                  pagination={{ pageSize: 10 }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Attendance;
