import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import payrollimg from "assets/images/payroll.png";
import attendance from "assets/images/attendance.png";
import hiringimg from "assets/images/hiringimg.png";
import salary from "assets/images/salary.jpg";
import skillsReport from "assets/images/skillsReport.png";
import orgHierarchy from "assets/images/orgHierarchy.png";
import traininganddevelopement from "assets/images/traininganddevelopement.png";
import leave from "assets/images/leave.png";
import { useNavigate } from "react-router-dom";
import Footer from "layouts/authentication/components/Footer";
import { Card, Row, Col } from "antd";

const cardData = [
  { img: payrollimg, text: "Expense Management", onClick: "/expenseManagement", color: "#64748b" },
  { img: attendance, text: "Attendance", onClick: "/reportsAttendance", color: "#84cc16" },
  { img: leave, text: "Time Off/Leaves", onClick: "/timeOff", color: "#16a34a" },
  { img: payrollimg, text: "Salary Slip", onClick: "/salarySlip", color: "#2dd4bf" },
  { img: hiringimg, text: "Hiring", onClick: "/recruitment-Integration", color: "#06b6d4" },
  {
    img: traininganddevelopement,
    text: "Training and Development",
    onClick: "/enrolled-Employee-for-Training",
    color: "#0369a1",
  },
  { img: skillsReport, text: "Skills Report", onClick: "/skillsReport", color: "#4f46e5" },
  { img: salary, text: "Salary Analysis", onClick: "/salaryAnalysis", color: "#8b5cf6" },
  {
    img: orgHierarchy,
    text: "Organization Hierarchy",
    onClick: "/organizationHierarchy",
    color: "#db2777",
  },
];

function Report() {
  const navigate = useNavigate();
  const handleCardClick = (path) => navigate(path);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card title="Self Services" className="card-professional">
        <Row gutter={[16, 16]} justify="center">
          {cardData.map(({ img, text, onClick, color }, index) => (
            <Col key={index} xs={24} sm={12} md={8} lg={6}>
              <Card
                className="circular-box"
                onClick={() => handleCardClick(onClick)}
                style={{ backgroundColor: color }}
              >
                <div className="ribbon-content">
                  <img src={img} alt={text} style={{ height: "65px" }} />
                  <div className="card-content">
                    <p style={{ fontSize: "medium" }}>{text}</p>
                  </div>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default Report;
