import { useState } from "react";

function TrainingService() {
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    complaintName: "",
    complaintAgainst: "",
    departments: "",
    detailofIncident: "",
    file:"N/A"
  });

  const [formDataError, setFormDataError] = useState({
    firstNameError: "",
    lastNameError: "",
    // emailError: "",
    phoneNoError: "",
    complaintNameError: "",
    complaintAgainstError: "",
    departmentError: "",
    detailofIncidentError: "",
    // Status:""
  });

  const clearFields = () => {
    // Reset the form data to initial values
    setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNo: "",
        complaintName: "",
        complaintAgainst: "",
        // complaintDate: "",
        detailofIncident: "",
        Status:""
    });
  };

  function validateValidation(formData, setFormDataError) {
    let hasError = false;

    // Check each field for emptiness or invalidity
    Object.keys(formData).forEach((fieldName) => {
      if (
        (typeof formData[fieldName] === "string" &&
          (!formData[fieldName] || !formData[fieldName].trim())) || // For string fields
        (typeof formData[fieldName] === "number" &&
          (isNaN(formData[fieldName]) || formData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      } else {
        // Clear the error message if the field is not empty
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: "", // Clear the error message
        }));
      }
    });

    // Check if any error occurred
    if (hasError) {
      return false;
    }

    // Check if all fields are filled out
    const isAllFieldsFilled = Object.values(formData).every(
      (value) => value !== null && value !== undefined && value !== ""
    );

    if (!isAllFieldsFilled) {
      // If any field is empty, display an error message
      setFormDataError((prevState) => ({
        ...prevState,
        genericError: "Please fill out all fields.",
      }));
      return false;
    }

    return true;
  }

  return {
    formData,
    setFormData,
    formDataError,
    setFormDataError,
    clearFields,
    validateValidation,
  };
}

export default TrainingService;
