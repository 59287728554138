import React, { useState, useRef, useEffect } from "react";
import { Delete } from "@mui/icons-material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { message } from "antd";

export default function TimeOffService() {
  const [showDetail, setShowDetail] = useState(true);
  const [showAddPolicy, setShowPolicy] = useState(true);
  const [showNewEmployee, setShowNewEmployee] = useState(false);
  const [leavePolicies, setLeavePolicies] = useState([]);
  const [policyDetails, setPolicyDetails] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [datasourceDetail, setDatasourceDetail] = useState([]);
  const [enrolledEmployees, setEnrolledEmployees] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchLeavePolicies();
  }, []);

  const fetchLeavePolicies = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/LeaveAddPolicy/Get-Leave-Policy`);
      setLeavePolicies(response.data);
      // setLeavePolicies(response.data.map((policy) => policy.policyName));
    } catch (error) {
      console.error("Error fetching leave policies:", error);
    }
  };

  useEffect(() => {
    fetchPolicyDetails();
  }, [selectedPolicy]);

  const fetchPolicyDetails = async () => {
    if (selectedPolicy) {
      try {
        const response = await axios.get(
          `${apiUrl}/api/LeaveAddPolicy/Get-Leave-Policy/${selectedPolicy.policyName}`
        );
        setPolicyDetails(response.data);
        // Fetch enrolled employees for the selected policy
        await fetchEnrolledEmployees(selectedPolicy.policyName);
        console.warn("selected data:", selectedPolicy);
      } catch (error) {
        console.error(`Error fetching details for policy '${selectedPolicy}':`, error);
        toast.error(`Error fetching details for policy '${selectedPolicy}'`);
      }
    }
  };

  useEffect(() => {
    async function fetchEmployees() {
      try {
        const response = await axios.get(`${apiUrl}/api/PersonalInformation/Get-All`);
        setEmployees(response.data.personalInfo);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    }

    fetchEmployees();
  }, []);

  const handleDetail = async (policyName) => {
    setSelectedPolicy(policyName);
    setShowDetail(false);
    // Fetch enrolled employees for the selected policy
    await fetchEnrolledEmployees(policyName);
  };

  const handleAddPolicy = () => {
    setShowPolicy(false);
    setShowDetail(true);
  };

  const handlePolicySubmissionSuccess = () => {
    setShowPolicy(true);
    setShowDetail(true);
    fetchLeavePolicies();
  };
  const addNewEmployee = () => {
    setShowNewEmployee(true);
  };

  const anchorRef = useRef(null);

  const handleDownload = () => {
    anchorRef.current.click();
  };

  const columnsDetail = [
    {
      title: "Employee ID",
      dataIndex: "EmployeeID",
      key: "EmployeeID",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Enrollment Date",
      dataIndex: "EnrollmentDate",
      key: "EnrollmentDate",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
    },
  ];

  function formatDateTime(dateTimeString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateTimeString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = date.getMinutes();
    return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
  }

  const onChange = (selectedValues) => {
    const selectedEmployeeObjects = selectedValues.map((value) => {
      return JSON.parse(value); // Parse JSON string to object
    });
    setSelectedEmployees(selectedEmployeeObjects);
  };

  const handleFormSubmit = async (policy) => {
    try {
      if (selectedEmployees.length === 0) {
        toast.error("Please select employees before submitting.");
        return; // Exit function early if no employees are selected
      }
      const employeesToEnroll = selectedEmployees.map((employee) => ({
        name: `${employee.firstName} ${employee.lastName}`,
        email: employee.email,
        enrolledDate: new Date().toISOString(),
        softDeleted: false,
        deletedAt: null,
        leavepolicyId: policy.leavepolicyId,
      }));

      await axios.post(
        `${apiUrl}/api/LeaveAddPolicy/Add-Enrolled-Leave-Employees`,
        employeesToEnroll
      );
      await fetchEnrolledEmployees(policy.policyName);
      fetchPolicyDetails();
      toast.success("Enrolled successfully");
    } catch (error) {
      console.error("Error adding enrolled leave employees:", error);
      toast.error("Oops Failed");
    }
  };

  async function fetchEnrolledEmployees(policyName) {
    try {
      const response = await axios.get(
        `${apiUrl}/api/LeaveAddPolicy/Get-Enrolled-Employee/${policyName}`
      );
      const formattedData = response.data.map((employee) => ({
        EmployeeID: employee.enrolledEmpoyeeId,
        Name: employee.name,
        EnrollmentDate: new Date(employee.enrolledDate).toLocaleString(),
        Action: (
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Delete
              className="hiOutlined"
              onClick={() => deleteEnrolledLeaveEmployee(employee.enrolledEmpoyeeId)}
            />
          </span>
        ),
      }));
      setDatasourceDetail(formattedData);
      setEnrolledEmployees(response.data);
    } catch (error) {
      console.error("Error fetching enrolled employees:", error);
    }
  }

  async function deleteEnrolledLeaveEmployee(id) {
    try {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this enrolled leave employee?"
      );
      if (!confirmDelete) return;
      const response = await fetch(
        `${apiUrl}/api/LeaveAddPolicy/Delete-Enrolled-Leave-Employee/${id}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        message.success("Enrolled leave employee deleted successfully.");
        // Remove the deleted item from the UI
        setDatasourceDetail((prevData) =>
          prevData.filter((employee) => employee.EmployeeID !== id)
        );
        await fetchEnrolledEmployees(selectedPolicy.policyName);
      } else {
        const errorMessage = await response.text();
        message.error(`Failed to delete enrolled leave employee: ${errorMessage}`);
      }
    } catch (error) {
      message.error(`An error occurred: ${error.message}`);
    }
  }
  return {
    showDetail,
    showAddPolicy,
    showNewEmployee,
    leavePolicies,
    policyDetails,
    employees,
    datasourceDetail,
    handleDetail,
    handleAddPolicy,
    handlePolicySubmissionSuccess,
    addNewEmployee,
    handleDownload,
    columnsDetail,
    formatDateTime,
    onChange,
    handleFormSubmit,
    anchorRef,
    fetchLeavePolicies,
    enrolledEmployees,
  };
}
