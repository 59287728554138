// src/utils/pdfGenerator.js
import jsPDF from "jspdf";
import "jspdf-autotable";

const generatePDFReport = (employees, selectedDepartment) => {
    const filteredData = employees.filter(
      (employee) => selectedDepartment === "All Departments" || employee.team === selectedDepartment
    );

    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("Employee Performance Report", 14, 22);

    const lineY = 28;
    const lineWidth = 0.5;
    const lineColor = [192, 192, 192];
    doc.setLineWidth(lineWidth);
    doc.setDrawColor(...lineColor);
    doc.line(14, lineY, 200, lineY);

    const marginTop = 3;
    const marginTopEOM = 2;
    doc.setFontSize(12);
    doc.text("Employee: All - All employees selected", 14, 32 + marginTop);

    // Get the current date
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();

    doc.text(`Report Date: ${formattedDate}`, 14, 42 + marginTopEOM);
    doc.text("Exceptions: No Deletions", 14, 52);
    doc.text(`Department: ${selectedDepartment}`, 14, 59);

    const headers = [
      [
        "Employee ID",
        "Employee Name",
        "Email",
        "Manager",
        "Evaluated By",
        "Department",
        "Status",
        "Performance",
      ],
    ];
    const data = filteredData.map((employee) => {
      const employeeId = employee.personalInformation.personalId;
      const employeeName = `${employee.personalInformation.firstName} ${employee.personalInformation.lastName}`;
      const employeeEmail = employee.personalInformation.email;
      const manager = employee.manager;
      const evaluated = employee.manager;
      const department = employee.team || "N/A";
      const status =
        employee.personalInformation.status === 0
          ? "Ask to initiate"
          : employee.personalInformation.status === 1
          ? "In process"
          : employee.personalInformation.status === 2
          ? "Completed"
          : "Unknown";
      const performance =
        employee.personalInformation.rating !== null
          ? employee.personalInformation.rating
          : "N/A";

      return [
        employeeId,
        employeeName,
        employeeEmail,
        manager,
        evaluated,
        department,
        status,
        performance,
      ];
    });

    doc.autoTable({
      startY: 62,
      head: headers,
      body: data,
      headerStyles: {
        fillColor: [192, 192, 192],
        fontSize: 8,
      },
      styles: {
        fontSize: 8,
      },
    });

    doc.save(`employee_performance_report_${selectedDepartment}.pdf`);
  };

export default generatePDFReport;
