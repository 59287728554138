import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

export default function ProtectedRoute({ element }) {
  const navigate = useNavigate();
  useEffect(() => {
    const login = localStorage.getItem("loginEmail");
    if (!login) {
      navigate("/emplogin");
    }
  }, [navigate]);

  return element;
}

ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired,
};
