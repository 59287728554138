import React from "react";
import "./Job.scss";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { updatePerformanceInfo } from "../../layouts/Services/Actions/actions";
import PerformanceFactorService from "../EmployeeApiService/JobInitiateService/PerformanceFactorService";
import { Button, Card } from "antd";

export default function PerformanceFactor({ onNext, selectedEmail }) {
  const {
    performanceFormData,
    performanceFormDataError,
    setPerformFormdataError,
    validatePerformanceValidation,
    handlePerformanceChange,
  } = PerformanceFactorService({ onNext, selectedEmail });
  const { initiateData } = useSelector((state) => state);

  const dispatch = useDispatch();
  const handleNext = (e) => {
    e.preventDefault();
    const isValid = validatePerformanceValidation(performanceFormData, setPerformFormdataError);
    if (isValid) {
      dispatch(updatePerformanceInfo(performanceFormData));
      console.warn("Initiate Data : ", initiateData);
      onNext();
    }
  };

  return (
    <Card title="Performance factor" className="card-professional">
      <div className="row">
        <div className="col-md-9 mb-4">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1" className="lab">
              Job Knowledge/Technical Skills *<p style={{ fontSize: "small" }}>As Evidenced By</p>
            </label>
            <input
              type="text"
              className={`form-control ${
                performanceFormDataError.jobKnowledgeError ? "is-invalid" : ""
              }`}
              name="jobKnowledge"
              value={performanceFormData.jobKnowledge}
              onChange={handlePerformanceChange}
              placeholder="Enter evidence here"
            />
            {performanceFormDataError.jobKnowledgeError && (
              <div className="invalid-feedback invalid-message">
                {performanceFormDataError.jobKnowledgeError}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-9 mb-4">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1" className="lab">
              Administration *<p style={{ fontSize: "small" }}>As Evidenced By</p>
            </label>
            <input
              type="text"
              placeholder="Enter evidence here"
              className={`form-control ${
                performanceFormDataError.administrationError ? "is-invalid" : ""
              }`}
              name="administration"
              value={performanceFormData.administration}
              onChange={handlePerformanceChange}
            />
            {performanceFormDataError.administrationError && (
              <div className="invalid-feedback invalid-message">
                {performanceFormDataError.administrationError}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-9 mb-4">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1" className="lab">
              Decision-Making *<p style={{ fontSize: "small" }}>As Evidenced By</p>
            </label>
            <input
              type="text"
              placeholder="Enter evidence here"
              className={`form-control ${
                performanceFormDataError.decision_MakingError ? "is-invalid" : ""
              }`}
              name="decision_Making"
              value={performanceFormData.decision_Making}
              onChange={handlePerformanceChange}
            />
            {performanceFormDataError.decision_MakingError && (
              <div className="invalid-feedback invalid-message">
                {performanceFormDataError.decision_MakingError}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-9 mb-4">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1" className="lab">
              Communication *<p style={{ fontSize: "small" }}>As Evidenced By</p>
            </label>
            <input
              type="text"
              placeholder="Enter evidence here"
              className={`form-control ${
                performanceFormDataError.communicationError ? "is-invalid" : ""
              }`}
              name="communication"
              value={performanceFormData.communication}
              onChange={handlePerformanceChange}
            />
            {performanceFormDataError.communicationError && (
              <div className="invalid-feedback invalid-message">
                {performanceFormDataError.communicationError}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-9 mb-4">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1" className="lab">
              Initiative/Responsibility *<p style={{ fontSize: "small" }}>As Evidenced By</p>
            </label>
            <input
              type="text"
              placeholder="Enter evidence here"
              className={`form-control ${
                performanceFormDataError.initiativeError ? "is-invalid" : ""
              }`}
              name="initiative"
              value={performanceFormData.initiative}
              onChange={handlePerformanceChange}
            />
            {performanceFormDataError.initiativeError && (
              <div className="invalid-feedback invalid-message">
                {performanceFormDataError.initiativeError}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-9 mb-4">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1" className="lab">
              Work Relationships *<p style={{ fontSize: "small" }}>As Evidenced By</p>
            </label>
            <input
              type="text"
              placeholder="Enter evidence here"
              className={`form-control ${
                performanceFormDataError.work_RelationshipsError ? "is-invalid" : ""
              }`}
              name="work_Relationships"
              value={performanceFormData.work_Relationships}
              onChange={handlePerformanceChange}
            />
            {performanceFormDataError.work_RelationshipsError && (
              <div className="invalid-feedback invalid-message">
                {performanceFormDataError.work_RelationshipsError}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-9 mb-4">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1" className="lab">
              Flexibility *<p style={{ fontSize: "small" }}>As Evidenced By</p>
            </label>
            <input
              type="text"
              placeholder="Enter evidence here"
              className={`form-control ${
                performanceFormDataError.flexibilityError ? "is-invalid" : ""
              }`}
              name="flexibility"
              value={performanceFormData.flexibility}
              onChange={handlePerformanceChange}
            />
            {performanceFormDataError.flexibilityError && (
              <div className="invalid-feedback invalid-message">
                {performanceFormDataError.flexibilityError}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-9 mb-4">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1" className="lab">
              Overall Performance Factor Rating *
            </label>
            <input
              type="number"
              placeholder="Enter Overall Performance Factor Rating here"
              className={`form-control ${
                performanceFormDataError.overall_PerformanceError ? "is-invalid" : ""
              }`}
              name="overall_Performance"
              value={performanceFormData.overall_Performance}
              onChange={handlePerformanceChange}
            />
            {performanceFormDataError.overall_PerformanceError && (
              <div className="invalid-feedback invalid-message">
                {performanceFormDataError.overall_PerformanceError}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-9">
          <Button type="primary" style={{ float: "inline-end" }} onClick={handleNext}>
            Next
          </Button>
        </div>
      </div>
    </Card>
  );
}
PerformanceFactor.propTypes = {
  onNext: PropTypes.func.isRequired,
  selectedEmail: PropTypes.object.isRequired,
};
