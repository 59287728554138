// const initialState = {
//     personal: [],
//     job: [],
//     emergency: [],
//     benefits: [],
//     documents: [],
//     lifeInsuranceName: [],
//   };

//   const formDataReducer = (state = initialState, action) => {
//     switch (action.type) {
//       case 'UPDATE_PERSONAL_INFO':
//         return {
//           ...state,
//           personal: action.payload,
//         };
//       case 'UPDATE_JOB_INFO':
//         return {
//           ...state,
//           job: action.payload,
//         };
//         case 'UPDATE_EMERGENCY_INFO':
//         return {
//           ...state,
//           emergency: action.payload,
//         };
//         case 'UPDATE_Benefits_INFO':
//         return {
//           ...state,
//           benefits: action.payload,
//         };
//         case 'UPDATE_LIFE_INSURANCE_INFO':
//           return {
//             ...state,
//             lifeInsuranceName: action.payload,
//           };
//         case 'UPDATE_DOCUMENTS_INFO':
//         return {
//           ...state,
//           documents: action.payload,
//         };
//       // Add cases for other parts of the form data
//       default:
//         return state;
//     }
//   };

//   export default formDataReducer;

const initialState = {
  personal: {},
  job: {},
  hardwareAssets: {},
  softwareAssets: {},
  emergency: {},
  benefits: {},
  documents: {},
  lifeInsuranceName: {},
  initiateData: {},
  performanceData: {},
  dynamicOrganizationData: {},
  enrolledTrainingEmployee: {},
};

const formDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_PERSONAL_INFO":
      return {
        ...state,
        personal: {
          ...state.personal,
          ...action.payload.formData,
          ...action.payload.employeeData, // Merge formData and employeeData
        },
      };
    case "UPDATE_JOB_INFO":
      return {
        ...state,
        job: action.payload,
      };
    case "UPDATE_HARDWARE_INFO":
      return {
        ...state,
        hardwareAssets: action.payload,
      };
      case "UPDATE_SOFTWARE_INFO":
      return {
        ...state,
        softwareAssets: action.payload,
      };
    case "UPDATE_EMERGENCY_INFO":
      return {
        ...state,
        emergency: action.payload,
      };
    case "UPDATE_BENEFITS_INFO":
      return {
        ...state,
        benefits: action.payload,
      };
    case "UPDATE_LIFE_INSURANCE_INFO":
      return {
        ...state,
        lifeInsuranceName: action.payload,
      };
    case "UPDATE_DOCUMENT_INFO":
      return {
        ...state,
        documents: action.payload,
      };
      case "UPDATE_INITIATE_INFO":
      return {
        ...state,
        initiateData: action.payload,
      };
    case "UPDATE_PERFORMANCE_INFO":
      return {
        ...state,
        performanceData: action.payload,
      };
    case "UPDATE_DYNAMIC_INFO":
      return {
        ...state,
        dynamicOrganizationData: action.payload,
      };
      case "UPDATE_ENROLLED_INFO":
      return {
        ...state,
        enrolledTrainingEmployee: action.payload,
      };
    default:
      return state;
  }
};

export default formDataReducer;
