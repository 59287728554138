import React, { useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DatePicker from "react-datepicker";
import "./RequesttimeOff.scss";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import SoftButton from "components/SoftButton";
import Footer from "layouts/authentication/components/Footer";
function RequestTimeOff() {
  const navigate = useNavigate();
  const [dateTime, setDateTime] = useState("");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleDateTimeChange = (date) => {
    setDateTime(date);
  };

  const handleDatePickerOpen = () => {
    setIsDatePickerOpen(true);
  };

  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false);
  };

  const dashboard = () => {
    navigate("/dashboard");
  };
  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Request Time Off</h5>
              <button
                className="btn btn-outline-dark"
                style={{ float: "inline-end", marginTop: "-35px" }}
                onClick={dashboard}
              >
                Back
              </button>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      From *
                    </label>
                    <br></br>
                    <DatePicker
                      selected={dateTime}
                      onChange={handleDateTimeChange}
                      onCalendarOpen={handleDatePickerOpen}
                      onCalendarClose={handleDatePickerClose}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      placeholderText="Select date and time"
                      className="form-control custom-datepicker-college"
                      // style={{ borderColor }}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      To *
                    </label>
                    <br></br>
                    <DatePicker
                      selected={dateTime}
                      onChange={handleDateTimeChange}
                      onCalendarOpen={handleDatePickerOpen}
                      onCalendarClose={handleDatePickerClose}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      placeholderText="Select date and time"
                      className="form-control custom-datepicker-college"
                      // style={{ borderColor }}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Time Off Category *
                    </label>
                    <select className="form-select" aria-label="Default select example">
                      <option value=""> Please Select </option>
                      <option value="Sick Leaves"> Sick Leaves </option>
                      <option value="Emergency Leaves "> Emergency Leaves </option>
                      <option value="Emergency Leaves "> Annual Leaves </option>
                      <option value="Casual Leaves "> Casual Leaves </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInput"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Amount Days *
                    </label>
                    <input type="text" className="form-control" disabled />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Notes
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Type here..."
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <SoftButton
                    variant="outlined"
                    style={{ float: "inline-end" }}
                    color="info"
                    size="small"
                  >
                    Send Request
                  </SoftButton>
                  <SoftButton
                    style={{ float: "inline-end", marginRight: "10px" }}
                    variant="outlined"
                    color="dark"
                    size="small"
                  >
                    Cancel
                  </SoftButton>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default RequestTimeOff;
