import React, { useState, useEffect } from "react";
import "antd/dist/antd";
import "./Onboarding.scss";
import check from "assets/images/checklist.jpg";
import axios from "axios";
import { Empty } from "antd";

const ManagerOnboarding = () => {
  const [managerOnboarding, setManagerOnboarding] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    showManagerOnboarding();
  }, []);

  const showManagerOnboarding = () => {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        axios
          .get(`${apiUrl}/api/ManagerOnboarding/Get-ManagerOnboarding-By-Email/${loginEmail}`)
          .then((response) => {
            const responseData = response.data;
            setManagerOnboarding(responseData);
          })
          .catch((error) => {
            console.error("Error fetching manager onboarding data:", error);
          });
      }
    } catch (error) {
      console.error("Error fetching manager onboarding data:", error);
    }
  };

  const renderManagerCheckbox = (label, field) => {
    const isChecked = managerOnboarding ? managerOnboarding[field] : false; // Check if field is true
    return (
      <div className="form-check OnboardCheckbox" key={field}>
        <input
          disabled
          className="form-check-input"
          type="checkbox"
          id={`Check${field}`}
          checked={isChecked}
        />
        <label className="form-check-label" htmlFor={`Check${field}`}>
          {label}
        </label>
      </div>
    );
  };

  return (
    <>
      {managerOnboarding ? (
        <>
          <label
            className="mb-3"
            style={{ textAlign: "left", fontSize: "large", color: "#3dc00f" }}
          >
            <img src={check} height={"35px"} style={{ marginLeft: "-8px" }} />
            Tasks for Human Resources
          </label>
          {renderManagerCheckbox(
            "Send out a new hire welcome packet that includes the forms and documents new hires must complete.",
            "welcomePacket" // Update to match the API field name
          )}
          {renderManagerCheckbox(
            "Document the new hire's role and compensation information in your HRIS system or records.",
            "roleAndCompensationInfo" // Update to match the API field name
          )}
          {renderManagerCheckbox("Add the new employee to the payroll.", "payrollSetup")}
          {renderManagerCheckbox(
            "Share essential company policies (e.g., hours of operation, dress code, parking, etc.)",
            "companyPolicies" // Update to match the API field name
          )}
          {renderManagerCheckbox(
            "Schedule intro meetings to go over paperwork, including benefits, direct deposit, and company policies.",
            "paperworkIntroMeetings" // Update to match the API field name
          )}
          {renderManagerCheckbox(
            "If your organization conducts periodic reviews (such as after a 90-day probationary period), schedule these and other important milestones.",
            "periodicReviewsScheduling" // Update to match the API field name
          )}
          {renderManagerCheckbox(
            "Ask new hires to complete a background check (if applicable).",
            "backgroundCheck" // Update to match the API field name
          )}
          {renderManagerCheckbox(
            "Send the new hire to complete a drug screen (if applicable).",
            "drugScreen" // Update to match the API field name
          )}
          <label
            className="mb-3"
            style={{ textAlign: "left", fontSize: "large", color: "#3dc00f" }}
          >
            <img src={check} height={"35px"} style={{ marginLeft: "-8px" }} />
            IT Onboarding Checklist
          </label>
          {renderManagerCheckbox(
            "Create an email address for the new hire.",
            "emailCreation" // Update to match the API field name
          )}
          {renderManagerCheckbox(
            "Set up account permissions for any platforms or software the role requires.",
            "accountPermissionsSetup" // Update to match the API field name
          )}
          {renderManagerCheckbox(
            "Prepare a computer and any phone equipment or tech accessories a new hire may need.",
            "equipmentSetup" // Update to match the API field name
          )}
        </>
      ) : (
        <Empty description="Manager onboarding information is not available" />
      )}
    </>
  );
};

export default ManagerOnboarding;
