import React, { useEffect, useState } from "react";
import { message, Space } from "antd";
import { FileOpenOutlined } from "@mui/icons-material";
import moment from "moment";
import {
  fetchPersonalInformation,
  fetchDepartmentByEmployeeId,
  uploadSalarySlip,
  fetchSalarySlipsByPersonalId,
} from "./SalarySlipApi";

function SalarySlipService() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [items, setItems] = useState([]); // Holds the list of personal information
  const [salarySlips, setSalarySlips] = useState([]); // Holds the list of salary slips
  const [showFields, setShowFields] = useState(false); // Controls visibility of form fields
  const [loading, setLoading] = useState(false); // Indicates loading state for API calls
  const [formData, setFormData] = useState(initialFormData()); // Holds form data
  const [formErrors, setFormErrors] = useState(initialFormErrors()); // Holds form validation errors
  const [formDirty, setFormDirty] = useState(initialFormDirty()); // Tracks if form fields are dirty

  // Initialize form data with default values
  function initialFormData() {
    return {
      email: "",
      firstName: "",
      lastName: "",
      personalId: "",
      department: "",
      fromDate: "",
      endDate: "",
      salarySlip: null,
    };
  }

  // Initialize form error state with default values
  function initialFormErrors() {
    return {
      fromDate: false,
      endDate: false,
      salarySlip: false,
      endDateInvalid: false,
    };
  }

  // Initialize form dirty state with default values
  function initialFormDirty() {
    return {
      fromDate: false,
      endDate: false,
      salarySlip: false,
    };
  }

  // Load personal information on component mount
  useEffect(() => {
    loadPersonalInformation();
  }, []);

  // Fetch personal information and enhance it with department details
  const loadPersonalInformation = async () => {
    try {
      const personalInfo = await fetchPersonalInformation();
      const enhancedPersonalInfo = await Promise.all(
        personalInfo.map(async (info) => {
          const department = await fetchDepartmentByEmployeeId(info.employeeId);
          return { ...info, department }; // Return enhanced info object
        })
      );

      setItems(enhancedPersonalInfo); // Update state with fetched items

      // Set initial form data with the first employee's information
      if (enhancedPersonalInfo.length > 0) {
        const firstInfo = enhancedPersonalInfo[0];
        setFormData({
          email: firstInfo.email,
          firstName: firstInfo.firstName,
          lastName: firstInfo.lastName,
          personalId: firstInfo.personalId.toString(),
          department: firstInfo.department,
          fromDate: "",
          endDate: "",
          salarySlip: null,
        });
      }
    } catch (error) {
      console.error("Error loading personal information:", error);
    }
  };

  // Handle file upload change event
  const handleUploadDocChange = (e) => {
    const file = e.target.files[0]; // Get the uploaded file
    setFormData({ ...formData, salarySlip: file }); // Update form data with file
    setFormErrors({ ...formErrors, salarySlip: !file }); // Set error if no file selected
  };

  // Handle changes in form fields
  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value })); // Update form data
    setFormDirty((prev) => ({ ...prev, [key]: true })); // Mark field as dirty
    validateFormFields(key, value); // Validate the changed field

    // If the 'fromDate' field is changed
    if (key === "fromDate") {
      // If 'fromDate' is cleared, reset 'endDate'
      if (!value) {
        setFormData((prev) => ({ ...prev, endDate: "" }));
      } else {
        // If 'endDate' is earlier than the new 'fromDate', clear 'endDate'
        if (new Date(formData.endDate) < new Date(value)) {
          setFormData((prev) => ({ ...prev, endDate: "" }));
        }
      }
    }
  };

  // Validate form fields based on the field key and value
  const validateFormFields = (key, value) => {
    let errors = { ...formErrors }; // Clone current errors
    if (key === "fromDate") {
      errors.fromDate = !isValidDate(value); // Check fromDate validity
      if (isValidDate(value) && isValidDate(formData.endDate)) {
        errors.endDateInvalid = new Date(value) > new Date(formData.endDate); // Check if fromDate is after endDate
      }
    } else if (key === "endDate") {
      errors.endDate = !isValidDate(value); // Check endDate validity
      if (isValidDate(value) && isValidDate(formData.fromDate)) {
        errors.endDateInvalid = new Date(formData.fromDate) > new Date(value); // Check if endDate is before fromDate
      }
    }
    setFormErrors(errors); // Update errors state
  };

  // Check if a date string is valid
  const isValidDate = (dateString) => {
    return moment(dateString, "YYYY-MM-DD", true).isValid(); // Validate date format
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!isFormValid()) return; // Validate the form before submission

    const isDuplicate = salarySlips.some(
      (slip) => slip.fromDate === formData.fromDate && slip.endDate === formData.endDate
    );

    if (isDuplicate) {
      message.error("A salary slip with the same from date and end date already exists."); // Show error for duplicate
      return;
    }

    try {
      setLoading(true); // Set loading state
      await uploadSalarySlip(formData); // Upload salary slip
      message.success("Salary slip uploaded successfully"); // Show success message
      setShowFields(false); // Hide fields after submission
      await fetchSlips(formData.personalId); // Fetch updated slips
    } catch (error) {
      console.error("Error uploading salary slip:", error);
      message.error("Failed to upload salary slip."); // Show error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  // Check if the form is valid
  const isFormValid = () => {
    const errors = {
      fromDate: !isValidDate(formData.fromDate),
      endDate: !isValidDate(formData.endDate),
      salarySlip: !formData.salarySlip,
      endDateInvalid: new Date(formData.fromDate) > new Date(formData.endDate),
    };
    setFormErrors(errors); // Update form errors state

    const hasError = Object.values(errors).some((error) => error); // Check if any error exists
    if (hasError) {
      message.error("Please fill out all required fields correctly."); // Show error message
    }
    return !hasError; // Return true if no errors
  };

  // Handle cancel action to hide fields
  const handleCancel = () => {
    setShowFields(false); // Hide fields
  };

  // Handle panel click to set selected employee's information
  const onPanelClick = async (personalId) => {
    const selectedEmployee = items.find((item) => item.personalId === personalId);
    if (selectedEmployee) {
      setFormData({
        ...formData,
        email: selectedEmployee.email,
        firstName: selectedEmployee.firstName,
        lastName: selectedEmployee.lastName,
        personalId: selectedEmployee.personalId.toString(),
        department: selectedEmployee.department,
      });
      await fetchSlips(selectedEmployee.personalId); // Fetch salary slips for the selected employee
    }
  };

  // Fetch salary slips by personal ID
  const fetchSlips = async (personalId) => {
    try {
      const slips = await fetchSalarySlipsByPersonalId(personalId); // Fetch slips from API
      // Set salarySlips to the result array from the API response
      setSalarySlips(Array.isArray(slips.result) ? slips.result : []);
    } catch (error) {
      console.error("Error fetching salary slips:", error);
      setSalarySlips([]); // Reset to empty array on error
    }
  };

  // Handle PDF preview for the salary slip
  const handlePdfPreview = (fileUrl) => {
    window.open(fileUrl, "_blank"); // Open PDF in a new tab
  };

  // Show fields for salary slip entry
  const handleShowFields = () => {
    setShowFields(true); // Show fields
  };

  // Define columns for the salary slip table
  const salarySlipColumns = [
    { title: "Department", dataIndex: "department", key: "department" },
    {
      title: "Date Range",
      key: "dateRange",
      render: (_, record) => (
        <span>
          {new Date(record.fromDate).toLocaleDateString()} -{" "}
          {new Date(record.endDate).toLocaleDateString()}
        </span>
      ),
    },
    {
      title: "Salary Slip",
      dataIndex: "salarySlip",
      key: "salarySlip",
      render: (fileUrl) => (
        <Space size="middle">
          <FileOpenOutlined
            onClick={() => handlePdfPreview(`${apiUrl}/${fileUrl}`)} // Preview salary slip
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
  ];

  return {
    handleUploadDocChange,
    handleChange,
    handleSubmit,
    handleCancel,
    handleShowFields,
    items,
    salarySlips,
    showFields,
    loading,
    formData,
    formErrors,
    salarySlipColumns,
    onPanelClick,
  };
}

export default SalarySlipService;
