import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import "./Personal.scss";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { CInputGroup } from "@coreui/react";
import countryList from "react-select-country-list";
import SoftButton from "components/SoftButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import choosefile from "../../assets/images/uploadfile.png";
import personalInformationService from "../APIServices/PersonalInformationService";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updatePersonalInfo } from "../Services/Actions/actions";
import Footer from "layouts/authentication/components/Footer";
import { Card, Empty } from "antd";
import axios from "axios";

function Personal({ onNext, employeeDetail }) {
  const {
    formData,
    setFormData,
    formDataError,
    setFormDataError,
    handleChange,
    handleImageChange,
    validatePersonalValidation,
    employeeData,
    handleemployeeDataChange,
  } = personalInformationService();

  const { empId } = useParams(); // Get employeeId from URL params
  const [employeeExists, setEmployeeExists] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const checkEmployeeExists = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/PersonalInformation/CheckEmployeeExistsInPersonal/${empId}`
        );
        if (response.ok) {
          const data = await response.json();
          setEmployeeExists(data.exists);
        } else {
          // Handle non-200 status codes
          const errorData = await response.json();
          throw new Error(`HTTP ${response.status} - ${errorData.message}`);
        }
      } catch (error) {
        console.error("Error checking employee existence:", error);
        toast.error("Error checking employee existence");
      }
    };

    if (empId) {
      checkEmployeeExists();
    }
  }, [empId]);

  useEffect(() => {
    // Populate formData with employee data when editing
    if (employeeDetail) {
      const { personalInfo } = employeeDetail;
      setFormData({
        ...formData,
        personalId: personalInfo.personalId,
        image: personalInfo.image,
        employeeNumber: personalInfo.employeeNumber,
        firstName: personalInfo.firstName,
        lastName: personalInfo.lastName,
        DOB: personalInfo.dob,
        yourAge: personalInfo.yourAge,
        disabilities: personalInfo.disabilities,
        gender: personalInfo.gender,
        maritalStatus: personalInfo.maritalStatus,
        SSN: personalInfo.ssn,
        nationality: personalInfo.nationality,
        bankAccountNumebr: personalInfo.bankAccountNumebr,
        streetAddress1: personalInfo.streetAddress1,
        streetAddress2: personalInfo.streetAddress2,
        city: personalInfo.city,
        state: personalInfo.state,
        postalCode: personalInfo.postalCode,
        country: personalInfo.country,
        workPhone: personalInfo.workPhone,
        mobileno: personalInfo.mobileno,
        homePhone: personalInfo.homePhone,
        workEmail: personalInfo.workEmail,
        homeEmail: personalInfo.homeEmail,
        institution: personalInfo.institution,
        degree: personalInfo.degree,
        major_specialization: personalInfo.major_specialization,
        CGPA: personalInfo.cgpa,
        startDate: personalInfo.startDate,
        endDate: personalInfo.endDate,
      });
    }
  }, [employeeDetail]);

  const dispatch = useDispatch();
  const handleNext = async () => {
    // Reset previous email error messages
    setFormDataError({ ...formDataError, workEmailError: "" });

    // Validate other form fields
    const isValid = validatePersonalValidation(formData, setFormDataError);

    if (!isValid) {
      toast.error("Fields Required");
      return;
    }

    try {
      // API call to check if work email exists
      const response = await axios.get(
        `${apiUrl}/api/PersonalInformation/CheckWorkEmailExists/${formData.workEmail}`
      );

      if (response.data.exists) {
        // Set validation message from API in formDataError for inline display
        setFormDataError((prevState) => ({
          ...prevState,
          workEmailError: response.data.message || "Work email already exists",
        }));
      } else {
        // Proceed with updating personal info and navigating to the next step
        dispatch(updatePersonalInfo(formData, employeeData));
        onNext();
      }
    } catch (error) {
      // Handle server errors gracefully
      console.error("Server error:", error);
      setFormDataError((prevState) => ({
        ...prevState,
        workEmailError: "An unexpected error occurred. Please try again later.",
      }));
    }
  };

  const options = useMemo(() => countryList().getData(), []);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageClick = () => {
    document.getElementById("fileInput").click();
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setSelectedImage(URL.createObjectURL(selectedFile));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const success = await handleNext();
      if (success) {
        onNext();
        toast.success("Data has Been Added Successfully", {
          autoClose: 5000,
          style: { background: "lightgreen", color: "white" },
        });
      }
    } catch (error) {
      toast.error(error.message || "Added failed", {
        autoClose: 5000,
        style: { background: "lightcoral", color: "white" },
      });
    }
  };

  return (
    <div className="body flex-grow-1 px-3">
      <Card title="Personal Info" className="card-professional">
        {employeeExists ? (
          <Empty description="This personal data is Already Added for this employee." />
        ) : (
          <>
            <form onSubmit={onSubmit} encType="multipart/form-data">
              <ToastContainer />
              <div className="row">
                <label className="mb-3" style={{ textAlign: "left" }}>
                  <i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;BASIC INFORMATION
                </label>
                <input
                  type="hidden"
                  value={formData.personalId}
                  className="form-control"
                  onChange={handleChange}
                />
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <div className="mb-3">
                      <div style={{ position: "relative", display: "inline-block" }}>
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Upload File *
                        </label>
                        <br></br>
                        {selectedImage ? (
                          <img
                            src={selectedImage}
                            alt="Selected Avatar"
                            style={{
                              height: "100px",
                              width: "100px",
                              cursor: "pointer",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <img
                            src={choosefile}
                            alt="Avatar"
                            style={{
                              height: "100px",
                              width: "100px",
                              cursor: "pointer",
                              border: `2px dashed ${formDataError.imageError ? "red" : "black"}`,
                            }}
                            onClick={handleImageClick}
                          />
                        )}
                        <input
                          type="file"
                          id="fileInput"
                          accept="image/*"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer",
                          }}
                          onChange={(e) => {
                            handleFileChange(e);
                            handleImageChange(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Employee Number *
                      </label>
                      <input
                        type="text"
                        name="employeeNumber"
                        value={formData.employeeNumber}
                        onChange={handleChange}
                        className="form-control"
                        disabled // Disable the input field
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        First Name *
                      </label>

                      <input
                        type="text"
                        placeholder="Enter first name"
                        name="firstName"
                        readOnly
                        value={employeeData.firstName}
                        onChange={handleemployeeDataChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Last Name *
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Last Name here"
                        name="lastName"
                        readOnly
                        value={employeeData.lastName}
                        onChange={handleemployeeDataChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Gender *
                      </label>
                      <select
                        name="gender"
                        disabled
                        value={employeeData.gender}
                        onChange={handleChange}
                        className="form-select form-select-sm"
                        aria-label="Default select example"
                      >
                        <option value=""> Please Select </option>
                        <option value="Male"> Male </option>
                        <option value="Female"> Female </option>
                        <option value="UnSpecified"> UnSpecified </option>
                        <option value="Do not wish to disclosed"> Do not wish to disclosed </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Marital Status *
                      </label>
                      <select
                        name="maritalStatus"
                        disabled
                        value={employeeData.maritalStatus}
                        onChange={handleChange}
                        className="form-select form-select-sm"
                        aria-label="Default select example"
                      >
                        <option value=""> Please Select </option>
                        <option value="Single"> Single </option>
                        <option value="Married"> Married </option>
                        <option value="Divorced"> Divorced </option>
                        <option value="Widow"> Widow </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <label className="mb-3" style={{ textAlign: "left" }}>
                  <i className="fa fa-home" aria-hidden="true"></i>&nbsp;ADDRESS
                </label>
                <div className="row">
                  <div className="col-md-9 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Street Address
                      </label>
                      <input
                        name="streetAddress"
                        readOnly
                        value={employeeData.streetAddress}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        placeholder="Enter Street Address here"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        City
                      </label>
                      <input
                        name="city"
                        readOnly
                        value={employeeData.city}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        placeholder="Enter City here"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        State
                      </label>
                      <input
                        name="state"
                        readOnly
                        value={employeeData.state}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        placeholder="Enter State / Province here"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Postal / Zip Code
                      </label>
                      <input
                        name="postalCode"
                        readOnly
                        value={employeeData.postalCode}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        placeholder="Enter Postal / Zip Code here"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9 mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Country
                    </label>
                    <CInputGroup className="mb-3">
                      <select
                        name="country"
                        disabled
                        value={employeeData.country}
                        onChange={handleChange}
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        defaultValue="US"
                      >
                        <option value="">Please select</option>
                        {options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </CInputGroup>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <label className="mb-3" style={{ textAlign: "left" }}>
                  <i className="fa fa-phone-square" aria-hidden="true"></i>&nbsp;CONTACT
                </label>
                <div className="row">
                  <div className="col-md-9 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "large" }}
                      >
                        Mobile Phone
                      </label>
                      <CInputGroup className="mb-3">
                        <PhoneInput
                          name="mobileno"
                          disabled
                          value={employeeData.mobileno}
                          // onChange={handleChange}

                          onChange={(value) => handleChange(value, "mobileno")}
                          onBlur={handleChange}
                          className="col-md-12"
                          defaultCountry="us"
                          inputStyle={{ width: "620px" }}
                        />
                      </CInputGroup>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Work Email *
                      </label>
                      <input
                        name="workEmail"
                        value={formData.workEmail}
                        onChange={handleChange}
                        type="text"
                        className={`form-control ${
                          formDataError.workEmailError ? "is-invalid" : ""
                        }`}
                        placeholder="Enter work email here"
                      />
                      {formDataError.workEmailError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.workEmailError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Home Email *
                      </label>
                      <input
                        name="email"
                        readOnly
                        value={employeeData.email}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        placeholder="Enter home email here"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-9 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Work Authorization US
                      <i
                        className="fa fa-info-circle"
                        title="Are you authorized to work lawfully in the united states for Demand base?"
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                        aria-hidden="true"
                      ></i>
                    </label>
                    <select
                      disabled
                      aria-label="Default select example"
                      name="workAuthorizationUS"
                      value={employeeData.workAuthorizationUS}
                      onChange={handleChange}
                      className="form-select"
                    >
                      <option value=""> Please Select </option>
                      <option value="Yes"> Yes </option>
                      <option value="NO"> No </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-9 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Sponsorship Needed
                      <i
                        className="fa fa-info-circle"
                        title="Will you now or in the future require Demandbase to commence ('sponsor') an immigration case in order to employ you (for example, H-1B, OPT, F1, J1, TN or other employment-based immigration case)? This is sometimes called 'sponsorship' for an employment-based visa status."
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                        aria-hidden="true"
                      ></i>
                    </label>
                    <select
                      disabled
                      aria-label="Default select example"
                      name="sponsorshipNeeded"
                      value={employeeData.sponsorshipNeeded}
                      onChange={handleChange}
                      className="form-select"
                    >
                      <option value=""> Please Select </option>
                      <option value="Yes"> Yes </option>
                      <option value="NO"> No </option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
            <div className="col-md-9 mb-3">
              <SoftButton
                variant="outlined"
                style={{ float: "inline-end" }}
                color="info"
                size="small"
                onClick={handleNext}
                disabled={employeeExists}
                type="button"
              >
                {employeeExists ? "This personal data Already Added" : "Next"}
              </SoftButton>
            </div>
          </>
        )}
      </Card>
      <Footer />
    </div>
  );
}
Personal.propTypes = {
  onNext: PropTypes.func.isRequired,
  employeeDetail: PropTypes.object.isRequired,
};
export default Personal;
