import React, { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import countryList from "react-select-country-list";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function JobOpeningService() {
  const { postingJobId } = useParams();
  const options = useMemo(() => countryList().getData(), []);
  const [jobDetails, setJobDetails] = useState(null);
  const [show, setShow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchJobDetails();
  }, []);

  async function fetchJobDetails() {
    try {
      const response = await axios.get(
        `${apiUrl}/api/HiringProcess/GetpostingById/${postingJobId}`
      );
      setJobDetails(response.data); // Set job details in state

      // Assign job title to forJobApply state using functional update to ensure you have the latest state
      setFormData((prevFormData) => ({
        ...prevFormData,
        forJobApply: response.data.postingInfo.postingJobTitle,
        postingJobId: response.data.postingInfo.postingJobId,
        skills: response.data.postingInfo.skills,
      }));
    } catch (error) {
      console.error("Error fetching job details:", error);
    }
  }

  const [formData, setFormData] = useState({
    postingJobId: "",
    firstName: "",
    lastName: "",
    gender: "",
    maritalStatus: "",
    email: "",
    mobileno: "",
    streetAddress: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    coverLetter: "",
    resume: "",
    dateAvailable: "",
    forJobApply: "",
    workAuthorizationUS: "",
    sponsorshipNeeded: "",
  });

  const handleShow = () => {
    setShow(false);
  };

  const handleCancelClick = () => {
    navigate("/viewcareer");
  };

  const [formDataError, setFormDataError] = useState({
    firstNameError: "",
    lastNameError: "",
    genderError: "",
    maritalStatusError: "",
    nationalityError: "",
    emailError: "",
    mobilenoError: "",
    streetAddressError: "",
    cityError: "",
    stateError: "",
    postalCodeError: "",
    countryError: "",
    coverLetterError: "",
    resumeError: "",
    dateAvailableError: "",
    workAuthorizationUSError: "",
    sponsorshipNeededError: "",
  });

  const validateValidation = (formData, setFormDataError) => {
    let hasError = false;

    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check each field for emptiness or invalidity
    Object.keys(formData).forEach((fieldName) => {
      if (
        (typeof formData[fieldName] === "string" &&
          (!formData[fieldName] || !formData[fieldName].trim())) || // For string fields
        (typeof formData[fieldName] === "number" &&
          (isNaN(formData[fieldName]) || formData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      } else {
        // Clear the error message if the field is not empty
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: "", // Clear the error message
        }));
      }

      // Email validation
      if (fieldName === "email") {
        if (!formData[fieldName]) {
          // If email field is empty, display "Email is required."
          setFormDataError((prevState) => ({
            ...prevState,
            [`${fieldName}Error`]: "Email is required.",
          }));
          hasError = true;
        } else if (!emailRegex.test(formData[fieldName])) {
          // If email format is invalid, display "Invalid email format."
          setFormDataError((prevState) => ({
            ...prevState,
            [`${fieldName}Error`]: "Invalid email format.",
          }));
          hasError = true;
        } else {
          // Clear the error message if email is valid
          setFormDataError((prevState) => ({
            ...prevState,
            [`${fieldName}Error`]: "",
          }));
        }
      }
    });

    // Check if cover letter has an error
    if (formData.coverLetterError) {
      hasError = true;
    }

    // Check if any error occurred
    if (hasError) {
      return false;
    }

    // Check if all fields are filled out
    const isAllFieldsFilled = Object.values(formData).every(
      (value) => value !== null && value !== undefined && value !== ""
    );

    if (!isAllFieldsFilled) {
      // If any field is empty, display an error message
      setFormDataError((prevState) => ({
        ...prevState,
        genericError: "Please fill out all fields.",
      }));
      return false;
    }

    return true;
  };

  const handleChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    // Clear the error message if the value is not empty
    if (value.trim() !== "") {
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } else {
      // Set the error message if the value is empty
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: `${name} is required.`,
      }));
    }

    if (name === "dateAvailable") {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      if (selectedDate <= currentDate) {
        // Changed from >= to <=
        setFormDataError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "Please select a date after today.", // Changed the error message
        }));
        return;
      }
    }

    setFormData({ ...formData, [name]: value });
  };

  const [error, setError] = useState(false);

  const handleUploadDocChange = (e) => {
    const file = e.target.files[0];
    const allowedExtensions = [".pdf", ".PDF", ".docx", ".DOCX", ".doc", ".DOC"];

    if (file) {
      const fileExtension = file.name.split(".").pop();
      if (!allowedExtensions.includes("." + fileExtension)) {
        setError("Unsupported file type. Only PDF and DOCX files are allowed.");
        setFormData({ ...formData, coverLetter: null }); // Update the state with null for resume
        setFormDataError((prevState) => ({
          ...prevState,
          coverLetterError: "", // Clear the error message for coverLetter
        }));
        return;
      }

      setError("");
      setFormData({ ...formData, coverLetter: file }); // Update the state with the selected file for resume
      setFormDataError((prevState) => ({
        ...prevState,
        coverLetterError: "", // Clear the error message for coverLetter
      }));
    }
  };

  const handleUploadresumeChange = (e) => {
    const file = e.target.files[0];
    const allowedExtensions = [".pdf", ".PDF", ".docx", ".DOCX", ".doc", ".DOC"];

    if (file) {
      const fileExtension = file.name.split(".").pop();
      if (!allowedExtensions.includes("." + fileExtension)) {
        setError("Unsupported file type. Only PDF and DOCX files are allowed.");
        setFormData({ ...formData, resume: null }); // Update the state with null for resume
        return;
      }
      setError("");
      setFormData({ ...formData, resume: file }); // Update the state with the selected file for resume
      setFormDataError((prevState) => ({
        ...prevState,
        resumeError: "", // Clear the error message for coverLetter
      }));
    }
  };
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form data
    const isValid = validateValidation(formData, setFormDataError);

    if (isValid) {
      const formDataToSend = new FormData();
      // Append text fields to FormData
      Object.keys(formData).forEach((key) => {
        if (formData[key] && key !== "coverLetter" && key !== "resume") {
          formDataToSend.append(key, formData[key]);
        }
      });
      if (formData.coverLetter) {
        formDataToSend.append("coverLetter", formData.coverLetter);
      }
      if (formData.resume) {
        formDataToSend.append("resume", formData.resume);
      }
      try {
        setIsLoading(true);
        const response = await fetch(
          `${apiUrl}/api/EmployeeJobApplication/Employee-Job-Application`,
          {
            method: "POST",
            body: formDataToSend,
          }
        );
        const responseText = await response.text();

        if (response.ok) {
          // Reset form after successful submission
          setFormData({
            firstName: "",
            lastName: "",
            gender: "",
            maritalStatus: "",
            nationality: "",
            email: "",
            mobileno: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            country: "",
            coverLetter: null,
            resume: null,
            dateAvailable: "",
            forJobApply: "",
            workAuthorizationUS: "",
            sponsorshipNeeded: "",
          });
          setIsSubmitted(true);
          localStorage.setItem("applicationSubmitted", "true");
          toast.success("Application submitted successfully!");
        } else {
          toast.error(`${responseText}`);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to submit application. Please try again");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return {
    formData,
    options,
    handleSubmit,
    isSubmitted,
    handleUploadresumeChange,
    handleUploadDocChange,
    handleChange,
    formDataError,
    handleCancelClick,
    handleShow,
    jobDetails,
    show,
    isLoading,
  };
}

export default JobOpeningService;
