import { Button, Input, Tooltip, Dropdown, Empty, Card, Form } from "antd";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Oval } from "react-loader-spinner";
import Service from "./Service";

function Announcement() {
  const {
    isAdding,
    handleCancel,
    editMode,
    loading,
    announcements,
    menu,
    handleEditAnnouncement,
    handleSubmit,
    handleAddAnnouncement,
    truncateText,
    form,
  } = Service();
  return (
    <Card
      className="card-professional"
      style={{ height: "100%" }}
      title="Announcement"
      extra={
        !isAdding &&
        !editMode && (
          <Button
            type="default"
            className="add-announcement-button"
            onClick={handleAddAnnouncement}
          >
            Add Announcement
          </Button>
        )
      }
    >
      <div className="row">
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Oval
              height={40}
              width={40}
              color="#4fa94d"
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <>
            {!isAdding && !editMode && (
              <div className="announcement-list">
                {announcements.length > 0 ? (
                  announcements.map((ann) => (
                    <div key={ann.announcementId} className="announcement-container">
                      <FontAwesomeIcon icon={faInfoCircle} className="frame-icon" />
                      <div className="announcement-content">
                        <p className="announcement-text">
                          <Tooltip title={ann.announcement} trigger="hover">
                            {truncateText(ann.announcement, 50)}
                          </Tooltip>
                        </p>
                        <p className="message-text">
                          <Tooltip title={ann.message} trigger="hover">
                            {truncateText(ann.message, 45)}
                          </Tooltip>
                        </p>
                      </div>
                      <Dropdown overlay={menu(ann)} placement="bottomRight">
                        <Button className="complete-button" color="primary">
                          Actions
                        </Button>
                      </Dropdown>
                    </div>
                  ))
                ) : (
                  <Empty description="No announcement added" />
                )}
              </div>
            )}
            {(isAdding || editMode) && (
              <Form
                form={form}
                layout="vertical"
                onFinish={isAdding ? handleSubmit : handleEditAnnouncement}
              >
                <Form.Item
                  label="Subject"
                  name="announcement"
                  rules={[{ required: true, message: "Please enter the announcement subject" }]}
                >
                  <Input placeholder="Enter announcement" />
                </Form.Item>

                <Form.Item
                  label="Message"
                  name="announcementMessage"
                  rules={[{ required: true, message: "Please enter the announcement message" }]}
                >
                  <Input placeholder="Enter message" />
                </Form.Item>

                <div className="buttons">
                  <Button type="default" onClick={handleCancel}>
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button type="primary" htmlType="submit">
                    {isAdding ? "Add Announcement" : "Update Announcement"}
                  </Button>
                </div>
              </Form>
            )}
          </>
        )}
      </div>
    </Card>
  );
}

export default Announcement;
