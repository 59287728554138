import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

export default function FeedBack() {
  const { postingJobId } = useParams();
  const [jobOfferOpen, setJobOfferOpen] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loadingJobOffer, setLoadingEmails] = useState({});

  const [formData, setFormData] = useState({
    ndafile: null,
    ncafile: null,
    employeeAgreement: null,
    i_nine: null,
    w_four: null,
    offerLetter: null,
    status: "Send",
  });
  const [jobOfferEmployeeId, setJobOfferEmployeeId] = useState(null);
  const [jobOfferemail, setJobOfferemail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [jobOfferScheduled, setJobOfferScheduled] = useState({});

  const [jobOffer, setJobOffer] = useState({});

  useEffect(() => {
    fetchEmailStatuses();
    fetchEmployeesByPostingJobId(postingJobId);
  }, []);

  const fetchEmployeesByPostingJobId = async (postingJobId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/EmployeeJobApplication/GetEmployeesByPostingJobId/${postingJobId}`
      );
      const responseData = response.data.employeeJobApplications.map((info) => ({
        key: info.employeeId.toString(),
        employeeId: info.employeeId,
        status: info.status,
      }));
      setJobOfferScheduled(responseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchEmailStatuses = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/EmployeeInterview/GetAllEmails`);
      const emailsToCheck = response.data; // Assuming the API returns an array of email strings
      const emailStatus = [];

      for (const email of emailsToCheck) {
        const response = await axios.get(`${apiUrl}/api/EmployeeJobApplication/GetEmailStatus`, {
          params: { email },
        });
        if (response.status === 200) {
          const { emailSent, status } = response.data;
          emailStatus.push({ email, emailSent, status });
        }
      }

      setJobOfferScheduled(emailStatus);
    } catch (error) {
      console.error("Error fetching email statuses:", error);
    }
  };

  const handleContractChange = (e, fileType) => {
    const file = e.target.files[0];
    const allowedExtensions = [".pdf", ".PDF", ".docx", ".DOCX", ".doc", ".DOC"];

    if (file) {
      const fileExtension = file.name.split(".").pop();
      if (!allowedExtensions.includes("." + fileExtension)) {
        setErrorMessage("Unsupported file type. Only PDF and DOCX files are allowed.");
        setFormData({ ...formData, [fileType]: null }); // Update the state with null for the specific file type
        return;
      }
      setErrorMessage("");
      setFormData({ ...formData, [fileType]: file }); // Update the state with the selected file for the specific file type
    }
  };

  const showjobOfferModal = (employeeId, email) => {
    setJobOfferEmployeeId(employeeId);
    setJobOfferemail(email);
    setJobOfferOpen(true);
  };

  const handleJobOfferCancel = () => {
    setJobOfferOpen(false);
  };

  const handleJobOfferClick = () => {
    setJobOfferOpen(false);
    JobOfferClick();
  };

  const JobOfferClick = async () => {
    const formDataToSend = new FormData();

    // Check if files are present before appending
    if (formData.ndafile) formDataToSend.append("ndafile", formData.ndafile);
    if (formData.ncafile) formDataToSend.append("ncafile", formData.ncafile);
    if (formData.employeeAgreement)
      formDataToSend.append("employeeAgreement", formData.employeeAgreement);
    if (formData.i_nine) formDataToSend.append("i_nine", formData.i_nine);
    if (formData.w_four) formDataToSend.append("w_four", formData.w_four);
    if (formData.offerLetter) formDataToSend.append("offerLetter", formData.offerLetter);

    // Append other data
    formDataToSend.append("employeeId", jobOfferEmployeeId);
    formDataToSend.append("email", jobOfferemail);
    formDataToSend.append("status", "You Send");

    try {
      setLoadingEmails((prev) => ({ ...prev, [jobOfferemail]: true }));
      const response = await fetch(`${apiUrl}/api/EmployeeInterview/Employee-Contract`, {
        method: "POST",
        body: formDataToSend,
      });

      if (response.ok) {
        // Reset form after successful submission
        setJobOfferOpen(false);
        setErrorMessage("");
        setJobOffer((prevState) => ({
          ...prevState,
          [jobOfferEmployeeId]: true,
        }));
        toast.success("Contract sent successfully!");
        fetchEmailStatuses();
        setJobOfferOpen(false);
      } else {
        const errorData = await response.text();
        console.log("Error response:", errorData); // Log the error response
        toast.error("Failed to submit the contract.");
      }
    } catch (error) {
      console.error("Error:", error); // Log error in console
      toast.error("Submission failed.");
    } finally {
      setLoadingEmails((prev) => ({ ...prev, [jobOfferemail]: false }));
    }
  };

  return {
    jobOfferOpen,
    formData,
    jobOffer,
    setFormData,
    handleContractChange,
    showjobOfferModal,
    handleJobOfferCancel,
    handleJobOfferClick,
    jobOfferScheduled,
    loadingJobOffer,
  };
}
