import React, { useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

export default function FeedBack() {
  const [openContract, setOpenContract] = useState(false);
  const [employeeContract, setEmployeeContract] = useState([]);
  const [contractPdf, setContractPdf] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [empId, setEmpId] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL

  const handleContractCancel = () => {
    setOpenContract(false);
  };

  const showcontractModal = (employeeId, email) => {
    try {
      // Fetch contract data for the specific employee
      axios
        .get(`${apiUrl}/api/EmployeeInterview/GetReceiveContractByEmail/${email}`)
        .then((response) => {
          const responseData = response.data;
          setEmployeeContract(responseData);
          setOpenContract(true);
          setEmployeeEmail(email);
          setEmpId(employeeId);
        })
        .catch((error) => {
          console.error("Error fetching contract data:", error);
        });
    } catch (error) {
      console.error("Error fetching contract data:", error);
    }
  };

  const handleContractPreview = async (fileUrl) => {
    try {
      // Get the file extension
      const extension = fileUrl.split(".").pop().toLowerCase();

      if (extension === "pdf") {
        // If PDF, set the selected PDF state and open modal for preview
        setContractPdf(fileUrl);
        const modal = new bootstrap.Modal(document.getElementById("staticBackdropc"));
        modal.show();
      } else {
        // If not PDF, download the file
        window.open(fileUrl, "_blank");
      }
    } catch (error) {
      console.error("Error handling file:", error);
    }
  };

  return {
    openContract,
    employeeContract,
    contractPdf,
    handleContractCancel,
    showcontractModal,
    handleContractPreview,
    employeeEmail,
    empId
  };
}
