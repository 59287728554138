import React, { useState, useEffect } from "react";
import { message, Rate, Tooltip } from "antd";
import SoftBadge from "components/SoftBadge";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import generatePDFReport from "./PDFGenrator";
import { fetchDepartments, fetchEmployees, updateEmployeeStatus } from "./apis";

function Service() {
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("All Departments");
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchEmployeesData();
    getDepartmentsData();
  }, []);

  const fetchEmployeesData = async (department = "All Departments") => {
    setLoading(true);
    try {
      const data = await fetchEmployees();
      const filteredData =
        department === "All Departments"
          ? data
          : data.filter((employee) => employee.team === department);
      setEmployees(filteredData);
    } catch (error) {
      console.error("Failed to fetch employees");
    } finally {
      setLoading(false);
    }
  };

  const getDepartmentsData = async () => {
    try {
      const departmentNames = await fetchDepartments();
      setDepartments(departmentNames || []); // Ensure departments is always an array
    } catch (error) {
      message.error("Failed to fetch departments");
    }
  };

  const updateStatus = async (personalId) => {
    try {
      await updateEmployeeStatus(personalId);
      message.success("Status updated to In Process");
      fetchEmployeesData(selectedDepartment);
    } catch (error) {
      message.error("Failed to update status");
    }
  };

  const handleDepartmentChange = (e) => {
    const selectedDept = e.target.value;
    setSelectedDepartment(selectedDept);
    fetchEmployeesData(selectedDept);
  };

  const handleDownloadReport = () => {
    generatePDFReport(employees, selectedDepartment);
  };

  const columns = [
    {
      title: "Employee_ID",
      dataIndex: ["personalInformation", "personalId"],
      key: "personalId",
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) =>
        `${record?.personalInformation?.firstName} ${record?.personalInformation?.lastName}`, // Use optional chaining
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, record) => {
        const email = record?.personalInformation?.email || ""; // Safely handle undefined
        const truncatedEmail = email.length > 8 ? `${email.slice(0, 8)}...` : email;
        return (
          <Tooltip title={email}>
            <span>{truncatedEmail}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Reports_To",
      dataIndex: "manager",
      key: "manager",
    },
    {
      title: "Evaluated_By",
      dataIndex: "manager",
      key: "manager",
    },
    {
      title: "Dept",
      dataIndex: "team",
      key: "team",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        const status = record?.personalInformation?.status;
        let badgeContent, color, onClick;
        switch (status) {
          case 0:
            badgeContent = "Ask to initiate";
            color = "warning";
            onClick = () => updateStatus(record?.personalInformation?.personalId);
            break;
          case 1:
            badgeContent = "In process";
            color = "info";
            break;
          case 2:
            badgeContent = "Completed";
            color = "success";
            break;
          default:
            badgeContent = "Unknown status";
            color = "default";
        }
        return (
          <SoftBadge
            variant="gradient"
            badgeContent={badgeContent}
            color={color}
            size="xs"
            container
            onClick={status === 0 ? onClick : null}
            style={{ cursor: status === 0 ? "pointer" : "default" }}
          />
        );
      },
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      render: (_, record) => {
        const rating = record?.personalInformation?.rating;
        return (
          <span style={{ display: 'inline-block' }}>
            {rating !== undefined ? (
              <Rate
                disabled
                value={rating}
                count={5}
                style={{ color: 'gold', fontSize: '16px', lineHeight: 'normal' }}
              />
            ) : (
              "N/A"
            )}
          </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (_, record) => (
        <Link to={`/performance/${record?.personalInformation?.email}`}>
          <EyeOutlined className="eyeOutlined" style={{ marginLeft: "10px" }} />
        </Link>
      ),
    },
  ];

  return {
    departments,
    loading,
    handleDepartmentChange,
    handleDownloadReport,
    columns,
    selectedDepartment,
    employees,
  };
}

export default Service;
