import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button, Modal, Table, Card } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

export default function Data() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { confirm } = Modal;
  const [loadingEmployeeId, setLoadingEmployeeId] = useState(null);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageKey, setImageKey] = useState(Date.now());

  useEffect(() => {
    fetchData();
  }, [imageKey]);

  const handleDelete = (employeeId) => {
    confirm({
      title: "Are you sure you want to Terminate this employee?",
      icon: <ExclamationCircleOutlined />,
      content: "Once terminated, this action cannot be undone.",
      okText: "Yes, Terminate",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          setLoadingEmployeeId(employeeId);
          const response = await axios.delete(
            `${apiUrl}/api/EndEmployeeContract/Employee-Contract-End/${employeeId}`,
            { headers: { "Content-Type": "application/json" } }
          );

          toast.success(response.data);

          // Remove employee from the UI after successful deletion
          setRows((prevRows) => prevRows.filter((info) => info.employeeId !== employeeId));
        } catch (error) {
          if (error.response && error.response.data) {
            toast.error(error.response.data);
          } else {
            toast.error("Failed to reject employee.");
          }
        } finally {
          setLoadingEmployeeId(null);
        }
      },
      onCancel() {
        console.log("Cancel button clicked");
      },
    });
  };

  async function fetchData() {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/api/PersonalInformation/Get-All`);
      const responseData = response.data;

      // Extract data from the response
      const personalInfo = responseData.personalInfo;
      const jobs = responseData.job;

      // Generate rows based on the fetched data
      const updatedRows = personalInfo.map((info) => {
        const jobData = jobs.find((job) => job.personalId === info.personalId);

        return {
          employeeId: info.personalId, // Ensure you pass employeeId for row identification
          Employee_Photo: (
            <img
              key={`${info.personalId}_${imageKey}`}
              src={`${apiUrl}/${info.image}`}
              style={{ width: "80px", height: "80px", borderRadius: "50px" }}
              alt=""
            />
          ),
          Employee_No: info.employeeNumber,
          Name: (
            <Link to={`/employeeDetail/${info.personalId}`}>
              {`${info.firstName} ${info.lastName}`}
            </Link>
          ),
          Job_Title: jobData ? jobData.team : "-",
          Location: `${info.city}, ${info.state}`,
          Hire_Date: jobData ? new Date(jobData.probationStartDate).toLocaleDateString() : "-",
          Terminate: (
            <Button
              type="primary"
              className="terminate-button"
              onClick={() => handleDelete(info.personalId)}
              loading={loadingEmployeeId === info.personalId}
            >
              Terminate
            </Button>
          ),
        };
      });

      // Update rows state
      setRows(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  const columns = [
    {
      title: "Image",
      dataIndex: "Employee_Photo",
      align: "center",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Employee_No",
      dataIndex: "Employee_No",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "Name",
      align: "center",
    },
    {
      title: "Job_Title",
      dataIndex: "Job_Title",
      align: "center",
    },
    {
      title: "Location",
      dataIndex: "Location",
      align: "center",
    },
    {
      title: "Hire Date",
      dataIndex: "Hire_Date",
      align: "center",
    },
    {
      title: "Terminate",
      dataIndex: "Terminate",
      align: "center",
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />
      <Card title="Employees" className="card-professional" style={{ margin: "20px" }}>
        <Table
          className="table-responsive"
          loading={loading}
          columns={columns}
          dataSource={rows}
          rowKey="employeeId"
          pagination={true}
        />
      </Card>
    </DashboardLayout>
  );
}
