import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

export default function InterviewService() {
  const { postingJobId } = useParams();
  const [firstDateTime, setFirstDateTime] = useState("");
  const [secondDateTime, setSecondDateTime] = useState("");
  const [thirdDateTime, setThirdDateTime] = useState("");
  const [employeeId, setEmployeeId] = useState(null);
  const [email, setEmail] = useState(null);
  const [loadingInterviewEmails, setLoadingEmails] = useState({});

  const apiUrl = process.env.REACT_APP_API_URL;
  const [interviewScheduled, setInterviewScheduled] = useState({});

  useEffect(() => {
    fetchEmployeesByPostingJobId(postingJobId);
  }, []);

  const fetchEmployeesByPostingJobId = async (postingJobId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/EmployeeJobApplication/GetEmployeesByPostingJobId/${postingJobId}`
      );
      const responseData = response.data.employeeJobApplications.map((info) => ({
        key: info.employeeId.toString(),
        employeeId: info.employeeId,
        status: info.status,
      }));
      setInterviewScheduled(responseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [open, setOpen] = useState(false);
  const showModal = (employeeId, email, firstDateTime, secondDateTime, thirdDateTime) => {
    setOpen(true);
    setEmployeeId(employeeId);
    setEmail(email);
    setFirstDateTime(firstDateTime);
    setSecondDateTime(secondDateTime);
    setThirdDateTime(thirdDateTime);
  };

  const handleOk = () => {
    const currentDate = new Date();

    // Extract dates without time
    const firstDate = new Date(firstDateTime).toLocaleDateString();
    const secondDate = new Date(secondDateTime).toLocaleDateString();
    const thirdDate = new Date(thirdDateTime).toLocaleDateString();

    // Extract times from datetime
    const firstTime = new Date(firstDateTime).toLocaleTimeString();
    const secondTime = new Date(secondDateTime).toLocaleTimeString();
    const thirdTime = new Date(thirdDateTime).toLocaleTimeString();

    // Check if same date has the same time
    if (
      (firstDate === secondDate && firstTime === secondTime) ||
      (secondDate === thirdDate && secondTime === thirdTime) ||
      (firstDate === thirdDate && firstTime === thirdTime)
    ) {
      toast.error("Interview times cannot be the same for the same date.");
      return;
    }

    // Check if interview times are in chronological order
    if (
      new Date(firstDateTime) >= new Date(secondDateTime) ||
      new Date(secondDateTime) >= new Date(thirdDateTime)
    ) {
      toast.error("Interview times must be in chronological order.");
      return;
    }

    // Check if interview times are after the current date and time
    if (
      new Date(firstDateTime) < currentDate ||
      new Date(secondDateTime) < currentDate ||
      new Date(thirdDateTime) < currentDate
    ) {
      toast.error("Interview times cannot be before the current date and time.");
      return;
    }

    setOpen(false);
    handleInterviewClick(employeeId, email, firstDateTime, secondDateTime, thirdDateTime);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleInterviewClick = async (
    employeeId,
    email,
    firstDateTime,
    secondDateTime,
    thirdDateTime
  ) => {
    try {
      setLoadingEmails((prev) => ({ ...prev, [email]: true }));
      const interviewData = { employeeId, email, firstDateTime, secondDateTime, thirdDateTime };
      const response = await axios.post(
        `${apiUrl}/api/EmployeeInterview/Employee-interview`,
        interviewData
      );

      if (response.status === 200) {
        setInterviewScheduled((prevInterviewScheduled) =>
          prevInterviewScheduled.map((interview) =>
            interview.employeeId === employeeId ? { ...interview, status: 3 } : interview
          )
        );
        toast.success("Interview Schedule Date time are send to the employee Successfully");
      }
    } catch (error) {
      console.error("Error handling interview:", error);

      // Display error message from the response if available
      if (error.response && error.response.data) {
        alert(`Error: ${error.response.data}`);
      } else {
        alert("An error occurred while scheduling the interview.");
      }
    } finally {
      setLoadingEmails((prev) => ({ ...prev, [email]: false }));
    }
  };

  return {
    employeeId,
    open,
    setOpen,
    email,
    firstDateTime,
    secondDateTime,
    thirdDateTime,
    setFirstDateTime,
    setSecondDateTime,
    setThirdDateTime,
    showModal,
    handleOk,
    handleCancel,
    handleInterviewClick,
    interviewScheduled,
    setInterviewScheduled,
    loadingInterviewEmails,
  };
}
