import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

// Fetch all personal information from the API
export const fetchPersonalInformation = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/PersonalInformation/Get-All-PersonalInfo`);
    if (!Array.isArray(response.data)) {
      throw new Error("Invalid personal information data format");
    }
    return response.data; // Return the array of personal information
  } catch (error) {
    console.error("Error fetching personal information data:", error);
    throw error; // Propagate the error to the caller
  }
};

// Fetch department details for a specific employee by their ID
export const fetchDepartmentByEmployeeId = async (employeeId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/PersonalInformation/Get-Department-By-EmployeeId/${employeeId}`
    );
    return response.data; // Return department details
  } catch (error) {
    console.error(`Error fetching department for employee ID ${employeeId}:`, error);
    return null; // Return null on error
  }
};

// Upload a salary slip using the provided form data
export const uploadSalarySlip = async (formData) => {
  const formDataToSend = new FormData();
  // Append form fields to FormData
  formDataToSend.append("email", formData.email);
  formDataToSend.append("firstName", formData.firstName);
  formDataToSend.append("lastName", formData.lastName);
  formDataToSend.append("personalId", formData.personalId);
  formDataToSend.append("department", formData.department);
  formDataToSend.append("fromDate", formData.fromDate);
  formDataToSend.append("endDate", formData.endDate);
  formDataToSend.append("salarySlip", formData.salarySlip);

  try {
    await axios.post(`${apiUrl}/api/SalarySlip/Salary-Slip`, formDataToSend);
    // Successfully uploaded, no return value needed
  } catch (error) {
    console.error("Error uploading salary slip:", error);
    throw error; // Propagate the error to the caller
  }
};

// Fetch salary slips for a specific personal ID
export const fetchSalarySlipsByPersonalId = async (personalId) => {
  try {
    const response = await axios.get(`${apiUrl}/api/SalarySlip/Get-Salary-Slip/${personalId}`);
    return response.data; // Return the object containing the result array of salary slips
  } catch (error) {
    console.error(`Error fetching salary slips for personal ID ${personalId}:`, error);
    throw error; // Propagate the error to the caller
  }
};
