import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Select,
  message,
  Card,
  Row,
  Col,
  Modal,
  List,
  Typography,
} from "antd";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import moment from "moment";

const { TextArea } = Input;

const HardwareAssets = () => {
  const [softwareAssets, setSoftwareAssets] = useState([]);
  const [personalInfo, setPersonalInfo] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchSoftwareAssets();
    fetchPersonalInfo();
  }, []);

  const fetchSoftwareAssets = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/Assets/Get-Hardware-Assets`);
      setSoftwareAssets(response.data);
    } catch (error) {
      console.error("Error fetching software assets:", error);
      message.error("Failed to fetch software assets.");
    }
  };

  const fetchPersonalInfo = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/Assets/Get-PersonalInfo`);
      setPersonalInfo(response.data);
    } catch (error) {
      console.error("Error fetching personal info:", error);
      message.error("Failed to fetch personal info.");
    }
  };

  const toggleAddForm = () => {
    setIsAdding(!isAdding);
    form.resetFields();
  };

  const handleAddSoftwareAsset = async (values) => {
    try {
      setIsLoading(true);
      const formattedValues = {
        ...values,
        dateLoaned: values.dateLoaned.format("YYYY-MM-DD"),
        dateReturned: values.dateReturned.format("YYYY-MM-DD"),
      };

      await axios.post(`${apiUrl}/api/Assets/Add-Hardware-Assets`, formattedValues);
      message.success("Hardware asset added successfully.");
      toggleAddForm();
      fetchSoftwareAssets();
    } catch (error) {
      console.error("Error adding Hardware asset:", error);
      message.error("Failed to add Hardware asset.");
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    { title: "Personal Id", dataIndex: "personalId", key: "personalId" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Department", dataIndex: "category", key: "category" },
    {
      title: "Assets Cost",
      dataIndex: "assetsCost",
      key: "assetsCost",
      render: (cost) => `$${cost.toFixed(2)}`,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="link" onClick={() => handleViewMore(record)}>
          View More
        </Button>
      ),
    },
  ];

  // Additional columns to show in the modal
  const additionalColumns = [
    { title: "Date Loaned", dataIndex: "dateLoaned", key: "dateLoaned" },
    {
      title: "Date Returned",
      dataIndex: "dateReturned",
      key: "dateReturned",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    { title: "Notes", dataIndex: "notes", key: "notes" },
    { title: "Description", dataIndex: "description", key: "description" },
  ];

  const handleViewMore = (asset) => {
    showMoreDataModal(asset);
  };

  const showMoreDataModal = (asset) => {
    Modal.info({
      title: "Additional Data",
      content: (
        <div>
          <Table
            dataSource={[asset]}
            columns={additionalColumns}
            className="table-responsive"
            pagination={false}
            rowKey="licenseKey"
          />
        </div>
      ),
      onOk() {
        // Reset any state here if needed
      },
      centered: true,
      width: "800px",
    });
  };

  const disablePastDates = (current) => {
    return current && current < moment().startOf("day");
  };

  const disableExpiryDate = (current) => {
    const subscriptionDate = form.getFieldValue("subscriptionDate");
    return (
      current && (current.isSame(subscriptionDate, "day") || current.isBefore(subscriptionDate))
    );
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div style={{ padding: "24px" }}>
          {!isAdding ? (
            <Card
              className="card-professional"
              title="Hardware Assets"
              extra={
                <Button type="default" onClick={toggleAddForm}>
                  Add Hardware Asset
                </Button>
              }
            >
              <Table
                dataSource={softwareAssets}
                columns={columns}
                className="table-responsive"
                rowKey="softwareAssetsId"
                pagination={{ pageSize: 10 }}
              />
            </Card>
          ) : (
            <Card
              className="card-professional"
              title="Add New Software Asset"
            >
              <Form
                form={form}
                onFinish={handleAddSoftwareAsset}
                layout="vertical"
                initialValues={{
                  assetsCost: 0,
                  subscriptionDate: moment(),
                  expiryDate: moment(),
                }}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="personalId"
                      label="Personal ID"
                      rules={[{ required: true, message: "Personal ID is required" }]}
                    >
                      <Select
                        placeholder="Select Employee ID"
                        onChange={(value) => {
                          // Find the selected person by personalId
                          const selectedPerson = personalInfo.find(
                            (person) => person.personalId === value
                          );

                          if (selectedPerson) {
                            // Set the name and department fields in the form
                            form.setFieldsValue({
                              name: `${selectedPerson.firstName} ${selectedPerson.lastName}`,
                              category: selectedPerson.department,
                            });
                          }
                        }}
                      >
                        {personalInfo.map((person) => (
                          <Select.Option key={person.personalId} value={person.personalId}>
                            {person.personalId}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="name"
                      label="Personal Name"
                      rules={[{ required: true, message: "Personal name is required" }]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="category"
                      label="Department"
                      rules={[{ required: true, message: "Department is required" }]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="assetsCost"
                      label="Assets Cost"
                      rules={[
                        { required: true, message: "Assets cost is required" },
                        {
                          type: "number",
                          min: 0,
                          message: "Assets cost must be a positive number",
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        style={{ width: "100%" }}
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="serial"
                      label="Serial"
                      rules={[{ required: true, message: "Serial is required" }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="dateLoaned"
                      label="Date Loaned"
                      rules={[{ required: true, message: "Date Loaned is required" }]}
                    >
                      <DatePicker style={{ width: "100%" }} disabledDate={disablePastDates} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="dateReturned"
                      label="Date Returned"
                      rules={[
                        { required: true, message: "Date Returned is required" },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const subscriptionDate = getFieldValue("dateLoaned");
                            if (!value || value.isAfter(subscriptionDate)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Expiry date must be after dateLoaned date!")
                            );
                          },
                        }),
                      ]}
                    >
                      <DatePicker style={{ width: "100%" }} disabledDate={disableExpiryDate} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="notes"
                      label="Notes"
                      rules={[{ required: true, message: "Notes are required" }]}
                    >
                      <TextArea rows={3} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="description"
                      label="Description"
                      rules={[{ required: true, message: "Description is required" }]}
                    >
                      <TextArea rows={3} />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {isLoading ? "Please Wait..." : "Submit"}
                    {isLoading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </Button>
                  <Button style={{ marginLeft: 8 }} onClick={toggleAddForm}>
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default HardwareAssets;
