import React from "react";
import { Chart } from "react-google-charts";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import { Button, Card, Col, Empty, Form, Input, Row, Table } from "antd";
import { Oval } from "react-loader-spinner";
import Service from "./Service";

function EmployeeAttendance() {
  const {
    isLoading,
    checkIn,
    checkOut,
    history,
    attendanceData,
    isLoadingPage,
    attendanceRecords,
    handleCheckIn,
    hide,
    CheckOuthide,
    handleHistory,
    handleCheckOut,
    historyhide,
    handleChange,
    handleAddCheckIn,
    columns,
    loading,
    chartData,
    handleAddCheckOut,
    attendanceSummary,
    formData,
    formDataCheckOut,
  } = Service();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card title="Attendance work from home" className="card-professional">
        {isLoadingPage ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "400px" }}
          >
            <Oval height={80} width={80} color="blue" />
          </div>
        ) : (
          <div className="row">
            <div className="col-md-6" style={{ height: "300px" }}>
              {!loading && attendanceSummary ? (
                <Chart
                  chartType="PieChart"
                  data={chartData}
                  options={{
                    pieHole: 0.4,
                    pieSliceText: "value",
                    tooltip: { text: "value" },
                    colors: ["green", "#faae20", "#FF5733"],
                  }}
                  width={"100%"}
                  height={"100%"}
                />
              ) : (
                <Empty style={{ marginTop: "65px" }} description="No Check In Yet!" />
              )}
            </div>
            <div className="btncheckin">
              {attendanceRecords.length > 0 ? (
                <React.Fragment>
                  {attendanceRecords[0].checkInStatus === 0 ? (
                    <Button
                      type="primary"
                      onClick={() => handleCheckOut(attendanceRecords[0].checkInId)}
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Check Out
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => handleCheckIn(attendanceRecords[0].checkInId)}
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Check In
                    </Button>
                  )}
                </React.Fragment>
              ) : (
                <Button
                  type="primary"
                  onClick={handleCheckIn} // Adjust this according to your requirement
                >
                  <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Check In
                </Button>
              )}
            </div>

            <div className="history">
              <Button type="primary" onClick={handleHistory}>
                <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;History
              </Button>
            </div>
          </div>
        )}
      </Card>
      <br></br>
      {checkIn && (
        <Card
          className="card-professional"
          title="Check In"
          extra={
            <Button type="default" onClick={hide}>
              Hide
            </Button>
          }
        >
          <Form
            layout="vertical"
            onFinish={handleAddCheckIn} // Add form submit handler
            initialValues={formData}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Department *" name="department" required>
                  <Input
                    placeholder="Enter department here"
                    disabled
                    value={formData.department}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Check In *" name="checkInDateTime" required>
                  <Input
                    type="datetime-local"
                    readOnly
                    value={formData.checkInDateTime}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button
                type="primary"
                style={{ float: "right" }}
                disabled={isLoading}
                htmlType="submit"
              >
                {isLoading ? "Please Wait..." : "Submit"}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
      {history && (
        <Card
          className="card-professional"
          title="History"
          extra={
            <Button type="default" onClick={historyhide}>
              Hide
            </Button>
          }
        >
          <Table
            className="table-responsive"
            bordered
            dataSource={attendanceData}
            columns={columns}
            pagination={{ pageSize: 10 }}
          />
        </Card>
      )}
      {checkOut && (
        <Card
          className="card-professional"
          title="Check out"
          extra={
            <Button type="default" onClick={CheckOuthide}>
              Hide
            </Button>
          }
        >
          <div className="col-md-5">
            <div className="mb-3">
              <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
                Check Out*
              </label>
              <input
                readOnly
                type="datetime-local"
                name="checkInDateTime"
                value={formDataCheckOut.checkOutDateTime}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          <Col span={10}>
            <Form.Item>
              <Button style={{ float: "right" }} type="primary" onClick={handleAddCheckOut}>
                {isLoading ? "Please Wait..." : "Submit"}
              </Button>
            </Form.Item>
          </Col>
        </Card>
      )}
    </DashboardLayout>
  );
}

export default EmployeeAttendance;
