import { useState, useEffect, useCallback } from "react";
import { message } from "antd";
import axios from "axios";

function Service() {
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [checkIn, setCheckIn] = useState(false);
  const [checkOut, setCheckout] = useState(false);
  const [history, setHistory] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [checkInTime, setCheckInTime] = useState(null);
  const [attendanceRecords, setAttendanceRecords] = useState([]);
  const [checkInId, setCheckInId] = useState(null);
  const [attendanceSummary, setAttendanceSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const getLocalDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // months are 0-indexed
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const [formDataCheckOut, setFormDataCheckOut] = useState({
    checkOutDateTime: getLocalDateTime(),
    email: "",
    checkInId: null,
  });

  const [formData, setFormData] = useState({
    checkInDateTime: getLocalDateTime(),
    email: "",
    status: "Present",
    firstName: "",
    lastName: "",
    personalId: "",
    // department: "",
  });

  useEffect(() => {
    // Fetch data only when apiUrl changes
    const fetchData = async () => {
      try {
        await fetchAttendanceData();
        await fetchAttendanceRecords();
        await fetchEmployees();
        await fetchAttendanceSummary();
        await fetchDepartments();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // Set form data for check-in/check-out
    const loginEmail = localStorage.getItem("loginEmail");
    if (loginEmail) {
      setFormData((prevState) => ({
        ...prevState,
        email: loginEmail,
      }));
      setFormDataCheckOut((prevState) => ({
        ...prevState,
        email: loginEmail,
      }));
    }

    // If checkInId exists, set the checkInId in formDataCheckOut
    if (checkInId !== null) {
      setFormDataCheckOut((prevState) => ({
        ...prevState,
        checkInId: checkInId,
      }));
    }
  }, [apiUrl, checkInId]); // Add dependencies here

  const fetchAttendanceData = async () => {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      const response = await axios.get(
        `${apiUrl}/api/AttendanceReport/GetAttendanceByEmail?email=${loginEmail}`
      );

      // Ensure the correct property name is used to access the data
      setAttendanceData(response.data.attendanceRecords);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  function formatDateTime(dateTimeString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return new Date(dateTimeString).toLocaleString("en-US", options);
  }

  const handleCheckIn = () => {
    const checkInDateTime = getLocalDateTime();
    setCheckIn(true);
    setFormData({
      ...formData,
      checkInDateTime,
      status: "Present",
    });
    setCheckInTime(checkInDateTime); // Update check-in time
    setHistory(false);
  };

  const hide = () => {
    setCheckIn(false);
  };

  const CheckOuthide = () => {
    setCheckout(false);
  };

  const handleHistory = async () => {
    setIsLoadingPage(true); // Show full page loader

    try {
      const loginEmail = localStorage.getItem("loginEmail");
      const response = await axios.get(
        `${apiUrl}/api/AttendanceReport/GetAttendanceByEmail?email=${loginEmail}`
      );

      // Ensure the correct property name is used to access the data
      setAttendanceData(response.data.attendanceRecords);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    } finally {
      setIsLoadingPage(false); // Hide full page loader
    }

    setHistory(true); // Show history component
    setCheckIn(false);
  };

  const handleCheckOut = () => {
    const now = new Date();
    const checkInDate = new Date(checkInTime);
    if (now - checkInDate < 60 * 1000) {
      message.error("Check Out time is not valid. Please wait for one minute after Check In.");
      return;
    }
    setCheckout(true);
    setFormDataCheckOut({
      ...formDataCheckOut,
      checkOutDateTime: getLocalDateTime(),
    });
    setHistory(false);
    setCheckIn(false);
  };

  const historyhide = () => {
    setHistory(false);
  };

  async function fetchEmployees() {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        const response = await axios.get(
          `${apiUrl}/api/Job/GetEmployee-JobDataby-Email/${loginEmail}`
        );
        const responseData = response.data;
        const { firstName, lastName, personalId } = responseData.personalInformation;
        // console.warn("Fetched Employee Data:", { firstName, lastName, personalId });
        setFormData((prevData) => ({
          ...prevData,
          firstName,
          lastName,
          personalId,
        }));
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const fetchDepartments = async () => {
    try {
      const email = localStorage.getItem("loginEmail");
      const response = await axios.get(
        `${apiUrl}/api/ExpenseManagement/GetEmployee-department/${email}`
      );

      // Ensure response.data.getemployee is not empty and access the department field
      if (response.data.getemployee.length > 0) {
        const departmentName = response.data.getemployee[0].hiringDepartment;
        setFormData((prevFormData) => ({
          ...prevFormData,
          department: departmentName,
        }));
      } else {
        console.error("No department data found for the logged-in user.");
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const handleChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    // Update formData state
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear the error message if the value is not empty
    if (value.trim() !== "") {
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } else {
      // Set the error message if the value is empty
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: `${name} is required.`,
      }));
    }
  };

  const handleAddCheckIn = async () => {
    try {
      setIsLoading(true);
      const updatedFormData = { ...formData };
      const response = await axios.post(
        `${apiUrl}/api/AttendanceReport/AddCheckIn`,
        updatedFormData
      );
      message.success("Check In successfully");
      setCheckIn(false);
      setHistory(false);
      fetchAttendanceSummary();
      fetchAttendanceRecords();
    } catch (error) {
      console.error("Error Check In:", error);
      message.error("Failed to Check In");
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "Check In Time",
      dataIndex: "checkInDateTime",
      key: "checkInDateTime",
      render: (text) => formatDateTime(text),
    },
    {
      title: "Check Out Time",
      dataIndex: "checkOutDateTime",
      key: "checkOutDateTime",
      render: (text) => (text === "Not checked out" ? text : formatDateTime(text)),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Worked Duration",
      dataIndex: "workedDuration",
      key: "workedDuration",
    },
  ];

  const fetchAttendanceSummary = useCallback(async () => {
    try {
      // Retrieve email from local storage
      const email = localStorage.getItem("loginEmail");

      if (!email) {
        throw new Error("Email not found in local storage.");
      }

      const response = await axios.get(
        `${apiUrl}/api/AttendanceReport/GetAttendanceSummaryByEmail?email=${encodeURIComponent(
          email
        )}`
      );

      // Update attendanceSummary state
      setAttendanceSummary(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching attendance summary:", error);
    }
  }, [apiUrl]);

  // Define data for the pie chart
  const chartData = [
    ["Status", "Count"],
    ["Present", attendanceSummary ? attendanceSummary.presentCount : 0],
    ["Late Arrival", attendanceSummary ? attendanceSummary.lateArrivalCount : 0],
    ["Absent", attendanceSummary ? attendanceSummary.absentCount : 0],
  ];

  const fetchAttendanceRecords = async () => {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      const response = await axios.get(
        `${apiUrl}/api/AttendanceReport/GetAttendanceByEmail?email=${loginEmail}&_=${new Date().getTime()}`
      );

      if (response.data && response.data.attendanceRecords) {
        // Sort records by checkInDateTime to get the latest record first
        const sortedRecords = response.data.attendanceRecords.sort(
          (a, b) => new Date(b.checkInDateTime) - new Date(a.checkInDateTime)
        );

        const records = sortedRecords.map((record) => ({
          checkInId: record.checkInId,
          checkInStatus: record.checkInStatus,
          ...record,
        }));

        setAttendanceRecords(records);

        // Set checkInId from the latest record if available
        if (records.length > 0) {
          setCheckInId(records[0].checkInId);
        } else {
          setCheckInId(null); // No records found, set checkInId to null or handle accordingly
        }
      } else {
        console.error("No attendance records found in the response.");
        // Handle no records found scenario
      }
    } catch (error) {
      console.error("Error fetching attendance records:", error);
      // Handle error if needed
    }
  };

  const handleAddCheckOut = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      // Ensure checkInId is defined and valid before making the POST request
      if (formDataCheckOut.checkInId !== null) {
        const response = await axios.post(
          `${apiUrl}/api/AttendanceReport/AddCheckOut?checkInId=${formDataCheckOut.checkInId}`,
          formDataCheckOut // Ensure formDataCheckOut is correctly defined
        );
        message.success("Check Out successfully");
        setCheckout(false);
        setHistory(false);
        fetchAttendanceSummary();
        fetchAttendanceRecords();
      } else {
        console.error("No valid checkInId available.");
        message.error("Failed to Check Out. Invalid checkInId.");
      }
    } catch (error) {
      console.error("Error Check Out:", error);
      message.error("Failed to Check Out");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    checkIn,
    checkOut,
    history,
    attendanceData,
    isLoadingPage,
    attendanceRecords,
    handleCheckIn,
    hide,
    CheckOuthide,
    handleHistory,
    handleCheckOut,
    historyhide,
    handleChange,
    handleAddCheckIn,
    columns,
    loading,
    chartData,
    handleAddCheckOut,
    attendanceSummary,
    formData,
    formDataCheckOut,
  };
}
export default Service;
