import React, { useState, useEffect } from "react";
import { Button } from "antd";
import "./OrganizationHierarchy.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import CustomerSupport from "assets/images/OrganizationHierarchy/customerSuccess.png";
import HR from "assets/images/OrganizationHierarchy/HumanResource.png";
import IT from "assets/images/OrganizationHierarchy/IT.png";
import Marketing from "assets/images/OrganizationHierarchy/maketing.png";
import Operations from "assets/images/OrganizationHierarchy/operations.png";
import Product from "assets/images/OrganizationHierarchy/product.png";
import Sales from "assets/images/OrganizationHierarchy/sales.png";
import payrollimg from "assets/images/payroll.png";

function Service() {
  const navigate = useNavigate();
  const [postingDetail, setPostingDetail] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [buttonText, setButtonText] = useState("Back To Report");
  const [employeesData, setEmployeesData] = useState([]);
  const [showEmployeeTable, setShowEmployeeTable] = useState(false);
  const [childEmployees, setChildEmployees] = useState({});
  const [loading, setLoading] = useState(false);
  const [expandedManager, setExpandedManager] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/HiringProcess/Getposting-Detail`);
        setPostingDetail(response.data.postingDetail);
      } catch (error) {
        console.error("Error fetching posting detail:", error);
      }
    };

    fetchData();
  }, []);

  const handleBack = () => {
    if (showEmployeeTable) {
      setShowEmployeeTable(false);
      setShowTable(true);
    } else if (showTable) {
      setShowTable(false);
      setButtonText("Back To Report");
      setSelectedDepartment(null);
    } else {
      navigate("/report");
    }
  };

  const departmentClickHandler = (department) => {
    setSelectedDepartment(department);
    setShowTable(true);
    setButtonText("Back");
  };

  const departments = [
    { name: "Customer Success", image: CustomerSupport },
    { name: "Human Resources", image: HR },
    { name: "IT", image: IT },
    { name: "Finance", image: payrollimg },
    { name: "Marketing", image: Marketing },
    { name: "Operations", image: Operations },
    { name: "Product", image: Product },
    { name: "Sales", image: Sales },
  ];

  const filteredData = postingDetail.filter((job) => job.department === selectedDepartment);

  const columns = [
    {
      title: "Job Title",
      dataIndex: "postingJobTitle",
      key: "postingJobTitle",
    },
    {
      title: "Recruiter",
      dataIndex: "recruiter",
      key: "recruiter",
    },
    {
      title: "Hiring Lead",
      dataIndex: "hiringLead",
      key: "hiringLead",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => showJobTitleEmployees(record.postingJobTitle, record.department)}
        >
          <i className="fas fa-eye" /> View Employees
        </Button>
      ),
    },
  ];

  const showJobTitleEmployees = async (jobTitle, department) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/PersonalInformation/Get-PersonalInfo-By-PostingTitle/${jobTitle}`
      );
      setEmployeesData(response.data.map((employee) => ({ ...employee, department })));
      setShowTable(false);
      setShowEmployeeTable(true);
    } catch (error) {
      console.error("Error fetching job title employees:", error);
    }
  };

  const fetchChildEmployees = async (managerName) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiUrl}/api/Job/GetEmployees-JobDataby-Manager/${managerName}`
      );
      setChildEmployees((prev) => ({ ...prev, [managerName]: response.data }));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching child employees:", error);
      setLoading(false);
    }
  };

  const handleEmployeeClick = (employee) => {
    const managerName = `${employee.firstName} ${employee.lastName}`;
    if (expandedManager === managerName) {
      setExpandedManager(null);
    } else {
      setExpandedManager(managerName);
      if (!childEmployees[managerName]) {
        fetchChildEmployees(managerName);
      }
    }
  };

  const handleChildEmployeeClick = (employee) => {
    const managerName = `${employee.personalInformation.firstName} ${employee.personalInformation.lastName}`;
    if (expandedManager === managerName) {
      setExpandedManager(null);
    } else {
      setExpandedManager(managerName);
      if (!childEmployees[managerName]) {
        fetchChildEmployees(managerName);
      }
    }
  };

  const renderEmployees = (employees, displayedEmployees = new Set(), isChild = false) => {
    return (
      <div className="employee-names-box">
        {employees.map((employee, index) => {
          const employeeName = isChild
            ? `${employee.personalInformation.firstName} ${employee.personalInformation.lastName}`
            : `${employee.firstName} ${employee.lastName}`;

          if (displayedEmployees.has(employeeName)) {
            return null; // Skip rendering this employee if already displayed
          }

          displayedEmployees.add(employeeName); // Mark this employee as displayed

          return (
            <div className="employee-container" key={index}>
              <span
                className="employee-name"
                onClick={() =>
                  isChild ? handleChildEmployeeClick(employee) : handleEmployeeClick(employee)
                }
              >
                {employeeName}
              </span>
              {expandedManager === employeeName && childEmployees[employeeName] && (
                <div className="child-employees">
                  {renderEmployees(childEmployees[employeeName], displayedEmployees, true)}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderJobTitles = (employees) => {
    const jobTitles = employees.reduce((acc, employee) => {
      const jobTitle = employee.employeeJobApplication.forJobApply;
      if (!acc[jobTitle]) {
        acc[jobTitle] = { employees: [], department: employee.department };
      }
      acc[jobTitle].employees.push(employee);
      return acc;
    }, {});

    if (Object.keys(jobTitles).length === 0) {
      return (
        <div className="no-data">
          <span style={{ fontSize: "medium" }}>No Data Available</span>
        </div>
      );
    }

    return Object.keys(jobTitles).map((jobTitle, index) => (
      <div key={index} className="job-title-box">
        <div className="job-title">{`${jobTitle} - ${jobTitles[jobTitle].department}`}</div>
        <div className="employee-names">
          {renderEmployees(jobTitles[jobTitle].employees, new Set())}
        </div>
      </div>
    ));
  };

  return {
    renderJobTitles,
    columns,
    filteredData,
    departmentClickHandler,
    handleBack,
    employeesData,
    buttonText,
    loading,
    departments,
    showTable,
    showEmployeeTable,
  };
}

export default Service;
