import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { message } from "antd";

function RequesttimeOffService() {
  const [isLoading, setIsLoading] = useState(false);
  const [policies, setPolicy] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    from: "",
    to: "",
    email: "",
    amountDays: "",
    timeOffCategory: "",
    Notes: "",
    Status: 0,
    employeeFirstName: "",
    employeeLastName: "",
    department:"",
    grantedDays:"",
    availableDays:""
  });

  useEffect(() => {
    async function fetchPolicy() {
      try {
        const response = await axios.get(`${apiUrl}/api/LeaveAddPolicy/Get-Leave-Policy`);
        setPolicy(response.data);
      } catch (error) {
        console.error("Error fetching leavePolicy:", error);
      }
    }

    fetchPolicy();
  }, []);

  useEffect(() => {
    // Retrieve email from local storage
    const email = localStorage.getItem("loginEmail");
    console.warn("Retrieved email:", email);
    setFormData((prevFormData) => ({
      ...prevFormData,
      email: email || "",
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Clear the error message if the value is not empty
    if (value.trim() !== "") {
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } else {
      // Set the error message if the value is empty
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: `${name} is required.`,
      }));
    }
  
    // Update the form data with the new value
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  
    // Calculate the number of days whenever "from" or "to" is changed
    if (name === "from" || name === "to") {
      // Parse the date strings to Date objects
      const fromDate = new Date(name === "from" ? value : formData.from);
      const toDate = new Date(name === "to" ? value : formData.to);
  
      // Check if both "from" and "to" dates are valid
      if (!isNaN(fromDate.getTime()) && !isNaN(toDate.getTime())) {
        // Validate if "to" date is before "from" date
        if (toDate < fromDate) {
          setFormDataError((prevState) => ({
            ...prevState,
            toError: "To date cannot be before start date.",
          }));
        } else {
          setFormDataError((prevState) => ({
            ...prevState,
            toError: "",
          }));
  
          // Calculate the difference in milliseconds
          const timeDifference = toDate.getTime() - fromDate.getTime();
          // Convert milliseconds to days and add 1 to include the last day
          const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1;
          // Update the amountDays field
          setFormData((prevFormData) => ({
            ...prevFormData,
            amountDays: daysDifference,
          }));
        }
      }
    }
  };
  
  useEffect(() => {
    if (formData.timeOffCategory) {
      const selectedPolicy = policies.find(
        (policy) => policy.policyName === formData.timeOffCategory
      );
      if (selectedPolicy) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          grantedDays: selectedPolicy.grantedDays,
          availableDays: selectedPolicy.grantedDays,
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          grantedDays: "",
          availableDays: "",
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        grantedDays: "",
        availableDays: "",
      }));
    }
  }, [formData.timeOffCategory, policies]);

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (formData.amountDays > formData.grantedDays) {
        message.error("Your requested amount of days is more than the granted days. Please select less or equal to granted days.");
        return;
      }
      const isValid = validateValidation(formData, setFormDataError);
      if (isValid) {
        try {
          setIsLoading(true);
          const updatedFormData = { ...formData };
          const response = await axios.post(
            `${apiUrl}/api/LeaveAddPolicy/Add-Request-Time`,
            updatedFormData
          );
          clearFields();
          toast.success("Request Send successfully");
          fetchData();
          setShow(false);
          return true;
        } catch (error) {
          console.error("Failed to submit Requested. Please try again", error);
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data);
          } else {
            toast.error("Failed to submit Request. Please try again.");
          }
        } finally {
          setIsLoading(false);
        }
      }
    }; 

  const [formDataError, setFormDataError] = useState({
    fromError: "",
    toError: "",
    emailError: "",
    amountDaysError: "",
    timeOffCategoryError: "",
    NotesError: "",
  });

  const clearFields = () => {
    // Reset the form data to initial values
    setFormData({
      from: "",
      to: "",
      email: "",
      amountDays: "",
      timeOffCategory: "",
      Notes: "",
    });
  };

  function validateValidation(formData, setFormDataError) {
    let hasError = false;

    // Check each field for emptiness or invalidity
    Object.keys(formData).forEach((fieldName) => {
      if (
        (typeof formData[fieldName] === "string" &&
          (!formData[fieldName] || !formData[fieldName].trim())) || // For string fields
        (typeof formData[fieldName] === "number" &&
          (isNaN(formData[fieldName]) || formData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      } else {
        // Clear the error message if the field is not empty
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: "", // Clear the error message
        }));
      }
    });

    // Check if any error occurred
    if (hasError) {
      return false;
    }

    // Check if all fields are filled out
    const isAllFieldsFilled = Object.values(formData).every(
      (value) => value !== null && value !== undefined && value !== ""
    );

    if (!isAllFieldsFilled) {
      // If any field is empty, display an error message
      setFormDataError((prevState) => ({
        ...prevState,
        genericError: "Please fill out all fields.",
      }));
      return false;
    }

    return true;
  }

  const handleShow = () => {
    setShow(true);
  };
  const handleCancel = () => {
    setShow(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const [dataSource, setDataSource] = useState([]);
  async function fetchData() {
    try {
      // Retrieve email from local storage
      const email = localStorage.getItem("loginEmail");
      console.warn("Retrieved email:", email);

      // Fetch data for the specific email
      const response = await axios.get(
        `${apiUrl}/api/LeaveAddPolicy/Get-request-by-email/${email}`
      );
      const responseData = response.data;

      // Update dataSource state with responseData
      setDataSource(responseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const [employeeDetails, setEmployeeDetails] = useState([]);
  useEffect(() => {
    fetchEmployeesByEmail();
  }, []);

  async function fetchEmployeesByEmail() {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        const response = await axios.get(
          `${apiUrl}/api/PersonalInformation/GetEmployeeby-Email/${loginEmail}`
        );
        const responseData = response.data;

        if (Array.isArray(responseData)) {
          setEmployeeDetails(responseData);

          // Assuming you have only one employee for the given email
          if (responseData.length > 0) {
            const { firstName, lastName, department } = responseData[0];
            // Update formData with employee details
            setFormData((prevFormData) => ({
              ...prevFormData,
              employeeFirstName: firstName,
              employeeLastName: lastName,
              department: department,
            }));
          }
        } else {
          setEmployeeDetails([responseData]);
          const { firstName, lastName,department } = responseData;
          // Update formData with employee details
          setFormData((prevFormData) => ({
            ...prevFormData,
            employeeFirstName: firstName,
            employeeLastName: lastName,
            department: department,
          }));
        }
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return {
    dataSource,
    show,
    handleShow,
    handleCancel,
    formData,
    setFormData,
    formDataError,
    setFormDataError,
    handleChange,
    clearFields,
    validateValidation,
    isLoading,
    handleSubmit,
    policies,
    employeeDetails
  };
}

export default RequesttimeOffService;
