import { Button, Card } from "antd";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

function EmployeeComplaintDetail() {
  const { email } = useParams();
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;
  const [complaintDetail, setComplaintDetail] = useState(null);

  useEffect(() => {
    async function fetchComplaintDetail() {
      try {
        const response = await axios.get(`${apiUrl}/api/Complaint/Get-Complaints/${email}`);
        setComplaintDetail(response.data);
      } catch (error) {
        console.error("Error fetching employee detail:", error);
      }
    }

    fetchComplaintDetail();
  }, [email]);

  const getStatusString = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Resolved";
      // case 2:
      //   return "Resolved";
      case 2:
        return "Rejected";
      default:
        return "Unknown";
    }
  };

  function formatDateTime(dateTimeString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateTimeString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = date.getMinutes();
    return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
  }
  const back = () => {
    navigate("/employeedashboard/complaints");
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <br></br>
        <br></br>
        <div className="body flex-grow-1 px-3">
          <div className="row">
            <Card
              className="card-professional"
              title="Complaint Details"
              extra={
                <Button type="default" onClick={back}>
                  Back
                </Button>
              }
            >
              {complaintDetail && (
                <>
                  <div className="complaint-card mb-2">
                    <h6 className="h6-complaint">First Name </h6>
                    <span className="h6-complaint">
                      {complaintDetail.complaintInfo.firstName}
                    </span>
                  </div>
                  <div className="complaint-card mb-2">
                    <h6 className="h6-complaint">Last Name </h6>
                    <span className="h6-complaint">
                      {complaintDetail.complaintInfo.lastName}
                    </span>
                  </div>
                  <div className="complaint-card mb-2">
                    <h6 className="h6-complaint"> Email </h6>
                    <span className="h6-complaint">{complaintDetail.complaintInfo.email}</span>
                  </div>
                  <div className="complaint-card mb-2">
                    <h6 className="h6-complaint">Phone No </h6>
                    <span className="h6-complaint">
                      {complaintDetail.complaintInfo.phoneNo}
                    </span>
                  </div>
                  <div className="complaint-card mb-2">
                    <h6 className="h6-complaint">Complaint Name </h6>
                    <span className="h6-complaint">
                      {complaintDetail.complaintInfo.complaintName}
                    </span>
                  </div>
                  <div className="complaint-card mb-2">
                    <h6 className="h6-complaint">Complaint Against</h6>
                    <span className="h6-complaint">
                      {complaintDetail.complaintInfo.complaintAgainst}
                    </span>
                  </div>
                  <div className="complaint-card mb-2">
                    <h6 className="h6-complaint">Status</h6>
                    <span className="h6-complaint">
                      {" "}
                      {getStatusString(complaintDetail.complaintInfo.status)}
                    </span>
                  </div>
                  <div className="complaint-card mb-2">
                    <h6 className="h6-complaint">Requested Date</h6>
                    <span className="h6-complaint">
                      {formatDateTime(complaintDetail.complaintInfo.complaintDate)}
                    </span>
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EmployeeComplaintDetail;
