import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const CorrectiveActionContext = createContext();

export const CorrectiveActionProvider = ({ children }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <CorrectiveActionContext.Provider value={{ isSubmitted, setIsSubmitted }}>
      {children}
    </CorrectiveActionContext.Provider>
  );
};

CorrectiveActionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
