import axios from "axios";

const API_BASE_URL = "https://soft.recipe4foodies.com/api/Finance";

export const authenticateUser = async () => {
  const response = await axios.post("https://soft.recipe4foodies.com/api/Account/Login", {
    email: "humanresources@gmail.com",
    password: "password",
  });
  return response.data.token;
};

export const fetchEmployees = async (month, year, token) => {
  const response = await axios.get(
    `${API_BASE_URL}/Employees-List?month=${month}&year=${year}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.employees;
};

export const markAsPaid = async (employeeId, month, year, token) => {
  await axios.put(
    `${API_BASE_URL}/Mark-As-Paid?employeeId=${employeeId}&month=${month}&year=${year}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const downloadReport = async (employeeId, month, year, token) => {
  const response = await axios.get(
    `${API_BASE_URL}/Report?employeeId=${employeeId}&month=${month}&year=${year}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const downloadMultipleReports = async (month, year, token) => {
  const response = await axios.get(
    `${API_BASE_URL}/Employees-Report?month=${month}&year=${year}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
