import { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/team-3.jpg";
import axios from "axios";

function ProfileCard() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  useEffect(() => {
    fetchEmployeesByEmail();
  }, []);
  const [employeeDetails, setEmployeeDetails] = useState([]);

  async function fetchEmployeesByEmail() {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        const response = await axios.get(
          `${apiUrl}/api/Job/GetEmployee-JobDataby-Email/${loginEmail}`
        );
        const responseData = response.data;

        if (Array.isArray(responseData)) {
          setEmployeeDetails(responseData);
        } else {
          setEmployeeDetails([responseData]);
        }
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <SoftBox position="relative">
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          //   mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SoftAvatar
              src={burceMars}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            {employeeDetails.map((employee) => (
              <>
                <SoftBox height="100%" mt={0.5} lineHeight={1}>
                  <SoftTypography variant="h5" fontWeight="medium">
                    {employee.manager}
                  </SoftTypography>
                  <SoftTypography variant="button" color="text" fontWeight="medium">
                    {/* CEO / Co-Founder of 1Journey/Datagonomix */}
                    {/* {employee.forJobApply} */}
                  </SoftTypography>
                </SoftBox>
              </>
            ))}
          </Grid>
        </Grid>
      </Card>
    </SoftBox>
  );
}

export default ProfileCard;
