import { useState, useEffect } from "react";
import axios from "axios";

function Service() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [datahardwareSource, setDatahardwareSource] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    fetchData();
    fetchHardwareData();
  }, []);

  async function fetchData() {
    try {
      const email = localStorage.getItem("loginEmail");
      if (!email) {
        console.error("No email found in local storage.");
        return;
      }

      // Fetch hardware assets by email
      const response = await axios.get(`${apiUrl}/api/Assets/Get-Software-Assets-By-Email`, {
        params: {
          email: email,
        },
      });
      const softwareassets = response.data; // Directly accessing the array
      const updatedRows = softwareassets.map((info) => {
        function formatDateTime(dateTimeString) {
          const options = {
            year: "numeric",
            month: "long",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          };

          return new Date(dateTimeString).toLocaleString("en-US", options);
        }

        return {
          key: info.softwareAssetsId.toString(),
          Category: `${info.category}`,
          Description: `${info.description}`,
          Provider: info.provider,
          Licensekey: info.licenseKey,
          SubscriptionDate: <span>{formatDateTime(info.subscriptionDate)}</span>,
          ExpiryDate: <span>{formatDateTime(info.expiryDate)}</span>,
          AssetsCost: info.assetsCost,
          Notes: info.notes,
        };
      });

      // Update dataSource state
      setDataSource(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function fetchHardwareData() {
    try {
      // Retrieve email from local storage
      const email = localStorage.getItem("loginEmail");
      if (!email) {
        console.error("No email found in local storage.");
        return;
      }

      // Fetch hardware assets by email
      const response = await axios.get(`${apiUrl}/api/Assets/Get-Hardware-Assets-By-Email`, {
        params: {
          email: email,
        },
      });

      const hardware = response.data;

      // Generate rows based on the fetched data
      const updatedRows = hardware.map((info) => {
        function formatDateTime(dateTimeString) {
          const options = {
            year: "numeric",
            month: "long",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          };

          return new Date(dateTimeString).toLocaleString("en-US", options);
        }

        return {
          key: info.hardwareAssetsId.toString(),
          Category: `${info.category}`,
          Description: `${info.description}`,
          Serial: info.serial,
          DateLoaned: <span>{formatDateTime(info.dateLoaned)}</span>,
          DateReturned: <span>{formatDateTime(info.dateReturned)}</span>,
          AssetsCost: info.assetsCost,
          Notes: info.notes,
        };
      });

      // Update dataSource state
      setDatahardwareSource(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const hardwarecol = [
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Serial",
      dataIndex: "Serial",
      key: "Serial",
    },
    {
      title: "Date Loaned",
      dataIndex: "DateLoaned",
      key: "DateLoaned",
    },
    {
      title: "Date Returned",
      dataIndex: "DateReturned",
      key: "DateReturned",
    },
    {
      title: "Assets Cost",
      dataIndex: "AssetsCost",
      key: "AssetsCost",
    },
    {
      title: "Notes",
      dataIndex: "Notes",
      key: "Notes",
    },
  ];

  const softwarecol = [
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Provider",
      dataIndex: "Provider",
      key: "Provider",
    },
    {
      title: "License key",
      dataIndex: "Licensekey",
      key: "Licensekey",
    },
    {
      title: "Subscription Date",
      dataIndex: "SubscriptionDate",
      key: "SubscriptionDate",
    },
    {
      title: "Expiry Date",
      dataIndex: "ExpiryDate",
      key: "ExpiryDate",
    },
    {
      title: "Assets Cost",
      dataIndex: "AssetsCost",
      key: "AssetsCost",
    },
    {
      title: "Notes",
      dataIndex: "Notes",
      key: "Notes",
    },
  ];
  return { softwarecol, hardwarecol, dataSource, datahardwareSource, toggleAccordion, activeIndex };
}

export default Service;
