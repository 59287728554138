export const updatePersonalInfo = (formData, employeeData) => {
  return {
    type: "UPDATE_PERSONAL_INFO",
    payload: { formData, employeeData },
  };
};

export const updateJobInfo = (data) => {
  return {
    type: "UPDATE_JOB_INFO",
    payload: data,
  };
};

export const updateHardwareInfo = (data) => {
  return {
    type: "UPDATE_HARDWARE_INFO",
    payload: data,
  };
};

export const updateSoftwareInfo = (data) => {
  return {
    type: "UPDATE_SOFTWARE_INFO",
    payload: data,
  };
};

export const updateEmergencyInfo = (data) => {
  return {
    type: "UPDATE_EMERGENCY_INFO",
    payload: data,
  };
};

export const updateBenefitsInfo = (data) => {
  return {
    type: "UPDATE_Benefits_INFO",
    payload: data,
  };
};
export const updateLifeInsuranceInfo = (date) => {
  return {
    type: "UPDATE_LIFE_INSURANCE_INFO",
    payload: date,
  };
};
export const updateDocumentInfo = (data) => {
  return {
    type: "UPDATE_DOCUMENT_INFO",
    payload: data,
  };
};
// -----------------------------

export const updateInitiateInfo = (data) => {
  return {
    type: "UPDATE_INITIATE_INFO",
    payload: data,
  };
};

export const updatePerformanceInfo = (data) => {
  return {
    type: "UPDATE_PERFORMANCE_INFO",
    payload: data,
  };
};

export const updateDynamicInfo = (data) => {
  return {
    type: "UPDATE_DYNAMIC_INFO",
    payload: data,
  };
};

export const updateEnrolledInfo = (data) => {
  return {
    type: "UPDATE_ENROLLED_INFO",
    payload: data,
  };
};
