import Dashboard from "EmployeeDashboard/dashboard";

import Shop from "examples/Icons/Shop";
import Document from "examples/Icons/Document";
import Office from "examples/Icons/Office";
import Personal from "./EmployeeDashboard/Personal/Personal";
import Job from "./EmployeeDashboard/Job/Job"
import Emergency from "./EmployeeDashboard/Emergency/Emergency";
import Documents from "./EmployeeDashboard/Documents/Documents";
import Benefits from "./EmployeeDashboard/Benefits/Benefits";
import Trainings from "./EmployeeDashboard/Training/Training";
import Assets from "./EmployeeDashboard/EmployeeAssets/EmployeeAssets";
import Attendance from "./EmployeeDashboard/EmployeeAttendence/EmployeeAttendence";
import Cube from "examples/Icons/Cube";
import Basket from "examples/Icons/Basket";
import CustomerSupport from "examples/Icons/CustomerSupport";
import Settings from "examples/Icons/Settings";
import Complaints from "./EmployeeDashboard/Complaints/Complaints";
import ExpenseManagement from "EmployeeDashboard/ExpenseManagment/index";
import Onboarding from "EmployeeDashboard/Onboarding/Parentfile";

const routes = [
  {
    type: "collapse", // Use "route" instead of "collapse" for individual routes
    name: "Dashboard",
    key: "Employeedashboard",
    route: "/employeedashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />, // Just reference the component, not JSX
    noCollapse: true,
  },

  { type: "title", title: "Personal Info", key: "personal-Info" },
  {
    type: "collapse",
    name: "Personal",
    key: "personal",
    route: "/employeedashboard/personal",
    icon: <Office size="12px" />,
    component: <Personal />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Job",
    key: "jobemployee",
    route: "/employeedashboard/jobemployee",
    icon: <Cube size="12px" />,
    component: <Job />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Remote Attendance",
    key: "attendance",
    route: "/employeedashboard/attendance",
    icon: <Document size="12px" />,
    component: <Attendance />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Benefits",
  //   key: "benefits",
  //   route: "/employeedashboard/benefits",
  //   icon: <CustomerSupport size="12px" />,
  //   component: <Benefits />,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Training",
    key: "training",
    route: "/employeedashboard/training",
    icon: <Shop size="12px" />,
    component: <Trainings />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Documents",
    key: "documents",
    route: "/employeedashboard/documents",
    icon: <Document size="12px" />,
    component: <Documents />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Emergency",
    key: "emergency",
    route: "/employeedashboard/emergency",
    icon: <Basket size="12px" />,
    component: <Emergency />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Assets",
    key: "assets",
    route: "/employeedashboard/assets",
    icon: <Settings size="12px" />,
    component: <Assets />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Onboarding",
    key: "onboarding",
    route: "/employeedashboard/onboarding",
    icon: <Document size="12px" />,
    component: <Onboarding />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Complaints",
    key: "complaints",
    route: "/employeedashboard/complaints",
    icon: <Document size="12px" />,
    component: <Complaints />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "ExpenseManagement",
    key: "expenseManagement",
    route: "/employeedashboard/expenseManagement",
    icon: <Document size="12px" />,
    component: <ExpenseManagement />,
    noCollapse: true,
  },
];

export default routes;

