import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import { Card, Table } from "antd";
import Service from "./Service";

function EmployeeAssets() {
  const { softwarecol, hardwarecol, dataSource, datahardwareSource, toggleAccordion, activeIndex  } = Service();
  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card title="Assets" className="card-professional">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={`accordion-button${activeIndex === 0 ? "" : " collapsed"}`}
                    type="button"
                    onClick={() => toggleAccordion(0)}
                    aria-expanded={activeIndex === 0 ? "true" : "false"}
                    aria-controls="collapseOne"
                  >
                    Hardware Assets
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className={`accordion-collapse collapse${activeIndex === 0 ? " show" : ""}`}
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="row mb-3">
                      <Table
                        className="table-responsive"
                        bordered
                        dataSource={datahardwareSource}
                        columns={hardwarecol}
                        pagination={{ pageSize: 10 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className={`accordion-button${activeIndex === 2 ? "" : " collapsed"}`}
                    type="button"
                    onClick={() => toggleAccordion(2)}
                    aria-expanded={activeIndex === 2 ? "true" : "false"}
                    aria-controls="collapseThree"
                  >
                    Software Assets
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className={`accordion-collapse collapse${activeIndex === 2 ? " show" : ""}`}
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="row mb-3">
                      <Table
                        className="table-responsive"
                        bordered
                        dataSource={dataSource}
                        columns={softwarecol}
                        pagination={{ pageSize: 10 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EmployeeAssets;
