import React, { useState, useEffect } from "react";
import "./Onboarding.scss";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Card, Table, Button, Tooltip, Badge } from "antd";
import axios from "axios";
import Footer from "layouts/authentication/components/Footer";
import { useNavigate } from "react-router-dom";

const Onboarding = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/EmployeeJobApplication/GetEmployee-Onboarding-Detail`
        );
        setData(response.data.getemployee);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const statusMapping = {
    4: "Pending",
    5: "Interview",
    6: "Contract Sent",
    7: "In Process",
    8: "Done",
  };

  const handleOnboarding = (empId, employeeEmail) => {
    navigate(`/hiringOnboarding/${empId}`, { state: { employeeEmail } });
  };

  const columns = [
    { title: "Employee_ID", dataIndex: "employeeId", key: "employeeId" },
    {
      title: "Name",
      key: "name",
      render: (text, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => (
        <Tooltip title={email}>{email.length > 13 ? `${email.slice(0, 13)}...` : email}</Tooltip>
      ),
    },
    { title: "Gender", dataIndex: "gender", key: "gender" },
    { title: "Job", dataIndex: "forJobApply", key: "forJobApply" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => statusMapping[status] || status,
    },
    {
      title: "Onboard",
      key: "onboard",
      render: (text, record) => {
        const status = statusMapping[record.status];

        if (status === "Done") {
          return <Badge color="green" text="Onboarded" />;
        }

        // Set up button and tooltip for other statuses
        let buttonText = "Onboarding";
        let buttonDisabled = true;
        let tooltipText = "Onboarding is not ready";

        if (status === "In Process") {
          buttonDisabled = false;
          tooltipText = "Employee onboarding in progress";
        }

        return (
          <Tooltip title={tooltipText}>
            <Button
              type="link"
              disabled={buttonDisabled}
              onClick={() => handleOnboarding(record.employeeId, record.email)}
            >
              {buttonText}
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card title="Onboarding Checklist" className="card-professional">
            <div className="row">
              <Table
                className="table-responsive"
                bordered
                columns={columns}
                dataSource={data}
                loading={loading}
              />
            </div>
          </Card>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Onboarding;
