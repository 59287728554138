// api.js
import axios from "axios";
export const apiUrl = process.env.REACT_APP_API_URL;

const EMPLOYEE_API = `${apiUrl}/api/EmployeeJobApplication/GetEmployee-Detail`;
const JOB_POSTINGS_API = `${apiUrl}/api/HiringProcess/Getposting-Detail`;
const DEPARTMENTS_API = `${apiUrl}/api/AttendanceReport/Get-All-Departments`;

// Fetch all employees
export const fetchEmployees = async () => {
  try {
    const response = await axios.get(EMPLOYEE_API);
    return response.data.getemployee;
  } catch (error) {
    console.error("Error fetching employees data:", error);
    throw error;
  }
};

// Fetch all job postings
export const fetchJobPostings = async () => {
  try {
    const response = await axios.get(JOB_POSTINGS_API);
    return response.data.postingDetail;
  } catch (error) {
    console.error("Error fetching job postings data:", error);
    throw error;
  }
};

// Fetch all departments
export const fetchDepartments = async () => {
  try {
    const response = await axios.get(DEPARTMENTS_API);
    return response.data.map((dept) => dept.department);
  } catch (error) {
    console.error("Error fetching departments:", error);
    throw error;
  }
};

