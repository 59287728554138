import React, { useState, useEffect } from "react";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import axios from "axios";
import SoftButton from "components/SoftButton";

function InterviewSchedule() {
  const [employeeInterview, setEmployeeInterview] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  async function fetchEmployeeData() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        const response = await axios.get(
          `${apiUrl}/api/EmployeeInterview/GetInterviewByEmployeeEmail/${userEmail}`
        );
        const responseData = response.data;

        // Update state with the fetched employee data
        setEmployeeInterview(responseData);
      } else {
        throw new Error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
      // setError(error);
    } finally {
      // setLoading(false);
    }
  }

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Interview Invitation</h5>
            </div>
            <div className="card-body">
              {employeeInterview && (
                <div key={employeeInterview.interviewId} className="row">
                  <p style={{ fontSize: "medium" }}>Email: {employeeInterview.email}</p>
                  <div>
                    <input
                      type="radio"
                      id="firstDateTime"
                      name="interviewTime"
                      value={employeeInterview.firstDateTime}
                      style={{ marginRight: "10px" }}
                    />
                    <label htmlFor="firstDateTime" style={{ fontSize: "medium" }}>
                      First Time :: ({employeeInterview.firstDateTime})
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="secondDateTime"
                      name="interviewTime"
                      value={employeeInterview.secondDateTime}
                      style={{ marginRight: "10px" }}
                    />
                    <label htmlFor="secondDateTime" style={{ fontSize: "medium" }}>
                      Second Time :: ({employeeInterview.secondDateTime})
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="thirdDateTime"
                      name="interviewTime"
                      value={employeeInterview.thirdDateTime}
                      style={{ marginRight: "10px" }}
                    />
                    <label htmlFor="thirdDateTime" style={{ fontSize: "medium" }}>
                      Third Time :: ({employeeInterview.thirdDateTime})
                    </label>
                  </div>
                  <div className="col-md-10">
                    <SoftButton
                      variant="outlined"
                      style={{ float: "inline-end" }}
                      color="info"
                      size="small"
                    >
                      Submit
                    </SoftButton>
                    <SoftButton
                      style={{ float: "inline-end", marginRight: "10px" }}
                      variant="outlined"
                      color="dark"
                      size="small"
                      // onClick={handleCancelClick}
                    >
                      Cancel
                    </SoftButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default InterviewSchedule;
