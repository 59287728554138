import SoftButton from "components/SoftButton";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
// import EnrolledTrainingService from "EmployeeDashboard/EmployeeApiService/JobInitiateService/EnrolledTrainingEmployeeService";
import { EnrolledTrainingService } from "EmployeeDashboard/EmployeeApiService/JobInitiateService/EnrolledTrainingEmployeeService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";

export default function EnrolledTrainingEmployee({ selectedEmail }) {
  const {
    enrolledFormData,
    isLoading,
    handleNext,
    handleEnrolledChange,
    trainingDetail,
    errorMessage,
  } = EnrolledTrainingService({
    selectedEmail,
  });

  function formatDateTime(dateTimeString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateTimeString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = date.getMinutes();
    return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
  }

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <ToastContainer />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Recommending Process</h5>
            </div>
            <div className="card-body">
              {trainingDetail && (
                <>
                  <div className="row">
                    <div className="col-md-5 mb-3">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Name of Training *
                        </label>
                        <input
                          type="text"
                          readOnly
                          placeholder="Enter training name here"
                          name="trainingName"
                          value={trainingDetail.trainingInfo.trainingName}
                          onChange={handleEnrolledChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Provider *
                        </label>
                        <input
                          type="text"
                          readOnly
                          placeholder="Enter Provider here"
                          name="provider"
                          value={trainingDetail.trainingInfo.provider}
                          onChange={handleEnrolledChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-5 mb-3">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Mentor *
                        </label>
                        <input
                          type="text"
                          readOnly
                          name="mentor"
                          placeholder="Enter mentor here"
                          value={trainingDetail.trainingInfo.mentor}
                          onChange={handleEnrolledChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Allocated Budget *
                        </label>
                        <input
                          type="text"
                          readOnly
                          name="allocated_budget"
                          placeholder="Enter Allocated Budget here"
                          value={trainingDetail.trainingInfo.allocated_budget}
                          onChange={handleEnrolledChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-5 mb-3">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Start Date *
                        </label>
                        <input
                          type="text"
                          readOnly
                          name="startDate"
                          value={formatDateTime(trainingDetail.trainingInfo.startDate)}
                          onChange={handleEnrolledChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          End Date *
                        </label>
                        <input
                          type="text"
                          readOnly
                          name="endDate"
                          //   value={formData.endDate}
                          value={formatDateTime(trainingDetail.trainingInfo.endDate)}
                          onChange={handleEnrolledChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Provide a rating for the employee`s performance in this training. *
                        </label>
                        <input
                          type="number"
                          name="rating"
                          value={enrolledFormData.rating}
                          onChange={handleEnrolledChange}
                          className={`form-control ${
                            errorMessage ? "is-invalid" : ""
                          }`}
                        />
                        {errorMessage && (
                          <div style={{ color: "red", marginTop: "5px", fontSize: "medium" }}>
                            {errorMessage}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <SoftButton
                        variant="outlined"
                        style={{ float: "inline-end" }}
                        color="info"
                        size="small"
                        onClick={handleNext}
                      >
                        {isLoading ? "Submitting..." : "Submit"}
                        {isLoading && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </SoftButton>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

EnrolledTrainingEmployee.propTypes = {
  selectedEmail: PropTypes.string.isRequired,
};
