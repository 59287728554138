import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

// Fetch all employees' personal information
export const fetchEmployees = async () => {
  try {
    const { data } = await axios.get(`${apiUrl}/api/PersonalInformation/Get-All`);
    return data.personalInfo;
  } catch (error) {
    console.error("Error fetching employees:", error);
    throw error;
  }
};

// Fetch all leave policies
export const fetchLeavePolicies = async () => {
  try {
    const { data } = await axios.get(`${apiUrl}/api/LeaveAddPolicy/Get-Leave-Policy`);
    return data;
  } catch (error) {
    console.error("Error fetching leave policies:", error);
    throw error;
  }
};

// Add a new leave policy
export const addLeavePolicy = async (formData) => {
  try {
    await axios.post(`${apiUrl}/api/LeaveAddPolicy/Add-Leave-Policy`, {
      ...formData,
      enrollments: formData.enrollments,
    });
  } catch (error) {
    console.error("Error adding leave policy:", error);
    throw error;
  }
};

// Fetch user information by email
export const fetchUserNameByEmail = async (email) => {
  try {
    const response = await axios.get(`${apiUrl}/api/Auth/GetUserByEmail/${encodeURIComponent(email)}`);
    if (response.status === 200) {
      return response.data.userName;
    } else {
      console.error("Unexpected status code:", response.status);
      throw new Error("Failed to fetch user name.");
    }
  } catch (error) {
    console.error("Error fetching user by email:", error);
    throw error;
  }
};
