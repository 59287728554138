import React from "react";
import { Form, Input, Button, Card, Row, Col } from "antd";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import APIFunctionService from "./APIFunctionService";

export default function AddPolicyInputFields({ onSubmitSuccess }) {
  const { handleSubmit, formData, formDataError, handleChange, loading, userName } =
  APIFunctionService();
  const navigate = useNavigate();

  const handleFormSubmit = async () => {
    const isSuccess = await handleSubmit();
    console.log("isSuccess", isSuccess);
    if (isSuccess) {
      onSubmitSuccess();
    }
  };

  return (
    <Card title="Time-Off Add Policy" className="card-professional">
      <Form layout="vertical" onFinish={handleFormSubmit}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Policy Name *"
              validateStatus={formDataError.policyNameError ? "error" : ""}
              help={formDataError.policyNameError}
            >
              <Input
                placeholder="Enter Policy name here"
                name="policyName"
                value={formData.policyName}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Granted Days *"
              validateStatus={formDataError.grantedDaysError ? "error" : ""}
              help={formDataError.grantedDaysError}
            >
              <Input
                type="number"
                placeholder="Enter granted days here"
                name="grantedDays"
                value={formData.grantedDays}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Approvals *">
              <Input
                name="approvals"
                value={`${formData.approvals || userName} HR Manager`}
                onChange={handleChange}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={loading}
            loading={loading}
            style={{ marginRight: 10 }}
          >
            {loading ? "Submitting..." : "Submit"}
          </Button>
          <Button
            type="default"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
AddPolicyInputFields.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
};
