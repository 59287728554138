import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

function usePersonalInformationService(onNext) {
  const { empId } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;

  const [formData, setFormData] = useState({
    personalId: 0,
    image: "",
    employeeNumber: "",
    workEmail: "",
  });

  const [employeeData, setEmployeeData] = useState({
    employeeId: "",
    firstName: "",
    lastName: "",
    city: "",
    state: "",
    gender: "",
    maritalStatus: "",
    streetAddress: "",
    postalCode: "",
    country: "",
    telePhone: "",
    mobileno: "",
    email: "",
    workAuthorizationUS: "",
    sponsorshipNeeded: "",
  });

  useEffect(() => {
    fetchEmployeeById(empId);
  }, [empId]);

  async function fetchEmployeeById(empId) {
    try {
      const response = await axios.get(
        `${apiUrl}/api/PersonalInformation/GetEmployeesById/${empId}`
      );
      const responseData = response.data;

      // Check if employeeJobApplications exists and is not empty
      if (responseData.employeeJobApplications && responseData.employeeJobApplications.length > 0) {
        // Assuming you only want data from the first employee
        const firstEmployee = responseData.employeeJobApplications[0];

        const updatedFormData = { ...employeeData };
        if (firstEmployee.firstName && firstEmployee.lastName) {
          updatedFormData.employeeId = firstEmployee.employeeId;
          updatedFormData.firstName = firstEmployee.firstName;
          updatedFormData.lastName = firstEmployee.lastName;
          updatedFormData.gender = firstEmployee.gender;
          updatedFormData.maritalStatus = firstEmployee.maritalStatus;
          updatedFormData.ssn = firstEmployee.ssn;
          updatedFormData.email = firstEmployee.email;
          updatedFormData.mobileno = firstEmployee.mobileno;
          updatedFormData.telePhone = firstEmployee.telePhone;
          updatedFormData.streetAddress = firstEmployee.streetAddress;
          updatedFormData.city = firstEmployee.city;
          updatedFormData.state = firstEmployee.state;
          updatedFormData.postalCode = firstEmployee.postalCode;
          updatedFormData.country = firstEmployee.country;
          updatedFormData.workAuthorizationUS = firstEmployee.workAuthorizationUS;
          updatedFormData.sponsorshipNeeded = firstEmployee.sponsorshipNeeded;
          updatedFormData.tenYearsStrategicSuccess = firstEmployee.tenYearsStrategicSuccess;
        } else {
          console.error("Some required fields are missing in the fetched data.");
        }
        setEmployeeData(updatedFormData);
        // setJobtitle(updatedFormData);
      } else {
        console.error("No employee data found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const [formDataError, setFormDataError] = useState({
    imageError: "",
  });

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFormData((prevData) => ({
        ...prevData,
        image: selectedFile,
      }));
      setFormDataError((prevState) => ({
        ...prevState,
        imageError: "",
      }));
    } else {
      setFormDataError((prevState) => ({
        ...prevState,
        imageError: "Image is required.",
      }));
    }
  };

  const handleChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    if (value.trim() !== "") {
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } else {
      // Set the error message if the value is empty
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: `${name} is required.`,
      }));
    }

    // Clear DOB and startDate errors if a valid date is selected
    setFormDataError({ ...formDataError, [`${name}Error`]: "" });

    // Update state for other fields
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchLastEmployeeNumber = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/PersonalInformation/GetLastEmployeeNumber`);
        if (response.status === 200) {
          const nextEmployeeNumber = response.data; // Assuming this is the new employee number like 1001
          setFormData((prevData) => ({
            ...prevData,
            employeeNumber: nextEmployeeNumber,
          }));
        }
      } catch (error) {
        console.error("Error fetching employee number:", error);
        setFormDataError((prevState) => ({
          ...prevState,
          employeeNumberError: "Error fetching employee number",
        }));
      }
    };

    fetchLastEmployeeNumber();
  }, []);

  const handleemployeeDataChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }
    const { name, value } = e.target;
    // Clear DOB and startDate errors if a valid date is selected
    setFormDataError({ ...formDataError, [`${name}Error`]: "" });
    // Update state for other fields
    setEmployeeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  function validatePersonalValidation(formData, setFormDataError) {
    let hasError = false;

    // Check each field for emptiness or invalidity
    Object.keys(formData).forEach((fieldName) => {
      if (
        (typeof formData[fieldName] === "string" &&
          (!formData[fieldName] || !formData[fieldName].trim())) || // For string fields
        (typeof formData[fieldName] === "number" &&
          (isNaN(formData[fieldName]) || formData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      }
    });

    // Check if any error occurred
    if (hasError) {
      return false;
    }

    // Check if all fields are filled out
    const isAllFieldsFilled = Object.values(formData).every(
      (value) => value !== null && value !== undefined && value !== ""
    );

    if (!isAllFieldsFilled) {
      // If any field is empty, display an error message
      setFormDataError((prevState) => ({
        ...prevState,
        genericError: "Please fill out all fields.",
      }));
      return false;
    }

    return true;
  }

  return {
    formData,
    setFormData,
    formDataError,
    setFormDataError,
    handleChange,
    handleImageChange,
    validatePersonalValidation,
    employeeData,
    handleemployeeDataChange,
  };
}

export default usePersonalInformationService;
