import { useState,useEffect } from "react";
import axios from "axios";
import { message } from "antd";
import jsPDF from "jspdf";
import "jspdf-autotable";

function WorkingHoursService(currentMonth, currentYear) {
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [showDefinePolicy, setShowDefinePolicy] = useState(true);

  const [checkInData, setCheckInData] = useState([]);
  const [aggregatedData, setAggregatedData] = useState([]);
  const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);
  const [showFields, setShowFields] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("All Departments");

  const [formData, setFormData] = useState({
    workingHoursFrom: "",
    workingHoursTo: "",
    presentTime: "",
    lateArrivalTime: "",
  });

  const [formDataError, setFormDataError] = useState({
    workingHoursFromError: "",
    workingHoursToError: "",
    presentTimeError: "",
    lateArrivalTimeError: "",
    genericError: "",
  });

  const handleChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    // Update formData state
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear error if value is not empty
    setFormDataError((prevState) => ({
      ...prevState,
      [`${name}Error`]: value.trim() !== "" ? "" : `${name} is required.`,
    }));
  };

  const validateForm = () => {
    let hasError = false;
    setFormDataError((prevState) => ({ ...prevState, genericError: "" }));

    // Validate each field
    Object.keys(formData).forEach((fieldName) => {
      if (!formData[fieldName]?.trim()) {
        const formattedFieldName = formatFieldName(fieldName);
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${formattedFieldName} is required.`,
        }));
        hasError = true;
      }
    });

    // Check if any error occurred
    return !hasError;
  };

  const formatFieldName = (fieldName) => {
    return fieldName.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
  };

  const clearFields = () => {
    setFormData({
      workingHoursFrom: "",
      workingHoursTo: "",
      presentTime: "",
      lateArrivalTime: "",
    });
  };

  const handleAddWorkingHours = async () => {
    if (validateForm()) {
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${apiUrl}/api/AttendanceReport/AddWorking-Hours`,
          formData
        );
        message.success("Working Hours added successfully");
        clearFields();
        setShowDefinePolicy(true);
      } catch (error) {
        console.error("Error adding Working Hours:", error);
        message.error("Failed to add Working Hours");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDefinePolicy = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/AttendanceReport/GetLatestWorkingHours`);
      const latestWorkingHours = response.data;

      if (latestWorkingHours) {
        setFormData({
          workingHoursFrom: latestWorkingHours.workingHoursFrom || "",
          workingHoursTo: latestWorkingHours.workingHoursTo || "",
          presentTime: latestWorkingHours.presentTime || "",
          lateArrivalTime: latestWorkingHours.lateArrivalTime || "",
        });
        setShowDefinePolicy(false);
      } else {
        setShowDefinePolicy(false);
      }
    } catch (error) {
      console.error("Error fetching latest working hours:", error);
      setShowDefinePolicy(false);
    }
  };

  // -----------------------------

  const handleViewMonthlyReport = () => {
    setShowFields(false);
  };

  useEffect(() => {
    fetchCheckInData();
  }, []);

  useEffect(() => {
    if (checkInData.length > 0) {
      aggregateCheckInData();
    }
  }, [checkInData, currentMonth, currentYear, selectedDepartment]);

  const fetchCheckInData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/AttendanceReport/getAllCheckIn`); // Update the URL as per your backend endpoint
      setCheckInData(response.data);
    } catch (error) {
      message.error("Failed to fetch check-in data");
    }
  };

  const aggregateCheckInData = () => {
    const employeeMap = {};
    const monthIndex = new Date(`${currentMonth} 1, ${currentYear}`).getMonth();

    checkInData.forEach((checkIn) => {
      const { personalId, firstName, lastName, department, checkInDateTime, status } = checkIn;
      const checkInDate = new Date(checkInDateTime);

      if (
        checkInDate.getFullYear() === parseInt(currentYear, 10) &&
        checkInDate.getMonth() === monthIndex &&
        (selectedDepartment === "All Departments" || department === selectedDepartment)
      ) {
        if (!employeeMap[personalId]) {
          employeeMap[personalId] = {
            key: personalId,
            personalId,
            firstName,
            lastName,
            department,
            checkInDateTime,
            Present: 0,
            LateArrival: 0,
            Absent: 0,
          };
        }

        if (status === "Present") {
          employeeMap[personalId].Present += 1;
        } else if (status === "Late Arrival") {
          employeeMap[personalId].LateArrival += 1;
        } else if (status === "Absent") {
          employeeMap[personalId].Absent += 1;
        }
      }
    });

    setAggregatedData(Object.values(employeeMap));
  };

  const columns = [
    {
      title: "Employee Id",
      dataIndex: "personalId",
      key: "personalId",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Present",
      dataIndex: "Present",
      key: "Present",
    },
    {
      title: "Late Arrival",
      dataIndex: "LateArrival",
      key: "LateArrival",
    },
    {
      title: "Absent",
      dataIndex: "Absent",
      key: "Absent",
    },
  ];

  const aggregateAttendanceByDay = () => {
    const attendanceByDay = {};

    const getDayName = (dateTimeString) => {
      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const date = new Date(dateTimeString);
      const dayIndex = date.getDay();
      return days[dayIndex];
    };

    checkInData.forEach((checkIn) => {
      if (selectedDepartment === "All Departments" || checkIn.department === selectedDepartment) {
        const dayName = getDayName(checkIn.checkInDateTime);

        if (!attendanceByDay[dayName]) {
          attendanceByDay[dayName] = {
            Present: 0,
            LateArrival: 0,
            Absent: 0,
          };
        }

        if (checkIn.status === "Present") {
          attendanceByDay[dayName].Present++;
        } else if (checkIn.status === "Late Arrival") {
          attendanceByDay[dayName].LateArrival++;
        } else if (checkIn.status === "Absent") {
          attendanceByDay[dayName].Absent++;
        }
      }
    });

    return attendanceByDay;
  };

  const attendanceByDay = aggregateAttendanceByDay();

  const barChartData = {
    labels: Object.keys(attendanceByDay),
    datasets: [
      {
        label: "Present",
        backgroundColor: "rgba(84, 132, 13, 1)",
        borderColor: "rgba(84, 132, 13, 1)",
        borderWidth: 1,
        barThickness: 20, // Adjust the thickness here (in pixels)
        data: Object.values(attendanceByDay).map((dayData) => dayData.Present),
      },
      {
        label: "Late Arrival",
        backgroundColor: "rgba(254, 164, 59, 1)",
        borderColor: "rgba(254, 164, 59, 1)",
        borderWidth: 1,
        barThickness: 20, // Adjust the thickness here (in pixels)
        data: Object.values(attendanceByDay).map((dayData) => dayData.LateArrival),
      },
      {
        label: "Absent",
        backgroundColor: "rgba(220, 21, 21, 1)",
        borderColor: "rgba(220, 21, 21, 1)",
        borderWidth: 1,
        barThickness: 20, // Adjust the thickness here (in pixels)
        data: Object.values(attendanceByDay).map((dayData) => dayData.Absent),
      },
    ],
  };

  const maxAttendanceCount = Math.max(
    ...Object.values(attendanceByDay).map((dayData) =>
      Math.max(dayData.Present, dayData.LateArrival, dayData.Absent)
    )
  );

  const options = {
    title: {
      display: true,
      fontSize: 15,
      text: "Attendance by Day",
    },
    legend: {
      display: true,
      position: "top",
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            suggestedMax: maxAttendanceCount,
          },
        },
      ],
    },
  };

  const handleDownloadReport = () => {
    const monthIndex = new Date(`${currentMonth} 1, ${currentYear}`).getMonth();
    const filteredData = checkInData.filter((checkIn) => {
      const checkInDate = new Date(checkIn.checkInDateTime);
      return (
        checkInDate.getFullYear() === parseInt(currentYear, 10) &&
        checkInDate.getMonth() === monthIndex &&
        (selectedDepartment === "All Departments" || checkIn.department === selectedDepartment)
      );
    });

    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("End of Months Report", 14, 22);

    const lineY = 28; // Adjust the Y-coordinate as needed
    const lineWidth = 0.5;
    const lineColor = [192, 192, 192]; // Gray color for the line
    doc.setLineWidth(lineWidth);
    doc.setDrawColor(...lineColor); // Set line color
    doc.line(14, lineY, 200, lineY); // Adjust the X-coordinate and length as needed

    const marginTop = 3;
    const marginTopEOM = 2;
    doc.setFontSize(12);
    doc.text("Employee: All - All employees selected", 14, 32 + marginTop); // Adjust the Y-coordinate as needed

    // Get the current date
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString(); // Formats the date as MM/DD/YYYY

    doc.text(`End of Months: ${formattedDate}`, 14, 42 + marginTopEOM);
    doc.text("Exceptions: No Deletions", 14, 52);
    doc.text(`Department: ${selectedDepartment}`, 14, 59);

    const headers = [
      ["Employee Id", "First Name", "Last Name", "department", "Check-in DateTime", "Status"],
    ];
    const data = filteredData.map((item) => [
      item.personalId,
      item.firstName,
      item.lastName,
      item.department,
      item.checkInDateTime,
      item.status,
    ]);

    doc.autoTable({
      startY: 62,
      head: headers,
      body: data,
      headerStyles: {
        fillColor: [192, 192, 192], // Gray color for header cells
        fontSize: 8, // Reduce font size for header cells
      },
      styles: {
        fontSize: 8, // Reduce font size for table content
      },
    });

    doc.save(`attendance_report_${currentMonth}_${currentYear}.pdf`);
  };

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/AttendanceReport/Get-All-Departments`);
        const departmentNames = response.data.map((dept) => dept.department);
        setDepartments(departmentNames);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, [apiUrl]);

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  return {
    selectedDepartment,
    departments,
    showFields,
    years,
    aggregatedData,
    handleViewMonthlyReport,
    columns,
    options,
    barChartData,
    handleDownloadReport,
    handleDepartmentChange,
    formData,
    formDataError,
    isLoading,
    showDefinePolicy,
    handleChange,
    handleAddWorkingHours,
    handleDefinePolicy,
  };
}

export default WorkingHoursService;
