import React, { useState, useEffect } from "react";
import axios from "axios";
import SoftTypography from "components/SoftTypography";
import { DownloadOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import { FileOpenOutlined } from "@mui/icons-material";
import { Space } from "antd";
import "jspdf-autotable";

function ExpenseTable() {
  const [expenseData, setExpenseData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("All Employees");
  const [departments, setDepartments] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const email = localStorage.getItem("loginEmail");
        const response = await axios.get(`${apiUrl}/api/ExpenseManagement/Get-Departments-By-Email/${email}`);
        setDepartments(response.data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, [apiUrl]);

  useEffect(() => {
    const fetchExpenseData = async () => {
      try {
        const email = localStorage.getItem("loginEmail");
        const endpoint =
          selectedDepartment === "All Employees"
            ? `${apiUrl}/api/ExpenseManagement/Get-Expense-by-Email/${email}`
            : `${apiUrl}/api/ExpenseManagement/Get-ExpenseManagement-By-Department/${selectedDepartment}`;
            const response = await axios.get(endpoint);
            const expenses = Array.isArray(response.data) ? response.data : [];
        setExpenseData(response.data);
      } catch (error) {
        console.error("Error fetching expense data:", error);
        setExpenseData([]);
      }
    };

    fetchExpenseData();
  }, [apiUrl, selectedDepartment]);

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const handleDelete = async (expenseManagementId) => {
    try {
      const confirmed = window.confirm("Are you sure you want to terminate this expense?");
      if (!confirmed) {
        return;
      }
      await axios.delete(
        `${apiUrl}/api/ExpenseManagement/Expense-Termination/${expenseManagementId}`
      );
      alert("Expense successfully terminated.");
      setExpenseData(
        expenseData.filter((expense) => expense.expenseManagementId !== expenseManagementId)
      );
    } catch (error) {
      alert("Failed to terminate Expense.");
    }
  };

  const handleDownload = (expense) => {
    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("Expense Report", 14, 22);

    const lineY = 28; // Adjust the Y-coordinate as needed
    const lineWidth = 0.5;
    const lineColor = [192, 192, 192]; // Gray color for the line
    doc.setLineWidth(lineWidth);
    doc.setDrawColor(...lineColor); // Set line color
    doc.line(14, lineY, 200, lineY); // Adjust the X-coordinate and length as needed

    const marginTop = 4;

    doc.setFontSize(12);
    doc.text(`Manager: ${expense.manager}`, 14, 34 + marginTop);
    doc.text(`Requested Date: ${expense.date}`, 14, 44 + marginTop);
    doc.text(`Description: ${expense.description}`, 14, 54 + marginTop);
    doc.text(`Department: ${expense.department}`, 14, 64 + marginTop);
    doc.text(`Charges: ${expense.charges}`, 14, 74 + marginTop);
    doc.text(`Status: ${expense.status === 0 ? "Pending" : expense.status === 1 ? "Approved" : "Rejected"}`, 14, 84 + marginTop);

    doc.save("expense_report.pdf");
};

const handlePdfPreview = async (fileUrl) => {
    try {
        window.open(fileUrl, "_blank");
    } catch (error) {
        console.error("Error handling file:", error);
    }
};

  const rows = expenseData.map((expense, index) => ({
    RequestedDate: (
      <SoftTypography key={`date_${index}`} variant="caption" color="text" fontWeight="medium">
        {expense.date}
      </SoftTypography>
    ),
    Manager: (
      <SoftTypography key={`manager_${index}`} variant="caption" color="text" fontWeight="medium">
        {expense.manager}
      </SoftTypography>
    ),
    Description: (
      <SoftTypography key={`type_${index}`} variant="caption" color="text" fontWeight="medium">
        {expense.description}
      </SoftTypography>
    ),
    Department: (
      <SoftTypography
        key={`department_${index}`}
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {expense.department}
      </SoftTypography>
    ),
    Charges: (
      <SoftTypography key={`charges_${index}`} variant="caption" color="text" fontWeight="medium">
        {expense.charges}
      </SoftTypography>
    ),
    Receipt: (
      <Space size="middle" key={`receipt_${index}`}>
        <FileOpenOutlined
          onClick={() => handlePdfPreview(`${apiUrl}/${expense.receipt}`)}
          style={{ cursor: "pointer" }}
        />
      </Space>
    ),
    CalculationSheet: (
      <Space size="middle" key={`calculationSheet_${index}`}>
        <FileOpenOutlined
          onClick={() => handlePdfPreview(`${apiUrl}/${expense.calculationSheet}`)}
          style={{ cursor: "pointer" }}
        />
      </Space>
    ),
    Status: (
      <span key={`status_${index}`} style={{ display: "flex", alignItems: "center" }}>
        <SoftTypography variant="caption" color="text" fontWeight="medium">
          {expense.status === 0 ? "Pending" : expense.status === 1 ? "Approved" : "Rejected"}
        </SoftTypography>
        <DownloadOutlined
          className="expense-icon"
          onClick={() => handleDownload(expense)}
          style={{ marginLeft: 8, cursor: "pointer" }}
        />
      </span>
    ),
  }));


  const columns = [
    { title: "Requested Date", name: "RequestedDate", align: "center" },
    { title: "Manager", name: "Manager", align: "center" },
    { title: "Description", name: "Description", align: "center" },
    { title: "Department", name: "Department", align: "center" },
    { title: "Charges", name: "Charges", align: "center" },
    { title: "Receipt", name: "Receipt", align: "center" },
    { title: "Expense Sheet", name: "CalculationSheet", align: "center" },
    { title: "Status", name: "Status", align: "center", },
  ];

  return { columns, rows, departments, selectedDepartment, handleDepartmentChange };
}

export default ExpenseTable;
