import React from "react";
import PropTypes from "prop-types";
import check from "../../../assets/images/checklist.jpg";

const OnboardingTasks = ({ formData, setFormData }) => {
  const handleCheckboxChange = (key, message) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: prevState[key] ? "" : message,
    }));
  };

  return (
    <>
      <label className="mb-3" style={{ textAlign: "left", fontSize: "large", color: "#3dc00f" }}>
        <img src={check} height={"35px"} style={{ marginLeft: "-8px" }} alt="Tasks for HR Administrator" />
        Tasks for HR Administrator
      </label>
      {[
        {
          key: "WelcomeEmail",
          message: "Share the good news! Send a welcome email introducing your new hire.",
        },
        {
          key: "MentorAssignment",
          message: "Assign your new hire a mentor and/or onboarding buddy.",
        },
        {
          key: "RecurringOneOnOneMeetings",
          message:
            "Invite your new employee to recurring one-on-one meetings to provide regular check-ins.",
        },
        {
          key: "FirstDayMeetings",
          message:
            "Schedule your new hire’s first day meetings, prioritizing intro calls with colleagues who will work closely with the new hire.",
        },
        {
          key: "WelcomeMeeting",
          message:
            "Coordinate a welcome meeting or phone call to kick off your new hire’s first day.",
        },
        {
          key: "WelcomeLunch",
          message: "Set up a welcome lunch with the new hire’s team, if appropriate.",
        },
        {
          key: "BusinessIntroduction",
          message:
            "Give the new employee an introduction to the business, including how their role fits in and any performance expectations you’ll have for their onboarding.",
        },
        { key: "OfficeTour", message: "Take your new hire on a tour of the office." },
      ].map(({ key, message }, index) => (
        <div className="form-check OnboardCheckbox" key={index}>
          <input
            className="form-check-input"
            type="checkbox"
            checked={formData[key] !== ""}
            id={`Check${index + 1}`}
            onChange={() => handleCheckboxChange(key, message)}
          />
          <label className="form-check-label" htmlFor={`Check${index + 1}`}>
            {message}
          </label>
        </div>
      ))}
      <label className="mb-3" style={{ textAlign: "left", fontSize: "large", color: "#3dc00f" }}>
        <img src={check} height={"35px"} style={{ marginLeft: "-8px" }} alt="check" />
        Mentor or Onboarding Buddy Checklist
      </label>

      {[
        {
          key: "RegularCheckIns",
          text: "Schedule regular check-ins throughout the first two weeks.",
        },
        {
          key: "ShadowingOpportunities",
          text: "Invite new hires to shadow relevant meetings or projects.",
        },
        { key: "AdHocSupport", text: "Be responsive to ad hoc questions throughout the day!" },
      ].map(({ key, text }, index) => (
        <div className="form-check OnboardCheckbox" key={index}>
          <input
            className="form-check-input"
            type="checkbox"
            checked={formData[key] !== ""}
            id={`Check${index + 18}`}
            onChange={() => handleCheckboxChange(key, text)}
          />
          <label className="form-check-label" htmlFor={`Check${index + 18}`}>
            {text}
          </label>
        </div>
      ))}
      <label className="mb-3" style={{ textAlign: "left", fontSize: "large", color: "#3dc00f" }}>
        <img src={check} height={"35px"} style={{ marginLeft: "-8px" }} alt="check" />
        Required Onboarding Documents for New Hires
      </label>

      {[
        { key: "BenefitsEnrollment", text: "Benefits enrollment paperwork" },
        { key: "EmergencyContactInfo", text: "Emergency contact information" },
        { key: "EmployeeHandbook", text: "Employee handbook featuring all company policies" },
        { key: "DirectDepositInfo", text: "Direct deposit and payroll information" },
        {
          key: "EmploymentEligibilityForm",
          text: "1-9 form (employment eligibility verification)",
        },
        {
          key: "NDAOrNonCompete",
          text: "Non-disclosure agreement, non-compete, or confidentiality agreement (if applicable)",
        },
        { key: "StateTaxWithholdingForm", text: "State tax withholding form (varies by location)" },
        {
          key: "FederalTaxWithholdingForm",
          text: "W-4 form (employee`s withholding certificate for federal taxes) or W-9 for contractors",
        },
      ].map(({ key, text }, index) => (
        <div className="form-check OnboardCheckbox" key={index}>
          <input
            className="form-check-input"
            type="checkbox"
            checked={formData[key] !== ""}
            id={`Check${index + 30}`}
            onChange={() => handleCheckboxChange(key, text)}
          />
          <label className="form-check-label" htmlFor={`Check${index + 30}`}>
            {text}
          </label>
        </div>
      ))}
    </>
  );
};

OnboardingTasks.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  selectAll: PropTypes.bool.isRequired,
};

OnboardingTasks.defaultProps = {
  formData: {},
  setFormData: () => {},
  selectAll: false,
};

export default OnboardingTasks;
