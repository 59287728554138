import React, { useEffect, useState } from "react";
import { Form, message, Modal, Tooltip } from "antd";
import SoftButton from "components/SoftButton";
import { Link } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import {
  getJobPostings,
  addJobPosting,
  updateJobPosting,
  getUserNameByEmail,
  getAllChildManagers,
} from "./HiringService";
import axios from "axios";

function HiringApiFunctions() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [form] = Form.useForm();
  const [jobPostings, setJobPostings] = useState([]);
  const [childManagers, setChildManagers] = useState([]);
  const [recruiter, setRecruiter] = useState("");
  const loginType = localStorage.getItem("loginType");
  const [isCompensationEnabled, setIsCompensationEnabled] = useState(false);
  const [jobDescription, setJobDescription] = useState(
    `We provide equal employment opportunities to all employees and applicants for employment and prohibits discrimination and harassment of any type. 
    Datagonomix considers applicants for employment without regard to race, color, religion, age, sex, national origin, disability status, genetic information, 
    protected veteran status, sexual orientation, gender, gender identity or expression, or any other characteristic protected by federal, state, provincial 
    or local laws.`
  );
  const [showForm, setShowForm] = useState(false);
  const [editingJobId, setEditingJobId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addloading, setAddLoading] = useState(false);

  useEffect(() => {
    fetchJobPostings();
    fetchUserData();
    fetchChildManagers();
  }, [form, loginType]);

  const fetchJobPostings = async () => {
    setLoading(true);
    try {
      const response = await getJobPostings();

      if (response && response.postingDetail && response.postingDetail.length > 0) {
        const updatedRows = response.postingDetail.map((info) => {
          const formattedCreatedOn = info.createdOn
            ? new Date(info.createdOn).toLocaleDateString()
            : "N/A";

          return {
            key: info.postingJobId.toString(),
            PositionName: info.postingJobTitle,
            Department: info.department,
            CreatedOn: formattedCreatedOn,
            Status: info.jobStatus,
            Skills: info.skills,
            ViewCandidate: (
              <SoftButton variant="outlined" color="info" size="small">
                <Link to={`/viewcandidate/${info.postingJobId}`}>View Candidate</Link>
              </SoftButton>
            ),
            Action: (
              <span style={{ display: "flex", alignItems: "center" }}>
                <Edit style={{ cursor: "pointer" }} onClick={() => handleEdit(info)} />
                &nbsp;&nbsp;&nbsp;
                <Delete
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDelete(info.postingJobId)}
                />
              </span>
            ),
          };
        });
        setJobPostings(updatedRows);
      } else {
        console.error("No job postings found in the response.");
      }
    } catch (error) {
      console.error("Error fetching job postings:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserData = async () => {
    const email = localStorage.getItem("hremail");
    try {
      const response = await getUserNameByEmail(email, loginType);
      if (response) {
        setRecruiter(response.userName); // Use the response directly
        form.setFieldsValue({ recruiter: response.userName });
      } else {
        console.warn("No user data found for this email.");
      }
    } catch (error) {
      console.error("Error fetching user name:", error);
    }
  };

  const fetchChildManagers = async () => {
    if (loginType === "HR") {
      try {
        const response = await getAllChildManagers();
        if (Array.isArray(response)) {
          setChildManagers(response);
        } else {
          console.warn("No child managers data found.");
        }
      } catch (error) {
        console.error("Error fetching child managers:", error);
      }
    }
  };

  const handleEdit = (info) => {
    form.setFieldsValue({
      postingJobTitle: info.postingJobTitle,
      budgetApproved: info.budgetApproved,
      jobStatus: info.jobStatus,
      hiringLead: info.hiringLead,
      department: info.department,
      employementType: info.employementType, // Check for correct spelling
      maximumExperience: info.maximumExperience,
      compensation: info.compensation,
      jobLocationType: info.jobLocationType,
      jobType: info.jobType,
      jobDescription: info.jobDescription,
      jobInternalCode: info.jobInternalCode,
      skills: info.skills ? info.skills.split(",").map((skill) => skill.trim()) : [],
    });
    setEditingJobId(info.postingJobId);
    setJobDescription(info.jobDescription);
    setShowForm(true);
  };

  const handleDelete = (postingJobId) => {
    // Display confirmation modal
    Modal.confirm({
      title: "Are you sure to terminate this Position?",
      content: "This action cannot be undone.",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          const response = await axios.delete(
            `${apiUrl}/api/HiringProcess/Post-Termination/${postingJobId}`
          );
          if (response.data === "Job terminated successfully.") {
            message.success("Job terminated successfully.");
            // Remove the deleted job from the state
            setJobPostings((prev) => prev.filter((job) => job.key !== postingJobId.toString()));
          } else {
            message.error("Failed to delete the job posting.");
          }
        } catch (error) {
          console.error("Error during job deletion:", error);
          message.error("Error deleting the job posting. Please try again.");
        }
      },
      onCancel: () => {
        message.info("Termination cancelled.");
      },
    });
  };

  const onFinish = async (values) => {
    // Construct final values for the API
    const finalValues = {
      ...values,
      compensation: isCompensationEnabled ? values.compensation : "$0",
      recruiter,
      jobDescription,
    };

    // Check for required fields
    const missingFields = Object.keys(finalValues).filter((key) => !finalValues[key]);
    if (missingFields.length > 0) {
      message.error(`Please fill in the following fields: ${missingFields.join(", ")}`);
      return;
    }

    try {
      setAddLoading(true);
      if (editingJobId) {
        await updateJobPosting(editingJobId, finalValues);
      } else {
        await addJobPosting(finalValues);
      }
      message.success("Job posting submitted successfully.");
      setShowForm(false);
      fetchJobPostings(); // Refresh postings
      form.setFieldsValue({
        postingJobTitle: "",
        budgetApproved: "",
        jobStatus: "",
        hiringLead: "",
        department: "",
        employmentType: "",
        maximumExperience: "",
        compensation: "",
        jobLocationType: "",
        jobType: "",
        jobInternalCode: "",
        Skills: [],
      });
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data.errors) {
        const errorMessages = Object.values(error.response.data.errors).flat().join(", ");
        message.error(`Failed to submit the job posting: ${errorMessages}`);
      } else {
        message.error("Failed to submit the job posting. Please try again.");
      }
    } finally {
      setAddLoading(false);
    }
  };

  // Define columns for the table
  const columns = [
    {
      title: "Post_Name",
      dataIndex: "PositionName",
      key: "PositionName",
      render: (text) => (
        <Tooltip title={text}>
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: 100,
            }}
          >
            {text.length > 10 ? `${text.slice(0, 10)}...` : text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Department",
      dataIndex: "Department",
      key: "Department",
      render: (text) => (
        <Tooltip title={text}>
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: 100,
            }}
          >
            {text.length > 5 ? `${text.slice(0, 5)}...` : text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Created_On",
      dataIndex: "CreatedOn",
      key: "CreatedOn",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (text) => (
        <Tooltip title={text}>
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: 100,
            }}
          >
            {text.length > 4 ? `${text.slice(0, 4)}...` : text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Skills",
      dataIndex: "Skills",
      key: "Skills",
      render: (text) => (
        <Tooltip title={text}>
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: 100,
            }}
          >
            {text.length > 17 ? `${text.slice(0, 17)}...` : text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "View Candidate",
      dataIndex: "ViewCandidate",
      key: "ViewCandidate",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
    },
  ];

  const handleBudgetApprovalChange = (value) => {
    setIsCompensationEnabled(value === "Yes");
    if (value === "No") {
      form.setFieldsValue({ compensation: "$0" });
    } else {
      form.setFieldsValue({ compensation: "" });
    }
  };

  const usStates = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  return {
    form,
    jobPostings,
    childManagers,
    showForm,
    loading,
    addloading,
    onFinish,
    columns,
    setShowForm,
    recruiter,
    jobDescription,
    setJobDescription,
    editingJobId,
    isCompensationEnabled,
    handleBudgetApprovalChange,
    usStates,
  };
}

export default HiringApiFunctions;
