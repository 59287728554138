import { useEffect, useState } from "react";
import axios from "axios";
import { generatePdfReport } from "./PDFGenrator";

function Service() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [dataSource, setDataSource] = useState([]);
  const [trainingNames, setTrainingNames] = useState([]);
  const [selectedTrainingName, setSelectedTrainingName] = useState("All Training Detail");

  useEffect(() => {
    fetchEnrolledEmployees();
  }, []);

  async function fetchEnrolledEmployees() {
    try {
      const response = await axios.get(
        `${apiUrl}/api/InitiateAccountability/Get-Enrolled-Employee`
      );

      if (response.status === 200) {
        const responseData = response.data;

        // Map the data to your table format
        const updatedRows = responseData.map((info) => ({
          key: info.enrollmentDetails.enrolledTrainingId?.toString() || "",
          EmployeeID: info.enrollmentDetails.personalId || "",
          name: `${info.personalInfo.firstName || ""} ${info.personalInfo.lastName || ""}`,
          email: info.personalInfo.email || "",
          TrainingName: info.trainingDetails.trainingName || "",
          Provider: info.enrollmentDetails.provider || "",
          Mentor: info.enrollmentDetails.mentor || "",
          StartDate: formatDate(info.enrollmentDetails.startDate),
          EndDate: formatDate(info.enrollmentDetails.endDate),
        }));

        setDataSource(updatedRows);

        // Assuming training names are unique, get distinct training names
        const uniqueTrainingNames = [
          ...new Set(responseData.map((info) => info.enrollmentDetails.trainingName || "")),
        ].filter((name) => name); // Filters out empty strings
        setTrainingNames(uniqueTrainingNames);
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  function formatDate(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  }

  const handleTrainingNameChange = (event) => {
    setSelectedTrainingName(event.target.value);
  };

  const handleDownloadReport = () => {
    generatePdfReport(filteredDataSource, selectedTrainingName);
  };

  const filteredDataSource =
    selectedTrainingName === "All Training Detail"
      ? dataSource
      : dataSource.filter((item) => item.TrainingName === selectedTrainingName);

  const columns = [
    {
      title: "Employee_ID",
      dataIndex: "EmployeeID",
      key: "EmployeeID",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Training_Name",
      dataIndex: "TrainingName",
      key: "TrainingName",
    },
    {
      title: "Provider",
      dataIndex: "Provider",
      key: "Provider",
    },
    {
      title: "Mentor",
      dataIndex: "Mentor",
      key: "Mentor",
    },
    {
      title: "Start_Date",
      dataIndex: "StartDate",
      key: "StartDate",
    },
    {
      title: "End_Date",
      dataIndex: "EndDate",
      key: "EndDate",
    },
  ];

  return {
    columns,
    filteredDataSource,
    handleDownloadReport,
    handleTrainingNameChange,
    trainingNames,
    selectedTrainingName,
  };
}

export default Service;
