import React from "react";
import { Table, Button, Form, Input, DatePicker, Spin, Card, Row, Col } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import moment from "moment";
import Footer from "layouts/authentication/components/Footer";
import Service from "./Service";

const TrainingAndDevelopment = () => {
  const {
    trainings,
    columns,
    validateEndDate,
    handleFormSubmit,
    loading,
    isFormVisible,
    addLoading,
    showForm,
    form,
    editMode,
    setIsFormVisible,
  } = Service();

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card
            className="card-professional"
            title="Training and Development"
            extra={
              !isFormVisible ? (
                ""
              ) : (
                <Button type="default" onClick={() => setIsFormVisible(false)}>
                  Back to Training List
                </Button>
              )
            }
          >
            <div style={{ padding: "20px" }}>
              {!isFormVisible ? (
                <>
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    style={{ marginBottom: "20px" }}
                    onClick={() => showForm(null)}
                  >
                    Add Training
                  </Button>

                  <Spin spinning={loading}>
                    <Table
                      className="table-responsive"
                      dataSource={trainings}
                      columns={columns}
                      rowKey="trainingId"
                      pagination={{ pageSize: 10 }}
                    />
                  </Spin>
                </>
              ) : (
                <>
                  <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="trainingName"
                          label="Training Name"
                          rules={[{ required: true, message: "Please enter the training name" }]}
                        >
                          <Input placeholder="Enter training name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="provider"
                          label="Provider"
                          rules={[{ required: true, message: "Please enter the provider" }]}
                        >
                          <Input placeholder="Enter provider" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="mentor"
                          label="Mentor"
                          rules={[{ required: true, message: "Please enter the mentor name" }]}
                        >
                          <Input placeholder="Enter mentor" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="allocated_budget"
                          label="Allocated Budget"
                          rules={[{ required: true, message: "Please enter the allocated budget" }]}
                        >
                          <Input placeholder="Enter allocated budget" type="number" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="startDate"
                          label="Start Date"
                          rules={[
                            {
                              required: true,
                              message: "Please select the start date",
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            disabledDate={(current) => current && current < moment().startOf("day")} // Disable past dates
                            onChange={(date) => {
                              form.setFieldsValue({ endDate: null });
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="endDate"
                          label="End Date"
                          rules={[
                            {
                              required: true,
                              message: "Please select the end date",
                            },
                            { validator: validateEndDate }, // Custom validator for endDate
                          ]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            disabledDate={(current) => current && current < moment().startOf("day")} // Disable past dates
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item>
                      <Button
                        disabled={addLoading}
                        type="primary"
                        htmlType="submit"
                        loading={addLoading}
                      >
                        {editMode
                          ? addLoading
                            ? "Updating..."
                            : "Update Training"
                          : addLoading
                          ? "Submitting..."
                          : "Submit"}
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}
            </div>
          </Card>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default TrainingAndDevelopment;
