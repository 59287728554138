import React, { useState } from "react";
import "antd/dist/antd";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import "./Onboarding.scss";
import { Button, Card } from "antd";
import EmployeeOnboarding from "./EmployeeOnboarding";
import ManagerOnboarding from "./ManagerOnboarding";

const Onboarding = () => {
  const [selectedSection, setSelectedSection] = useState(null);
  const getHeaderText = () => {
    switch (selectedSection) {
      case "ManagerOnboarding":
        return "Manager Onboarding";
      case "EmployeeOnboarding":
        return "Employee Onboarding";
      default:
        return "Onboarding";
    }
  };

  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            <Card
              className="card-professional"
              title={getHeaderText()}
              extra={
                selectedSection && (
                  <Button
                    onClick={() => setSelectedSection(null)}
                    style={{ position: "absolute", right: 10, top: 10 }}
                  >
                    Back
                  </Button>
                )
              }
            >
              {selectedSection === null && (
                <div
                  style={{
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: "center",
                    gap: "30px", // Updated gap
                    flexWrap: "wrap", // Ensure circles are responsive
                  }}
                >
                  {/* Initial view with three circular boxes with updated size */}
                  <div
                    onClick={() => setSelectedSection("EmployeeOnboarding")}
                    className="circle-tab circle-tab-2" // Apply updated circle-tab class
                  >
                    <span>
                      Employee <br></br> Onboarding
                    </span>
                  </div>
                  <div
                    onClick={() => setSelectedSection("ManagerOnboarding")}
                    className="circle-tab circle-tab-4" // Apply updated circle-tab class
                  >
                    <span>
                      Manager <br></br> Onboarding
                    </span>
                  </div>
                </div>
              )}

              {selectedSection === "EmployeeOnboarding" && (
                <div>
                  <EmployeeOnboarding />
                </div>
              )}
              {selectedSection === "ManagerOnboarding" && (
                <div>
                  <ManagerOnboarding />
                </div>
              )}
            </Card>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default Onboarding;
