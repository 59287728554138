import { useEffect, useState } from "react";
import { Card, Table, Typography } from "antd";
import axios from "axios";

const { Text } = Typography;

export default function TrainingData() {
  const [trainingData, setTrainingData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    async function fetchTrainingData() {
      try {
        const response = await axios.get(`${apiUrl}/api/TrainingDevelopement/Get-Training-Detail`);
        setTrainingData(response.data.trainingDetail);
      } catch (error) {
        console.error("Error fetching training data:", error);
      }
    }

    fetchTrainingData();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "trainingName",
      key: "trainingName",
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "Mentor",
      dataIndex: "mentor",
      key: "mentor",
      align: "center",
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "Allocated Budget",
      dataIndex: "allocated_budget",
      key: "allocated_budget",
      align: "center",
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      align: "center",
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      align: "center",
      render: (text) => <Text>{text}</Text>,
    },
  ];

  return (
    <Card title="Trainings" className="card-professional">
      <Table
        className="table-responsive"
        columns={columns}
        bordered
        dataSource={trainingData}
        rowKey={(record) => record.id}
        pagination={{ pageSize: 10 }}
      />
    </Card>
  );
}
