import { useEffect, useState } from "react";
import axios from "axios";
import { generatePDF } from "./PDFGenrator";

function Service() {
  const apiUrl = process.env.REACT_APP_API_URL;

  const columns = [
    {
      title: "Serial No",
      key: "serialNo",
      render: (text, record, index) => index + 1, // Generate serial number based on index
    },
    {
      title: "Post Name",
      dataIndex: "postingJobTitle",
      key: "postingJobTitle",
      render: (text, record) => record.hiring.postingJobTitle, // Access nested object
    },
    {
      title: "Employee Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text, record) => `${record.firstName} ${record.lastName}`, // Combine first and last names
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (text, record) => record.hiring.department, // Access nested object
    },
    {
      title: "Skills",
      dataIndex: "skills",
      key: "skills",
      render: (text, record) => record.hiring.skills.split(", ").join(", "), // Access nested object and join array
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (record.status === 8 ? "Qualified" : record.status),
    },
  ];

  const [dataSource, setDataSource] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/EmployeeJobApplication/GetEmployee-Detail`)
      .then((response) => {
        if (response.status === 200) {
          const employees = response.data.getemployee.filter((employee) => employee.status === 8);
          setDataSource(employees);
          setFilteredData(employees); // Initialize filteredData with all employees

          // Extract skills and remove duplicates
          const allSkills = employees.flatMap((employee) => employee.hiring.skills.split(", "));
          const uniqueSkills = [...new Set(allSkills)];
          setSkills(uniqueSkills);
        } else {
          console.error("Error fetching data:", response.status, response.statusText);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [apiUrl]);

  const handleSkillsChange = (value) => {
    setSelectedSkills(value);

    if (value.length === 0) {
      setFilteredData(dataSource);
    } else {
      const filtered = dataSource.filter((employee) =>
        value.every((skill) => employee.hiring.skills.split(", ").includes(skill))
      );
      setFilteredData(filtered);
    }
  };

  const handleDownloadReport = () => {
    generatePDF(filteredData, selectedSkills);
  };

  return {
    selectedSkills,
    skills,
    columns,
    handleSkillsChange,
    handleDownloadReport,
    filteredData,
  };
}

export default Service;
