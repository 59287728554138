// PdfGenerator.js
import jsPDF from "jspdf";
import "jspdf-autotable";

export const generatePdfReport = (filteredDataSource, selectedTrainingName) => {
  const doc = new jsPDF();

  doc.setFontSize(18);
  doc.text("Training Detail Report", 14, 22);

  const lineY = 28;
  const lineWidth = 0.5;
  const lineColor = [192, 192, 192];
  doc.setLineWidth(lineWidth);
  doc.setDrawColor(...lineColor);
  doc.line(14, lineY, 200, lineY);

  const marginTop = 3;
  const marginTopEOM = 2;
  doc.setFontSize(12);
  doc.text("Training: All - All training details selected", 14, 32 + marginTop);

  // Get the current date
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();

  doc.text(`Report Date: ${formattedDate}`, 14, 42 + marginTopEOM);
  doc.text("Exceptions: No Deletions", 14, 52);
  doc.text(`Training: ${selectedTrainingName}`, 14, 59);

  const headers = [
    [
      "Employee ID",
      "Name",
      "Email",
      "Training Name",
      "Provider",
      "Mentor",
      "Start Date",
      "End Date",
    ],
  ];
  const data = filteredDataSource.map((employee) => {
    return [
      employee.EmployeeID,
      employee.name,
      employee.Email,
      employee.TrainingName,
      employee.Provider,
      employee.Mentor,
      employee.StartDate,
      employee.EndDate,
    ];
  });

  doc.autoTable({
    startY: 62,
    head: headers,
    body: data,
    headerStyles: {
      fillColor: [192, 192, 192],
      fontSize: 8,
    },
    styles: {
      fontSize: 8,
    },
  });

  doc.save(`training_detail_report_${selectedTrainingName}.pdf`);
};
