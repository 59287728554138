import { message as antdMessage, Menu, Form } from "antd";
import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./announcement.scss";
import { useNavigate } from "react-router-dom";

function Service() {
  const [form] = Form.useForm(); // Create form instance
  const [announcements, setAnnouncements] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const fetchAnnouncements = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/Announcement/Get-Announcement`);
      setAnnouncements(response.data);
    } catch (error) {
      antdMessage.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddAnnouncement = () => {
    setIsAdding(true);
    setEditMode(false);
    setSelectedAnnouncement(null);
    form.resetFields(); // Reset form fields when adding
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleCancel = () => {
    setIsAdding(false);
    setEditMode(false);
    setSelectedAnnouncement(null);
    form.resetFields();
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const response = await axios.post(`${apiUrl}/api/Announcement/Add-Announcement`, {
        announcement: values.announcement, // Use form values
        message: values.announcementMessage, // Use form values
      });
      if (response.status === 200) {
        antdMessage.success("Announcement added successfully");
        setIsAdding(false);
        fetchAnnouncements();
      } else {
        antdMessage.error("Failed to add announcement");
      }
    } catch (error) {
      console.log("An error occurred: " + error.message);
    }
  };

  const handleEditAnnouncement = async () => {
    try {
      const values = await form.validateFields(); // Get validated form values
      const response = await axios.put(
        `${apiUrl}/api/Announcement/Edit-Announcement/${selectedAnnouncement.announcementId}`,
        {
          announcement: values.announcement, // Use form values
          message: values.announcementMessage, // Use form values
        }
      );

      if (response.status === 200) {
        antdMessage.success("Announcement updated successfully");
        setEditMode(false);
        form.resetFields(); // Reset form fields after successful update
        setSelectedAnnouncement(null);
        fetchAnnouncements(); // Refresh the announcements after update
      } else {
        antdMessage.error("Failed to update announcement");
      }
    } catch (error) {
      console.log("An error occurred: " + error.message);
      antdMessage.error("Failed to update announcement: " + error.message);
    }
  };

  const handleMenuClick = async (action, ann) => {
    switch (action) {
      case "view":
        navigate(`/announcement-detail/${ann.announcementId}`, { state: { announcement: ann } });
        break;
      case "edit":
        setEditMode(true);
        setSelectedAnnouncement(ann);
        form.setFieldsValue({
          announcement: ann.announcement,
          announcementMessage: ann.message,
        });
        break;
      case "delete":
        const confirmDelete = window.confirm("Are you sure you want to delete this announcement?");
        if (confirmDelete) {
          try {
            await axios.delete(
              `${apiUrl}/api/Announcement/Delete-Announcement/${ann.announcementId}`
            );
            antdMessage.success("Announcement deleted successfully");
            fetchAnnouncements();
          } catch (error) {
            antdMessage.error("Failed to delete announcement: " + error.message);
          }
        }
        break;
      default:
        break;
    }
  };

  const menu = (ann) => (
    <Menu>
      <Menu.Item key="view" onClick={() => handleMenuClick("view", ann)}>
        View Announcement
      </Menu.Item>
      <Menu.Item key="edit" onClick={() => handleMenuClick("edit", ann)}>
        Edit
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => handleMenuClick("delete", ann)}>
        Delete
      </Menu.Item>
    </Menu>
  );
  return {
    isAdding,
    handleCancel,
    editMode,
    loading,
    announcements,
    menu,
    handleEditAnnouncement,
    handleSubmit,
    handleAddAnnouncement,
    truncateText,
    form,
  };
}
export default Service;
