import React, { useEffect, useState } from "react";
import "antd/dist/antd";
import "../../Onboarding/Onboarding.scss";
import hiringOnboardingService from "../../APIServices/ViewCandidateService/HiringOnboardingService";
import { ToastContainer } from "react-toastify";
import OnboardingTasks from "./OnboardingTasks"; // Import the new component
import { Button, Card, Modal } from "antd";

const HiringOnboarding = () => {
  const { formData, setFormData, handleSubmit, handleNavigation, isSubmitted } =
    hiringOnboardingService();
  const [isModalVisible, setIsModalVisible] = useState(false);
  useEffect(() => {
    // Show modal if already onboarded
    if (isSubmitted) {
      setIsModalVisible(true);
    }
  }, [isSubmitted]);
  return (
    <>
      <ToastContainer />
      <Modal
        visible={isModalVisible}
        closable={false}
        footer={null}
        centered
        bodyStyle={{ height: "80px", textAlign: "center" }}
      >
        <p style={{ textAlign: "center", fontSize: "18px" }}>
          You have already onboarded this employee
        </p>
        {isSubmitted && (
          <Button type="primary" onClick={handleNavigation}>
            Personal Info
          </Button>
        )}
      </Modal>
      {!isSubmitted && (
        <>
          <Card title="New Hiring Onboarding Checklists" className="card-professional">
            <OnboardingTasks formData={formData} setFormData={setFormData} />

            <div className="col-md-12">
              {!isSubmitted && (
                <Button type="primary" style={{ float: "inline-end" }} onClick={handleSubmit}>
                  Submit
                </Button>
              )}
            </div>
          </Card>
        </>
      )}
    </>
  );
};

export default HiringOnboarding;
